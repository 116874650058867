import { DataService } from "../base/base-data.service";

class CompaniesDataService extends DataService {
    apiUrl = '/company'

    async list() {
        return await this.get(`${this.apiUrl}`);
    }

    async getById(id) {
        return await this.get(`${this.apiUrl}/${id}`);
    }
    
    async insert(data) {
        return await this.post(`${this.apiUrl}`, data);
    }

    async update(data) {
        return await this.put(`${this.apiUrl}`, data);
    }
}

let companiesDataService = new CompaniesDataService();

export default companiesDataService;
import React, { Component } from "react";
import "./station-modification.scss";
import TopBar from "../../../components/topbar";
import Layout, { Content } from "antd/lib/layout/layout";
import { Input, Select, Form, Card, Button, message, Checkbox } from "antd";
import AddressComponent from "../../../components/address/address-component";
import stationDataService from "../../../data-services/stations/stations-data.service";
import warehouseDataService from "../../../data-services/warehouse/warehouse-data.service";

export class StationModificationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props?.match?.params?.stationId,
      warehouseLocations: null,
      initData: null,
    };
    this.formRef = React.createRef();
    this.addressRef = React.createRef();
    this.editStation = this.editStation.bind(this);
  }

  async componentDidMount() {
    let warehouseLocations = await warehouseDataService.getWarehouseLocations();
    if (warehouseLocations) {
      this.setState({
        warehouseLocations: warehouseLocations ?? "",
      });
    }

    let stationInformation = await stationDataService.getStationId(
      this.state.id
    );
    if (stationInformation) {
      this.setState({
        ...this.state,
        initData: {
          id: stationInformation.id,
          phoneNumber: stationInformation.phone,
          name: stationInformation.name,
          location: stationInformation.location,
          locationId: stationInformation.locationId,
          address: stationInformation.address,
          status: stationInformation.status,
        },
      });
    }

    this.refresh();
  }

  render() {
    var { t } = this.props;
    const { initData, warehouseLocations } = this.state;

    if (!initData) {
      return <></>;
    } else {
      return (
        <Layout className="bg-content">
          <div className="bg-body">
            <TopBar title={t("station.modification")} />
            <Content className="content">
              <Card>
                <Form
                  ref={this.formRef}
                  layout="vertical"
                  onFinish={this.editStation}
                >
                  <Form.Item
                    label={t("station.name")}
                    name={["name"]}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "createOrEditOrderPage.form.pleaseInputValidPhone"
                        ),
                      },
                    ]}
                  >
                    <Input
                      className="rounded"
                      placeholder={t(
                        "warehouseRegistration.inputWarehouseName"
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t("station.phone")}
                    name={["phoneNumber"]}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "createOrEditOrderPage.form.pleaseInputValidPhone"
                        ),
                      },
                      {
                        pattern:
                          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                        message: t(
                          "createOrEditOrderPage.form.pleaseInputValidPhone"
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("warehouseRegistration.inputPhoneNumber")}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t("station.address")}
                    className="col-12 pr-2"
                    name={["address"]}
                    rules={[{ required: true }]}
                  >
                    <AddressComponent
                      t={t}
                      ref={this.addressRef}
                      initialData={initData?.address}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t("station.location")}
                    name={["location"]}
                    rules={[
                      {
                        required: true,
                        message: t("warehouseRegistration.inputLocation"),
                      },
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      placeholder={t(
                        "createOrEditOrderPage.productForm.selectCategory"
                      )}
                    >
                      {warehouseLocations?.map(({ id, name }) => (
                        <Select.Option key={id} value={name}>
                          {t(name)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name={["status"]} valuePropName="checked">
                    <Checkbox>{t("status.available")}</Checkbox>
                  </Form.Item>

                  <Form.Item className="actions">
                    <Button
                      className="btn-action btn-back"
                      onClick={this.backPage}
                    >
                      {t("button.back")}
                    </Button>
                    <Button className="btn-action btn-insert" htmlType="submit">
                      {t("button.update")}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Content>
          </div>
        </Layout>
      );
    }
  }

  setFieldsValue = (values) => {
    if (this.addressRef && this.addressRef.current) {
      this.addressRef.current.setFieldsValue(values?.address);
    }
    this.setState({ ...values }, () => {
      this.refresh();
    });
  };

  refresh = () => {
    const { initData } = this.state;
    if (initData) {
      this.formRef.current.setFieldsValue(initData);
    }
  };

  backPage = () => {
    this.props.history.push(`/station`);
  };

  editStation = (values) => {
    var { t } = this.props;
    values.id = this.state.id;
    stationDataService
      .updateStation(values)
      .then((res) => {
        if (res.success) {
          message.success(t(res.successMessage));
          this.backPage();
        } else {
          message.error(t(res.errorMessage));
        }
      })
      .catch(({ data }) => {
        message.error(data.message);
      });
  };
}

import { connect } from 'react-redux';
import { deleteRoles, fetchListRoles, setSelectedRoleIds } from '../../../modules/roles/roles.actions';
import { TableRoles } from './table-roles.component';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        roles: state.roles?.list,
        selectedRoleIds: state.roles?.selected,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        listRoles: () => dispatch(fetchListRoles()),
        setSelectedRoleIds: (roleIds) => dispatch(setSelectedRoleIds(roleIds)),
        deleteRoles: (roleIds) => dispatch(deleteRoles(roleIds)),
    }
}
export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps)
)(TableRoles)
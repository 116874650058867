import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import WarehouseRegistrationScreen from "./warehouse-registration.screen";

const mapStateToProps = (state) => {
  return {
    userId: state.session?.auth?.user?.userid,
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, null),
  withRouter
)(WarehouseRegistrationScreen);

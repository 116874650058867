import { Col, Row, Table } from "antd";
import React, { Component } from "react";
import { Content } from "antd/lib/layout/layout";
import { formatMoney, formatAddress } from "../../../utils/common";

export default class SummarizeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: props.initialData,
    };
  }

  render() {
    const { t } = this.props;
    const { stationWarehouse, areaStation } = this.state?.initialData;
    const tableDataSource = areaStation?.map((i) => this.mappingToDataTable(i));
    return (
      <Content>
        <label className="mb-3">
          {t("warehouseRegistration.warehouseInfo")}
        </label>
        <Row className="ml-3">
          <Col className="mr-5">
            <p>{t("warehouseRegistration.warehouseName")}</p>
            <p>{t("warehouseRegistration.phoneNumber")}</p>
            <p>{t("profilePage.email")}</p>
            <p>{t("warehouseRegistration.address")}</p>
            <p>{t("warehouseRegistration.location")}</p>
          </Col>
          <Col>
            <p>{stationWarehouse?.name}</p>
            <p>{stationWarehouse?.phoneNumber}</p>
            <p>{stationWarehouse?.email}</p>
            <p>{formatAddress(stationWarehouse?.address, t)}</p>
            <p>{t(stationWarehouse?.location?.name)}</p>
          </Col>
        </Row>

        <label className="mt-5">
          {t("warehouseRegistration.areaAndSpaces")}
        </label>
        <Table dataSource={tableDataSource} columns={this.columns()} />
      </Content>
    );
  }

  columns = () => {
    const { t } = this.props;
    return [
      {
        title: t("warehouseRegistration.column.areaName"),
        dataIndex: "areaName",
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.category"),
        dataIndex: "category",
        render: (category, data) => {
          return <>{t(category)}</>;
        },
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.areaSize"),
        dataIndex: "areaSize",
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.numberOfSpace"),
        dataIndex: "numberOfSpace",
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.spaceSize"),
        dataIndex: "spaceSize",
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.price"),
        dataIndex: "priceDetail",
        align: "center",
      },
    ];
  };

  mappingToDataTable = (data) => {
    const { warehouseCategories } = this.state?.initialData;
    let category = warehouseCategories?.find((x) => x.id === data?.category.id);
    return {
      id: data?.id,
      areaName: data?.name,
      category: category?.languageKey,
      areaSize: `${data?.length} x ${data?.width} x ${data?.height}`,
      spaceSize: `${data?.space?.length} x ${data?.space?.width} x ${data?.space?.height}`,
      priceDetail: formatMoney(data?.price),
      numberOfSpace: data?.totalSpace,
    };
  };
}

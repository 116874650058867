import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AddCompanyScreen } from "./add-company.screen";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

export default compose(
    withTranslation('translations'),
    connect(null, null),
    withRouter
)(AddCompanyScreen)
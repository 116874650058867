import { Button, message, Spin } from "antd";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import React, { Component } from "react";
import stationDataService from "../../../data-services/stations/stations-data.service";
import { env } from "../../../env";
import BeecowMenu from "./../../../components/menu";

export class ImportStationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedFile: null,
      name_file: "Choose file",
      UploadFile: false,
      dataImport: [],
      no_success: 0,
      no_faild: 0,
    };
    this.HandleBack = this.HandleBack.bind(this);
    this.HandleUpload = this.HandleUpload.bind(this);
    this.handleDownloadTemplate = this.handleDownloadTemplate.bind(this);
  }

  handleFileImport(e) {
    let file = e.target.files[0];

    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("test", "StringValueTest");
    this.setState({
      name_file: file.name,
      UploadFile: true,
      dataImport: formdata,
    });
  }

  HandleUpload() {
    const { dataImport } = this.state;
    this.setState({ UploadFile: false, isLoading: true });
    stationDataService.importStation(dataImport).then((data) => {
      this.setState({ UploadFile: true, isLoading: false });
      message.success(`You're imported successful`);
    });
  }

  HandleBack() {
    window.location.href = "/station";
  }

  handleDownloadTemplate() {
    const link = document.createElement("a");
    link.href = `${env.REACT_APP_API_DOMAIN}/api/Station/template`;
    link.click();
  }

  render() {
    const { name_file, UploadFile, isLoading } = this.state;
    return (
      <div className="container-importStation">
        <Layout>
          <Sider>
            <BeecowMenu />
          </Sider>
          <Layout className="bg-content">
            <div className="bg-body">
              <Header className="header">
                <div className="lbl-header">
                  <a>Import Station</a>
                </div>

                <div className="btn-header">
                  <Button onClick={this.handleDownloadTemplate} className="btn-download">
                    Download Template
                  </Button>
                </div>
              </Header>

              <Content className="content">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      onChange={(e) => this.handleFileImport(e)}
                      id="inputGroupFile01"
                      className="custom-file-input"
                    />
                    <label className="custom-file-label" htmlFor="inputGroupFile01">
                      {name_file}
                    </label>
                  </div>
                </div>
                <div className="btn-upload">
                  {UploadFile && (
                    <Button className="btn-download" onClick={this.HandleUpload}>
                      Upload
                      <span className="btn-label">
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </span>
                    </Button>
                  )}
                  {!UploadFile && isLoading && (
                    <div className="iconLoading">
                      <Spin />
                    </div>
                  )}
                </div>
                <div className="lbl-result"></div>
                <div className="link-back">
                  <a className="lbl-back" onClick={this.HandleBack}>
                    Back
                  </a>
                </div>
              </Content>
            </div>
          </Layout>
        </Layout>
      </div>
    );
  }
}

import React, { Component } from "react";
import {
  Modal,
  Card,
  Col,
  Row,
  Button,
  Table,
  notification,
  message,
} from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import "moment/locale/vi";
import moment from "moment";
import "moment/locale/es-us";
import { bindActionCreators, compose } from "redux";
import {
  EStatusContractLeasing,
  EStatusProductLeasing,
} from "../../../constants/constants";
import { withRouter } from "react-router";
import TopBar from "../../../components/topbar";
import { withTranslation } from "react-i18next";
import languageService from "../../../services/language.service";
import contractDataService from "../../../data-services/contracts/contracts-data.service";
import { connect } from "react-redux";
import { resetSession } from "../../../modules/session/session.actions";
import { superAdmin } from "../../../constants/user-constants";

class ContractDetailPageAdmin extends Component {
  constructor(props) {
    super(props);
    let url = window.location.href;
    let id = url.substring(url.lastIndexOf("/") + 1);
    const { t } = props;
    this.state = {
      contractInfo: {
        index: 0,
        id: id,
        code: "",
        areaStationId: "",
        createdTime: "",
        expiryDate: "",
        name: "",
        address: "",
        productOfContracts: [],
        phoneNumber: "",
        price: "",
        spaceAreaId: "",
        status: {},
      },
      ModalConfirmChangeStatus: {
        visible: false,
        modalText: `Are you sure want to Verify this Contract ?`,
        confirmLoading: false,
      },
      ModalConfirmCleanUpWarehouse: {
        visible: false,
        confirmLoading: false,
      },
    };
  }
  componentWillMount() {
    this.InitDataTable();
  }

  getColumns() {
    const { t } = this.props;
    let columns = [
      {
        title: t("warehouse.tableColumn.no"),
        dataIndex: "index",
        width: "14%",
      },
      {
        title: t("warehouse.tableColumn.ProductId"),
        dataIndex: "code",
        sorter: (a, b) => a.orderCode - b.orderCode,
        width: "14%",
        render: (_, { code, id }) => (
          <p
            className="itemOrtherInTable product-code text-primary"
            onClick={() => this.openProductDetail(id)}
          >
            <a>{code}</a>
          </p>
        ),
      },
      {
        title: t("warehouse.tableColumn.productName"),
        dataIndex: "itemName",
        sorter: (a, b) => a.itemName - b.itemName,
        width: "20%",
        render: (text) => <p className="itemOrtherInTable">{text}</p>,
      },
      {
        title: t("warehouse.tableColumn.category"),
        dataIndex: "category",
        sorter: (a, b) => a.category - b.category,
        width: "20%",
        render: (text) => <p className="itemOrtherInTable">{t(text)}</p>,
      },
      {
        title: t("warehouse.tableColumn.initial"),
        dataIndex: "total",
        sorter: (a, b) => a.currentInventory - b.currentInventory,
        width: "15%",
        render: (text) => <p className="itemOrtherInTable">{text}</p>,
      },
      {
        title: t("warehouse.tableColumn.currentInventory"),
        dataIndex: "inventory",
        sorter: (a, b) => a.inventory - b.inventory,
        width: "15%",
        render: (text) => <p className="itemOrtherInTable">{text}</p>,
      },
      {
        title: t("warehouse.tableColumn.inWarehouse"),
        dataIndex: "inWarehouse",
        sorter: (a, b) => a.currentInventory - b.currentInventory,
        width: "15%",
        render: (text) => <p className="itemOrtherInTable">{text}</p>,
      },
    ];
    return columns;
  }

  getColorContract(contractInfo) {
    if (!contractInfo || !contractInfo.status) return;

    switch (contractInfo.status.statusName) {
      case EStatusContractLeasing.WaitingForVerify.statusName:
        contractInfo.status.color =
          EStatusContractLeasing.WaitingForVerify.color;
        break;
      case EStatusContractLeasing.WaitingForImport.statusName:
        contractInfo.status.color =
          EStatusContractLeasing.WaitingForVerify.color;
        break;
      case EStatusContractLeasing.Renewing.statusName:
        contractInfo.status.color = EStatusContractLeasing.Renewing.color;
        break;
      case EStatusContractLeasing.Expired.statusName:
        contractInfo.status.color = EStatusContractLeasing.Expired.color;
        break;
      case EStatusContractLeasing.Active.statusName:
        contractInfo.status.color = EStatusContractLeasing.Active.color;
        break;
      default:
        break;
    }
  }

  getColorProductOfContract(product, contract) {
    if (!contract || !contract.status || !product || !product.status) return;

    switch (contract.status.statusName) {
      case EStatusContractLeasing.WaitingForVerify.statusName:
        product.status = EStatusContractLeasing.WaitingForVerify;
        break;
      case EStatusContractLeasing.WaitingForImport.statusName:
        product.status = EStatusContractLeasing.WaitingForImport;
        break;
      case EStatusContractLeasing.Renewing.statusName:
        product.status = EStatusContractLeasing.Renewing;
        break;
      case EStatusContractLeasing.Expired.statusName:
        product.status = EStatusContractLeasing.Expired;
        break;
      case EStatusContractLeasing.Active.statusName:
        switch (product.status.statusName) {
          case EStatusProductLeasing.WaitingForImport.statusName:
            product.status.color = EStatusProductLeasing.WaitingForImport.color;
            break;
          case EStatusProductLeasing.OutOfStock.statusName:
            product.status.color = EStatusProductLeasing.OutOfStock.color;
            break;
          case EStatusProductLeasing.ReadyToSell.statusName:
            product.status.color = EStatusProductLeasing.ReadyToSell.color;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  InitDataTable() {
    const { history, justLoggedIn, currentStationId, role } = this.props;

    contractDataService
      .getContractDetailById(this.state.contractInfo?.id)
      .then((res) => {
        if (res?.data?.stationId !== currentStationId && role !== superAdmin) {
          if (justLoggedIn) {
            this.props.resetSession();
            history.push("/page-not-found");
          } else {
            this.props.resetSession();
            history.push({
              pathname: "/login",
              state: { returnUrl: `/admin/contract/${res?.data?.id}` },
            });
          }
        } else {
          this.getColorContract(res.data.data);
          res.data?.productOfContracts?.map((item, index) => {
            item.key = index;
            this.getColorProductOfContract(item, res.data);
          });
          const productOfContracts = res.data.productOfContracts.map(
            (x, i) => ({
              ...x,
              index: i + 1,
            })
          );
          const data = { ...res.data, productOfContracts: productOfContracts };
          this.setState({ contractInfo: data });
        }
      });
  }

  showModal = () => {
    this.setState({
      ModalConfirmChangeStatus: {
        ...this.state.ModalConfirmChangeStatus,
        visible: true,
      },
    });
  };

  handleOk = () => {
    this.setState({
      ModalConfirmChangeStatus: {
        ...this.state.ModalConfirmChangeStatus,
        confirmLoading: true,
      },
    });

    contractDataService
      .verifyContract(this.state.contractInfo?.id)
      .then((res) => {
        if (res.success) {
          notification.success({
            message: res.message,
          });
        } else {
          notification.error({
            message: res.errorMessage,
          });
        }

        this.setState({
          ModalConfirmChangeStatus: {
            ...this.state.ModalConfirmChangeStatus,
            confirmLoading: false,
            visible: false,
          },
        });
        this.InitDataTable();
      });
  };

  handleCancel = () => {
    this.setState({
      ModalConfirmChangeStatus: {
        ...this.state.ModalConfirmChangeStatus,
        visible: false,
      },
    });
  };

  openProductDetail(id) {
    this.props.history.push(`/products-detail/${id}`);
  }

  getTotalLeasingSpaces(areas) {
    let res = 0;
    areas.forEach((x) => {
      res += x.spaceAreas?.length;
    });
    return res;
  }

  render() {
    const { t } = this.props;
    const { contractInfo } = this.state;
    const total = contractInfo?.productOfContracts
      ?.map((x) => x.total)
      .reduce((a, b) => a + b, 0);
    const totalProduct = contractInfo?.productOfContracts?.length;
    const totalLeasingSpaces = this.getTotalLeasingSpaces(
      contractInfo?.areas || []
    );

    let columns = this.getColumns();
    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("warehouse.contract.contractDetailTitle")} />
          <Content className="content">
            <Card bordered={false} className="contractDetail">
              <Row>
                <Col span="23" className="textStatusContract">
                  {contractInfo.status?.statusName ==
                    EStatusContractLeasing.WaitingForVerify.statusName ||
                  contractInfo.status?.statusName ==
                    EStatusContractLeasing.Renewing.statusName ? (
                    <>
                      <p style={{ color: `${contractInfo.status.color}` }}>
                        {t(
                          contractInfo.status.languageKey ||
                            contractInfo.status.statusName
                        )}
                      </p>
                      <Button
                        onClick={this.showModal}
                        type="primary"
                        style={{
                          cursor: "pointer",
                          border: `#52c41a`,
                          background: `#52c41a`,
                        }}
                      >
                        {t("button.verify")}
                      </Button>
                    </>
                  ) : contractInfo.status?.statusName ==
                    EStatusContractLeasing.Expired.statusName ? (
                    <>
                      <p style={{ color: `${contractInfo.status.color}` }}>
                        {t(
                          contractInfo.status.languageKey ||
                            contractInfo.status.statusName
                        )}
                      </p>
                      <Button
                        onClick={this.cleanUpWareHouse}
                        type="primary"
                        className="ant-btn ant-btn-primary"
                      >
                        {t("button.cleanUpWarehouse")}
                      </Button>
                    </>
                  ) : (
                    <p style={{ color: `${contractInfo.status.color}` }}>
                      {t(
                        contractInfo.status.languageKey ||
                          contractInfo.status.statusName
                      )}
                    </p>
                  )}
                  <Modal
                    visible={this.state.ModalConfirmChangeStatus.visible}
                    onOk={this.handleOk}
                    confirmLoading={
                      this.state.ModalConfirmChangeStatus.confirmLoading
                    }
                    onCancel={this.handleCancel}
                  >
                    <p>{this.state.ModalConfirmChangeStatus.modalText}</p>
                  </Modal>
                  <Modal
                    visible={this.state.ModalConfirmCleanUpWarehouse.visible}
                    onOk={this.cleanUpWarehouseOk}
                    confirmLoading={
                      this.state.ModalConfirmCleanUpWarehouse.confirmLoading
                    }
                    onCancel={this.cleanUpWarehouseCancel}
                  >
                    <p>{t("message.cleanUpWarehouse")}</p>
                  </Modal>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <h3 className="headStyle">
                    {t("warehouse.contract.leasingInformation")}
                  </h3>
                  <Row>
                    <Col span={10} className="infor-contract">
                      <ul>
                        <li>
                          <p>{t("warehouse.contract.totalItemsProduct")}: </p>
                        </li>
                        <li>
                          <p>{t("warehouse.contract.leasingSpaces")}: </p>
                        </li>
                        <li>
                          <p>{t("warehouse.contract.rentalPrice")}: </p>
                        </li>
                        <li>
                          <p>{t("warehouse.contract.contractCreateDate")}:</p>
                        </li>
                        <li>
                          <p>{t("warehouse.contract.expiredDate")}:</p>
                        </li>
                      </ul>
                    </Col>
                    <Col span={12} className="infor-contract content-data">
                      <ul>
                        <li>
                          <p>
                            {total} {t("warehouse.contract.items")} /
                            {totalProduct} {t("warehouse.contract.products")}{" "}
                          </p>
                        </li>
                        <li>
                          <p>{totalLeasingSpaces} </p>
                        </li>
                        <li>
                          <p>{contractInfo?.price}</p>
                        </li>
                        <li>
                          <p>
                            {moment
                              .utc(contractInfo?.createdTime)
                              .locale(languageService.getLang())
                              .format("MMM DD, YYYY")}
                          </p>
                        </li>
                        <li>
                          <p>
                            {moment
                              .utc(contractInfo?.expiryDate)
                              .locale(languageService.getLang())
                              .format("MMM DD, YYYY")}
                          </p>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <h3 className="headStyle">
                    {t("warehouse.contract.renterInformation")}
                  </h3>
                  <Row>
                    <Col span={10} className="infor-contract">
                      <ul>
                        <li>
                          <p>{t("warehouse.contract.id")}:</p>
                        </li>
                        <li>
                          <p>{t("warehouse.contract.renterName")}:</p>
                        </li>
                        <li>
                          <p>{t("warehouse.contract.renterPhone")}:</p>
                        </li>
                        <li style={{ height: 57 }}>
                          <p>{t("warehouse.contract.renterAddress")}:</p>
                        </li>
                        {contractInfo?.contractTypeId === 0 && (
                          <>
                            <li>
                              <p>{t("warehouse.contract.areaId")}:</p>
                            </li>
                            <li>
                              <p>{t("warehouse.contract.size")}:</p>
                            </li>
                          </>
                        )}
                      </ul>
                    </Col>
                    <Col span={12} className="infor-contract content-data">
                      <ul>
                        <li>
                          <p>{contractInfo?.code}</p>
                        </li>
                        <li>
                          <p>{contractInfo?.name}</p>
                        </li>
                        <li>
                          <p>{contractInfo?.phoneNumber}</p>
                        </li>
                        <li>
                          <p>{contractInfo?.address}</p>
                        </li>
                        {contractInfo?.contractTypeId === 0 && (
                          <>
                            <li>
                              <p>{contractInfo?.areas[0].code}</p>
                            </li>
                            <li>
                              <p>
                                {contractInfo?.lengthWarehouse} x{" "}
                                {contractInfo?.widthWarehouse} x{" "}
                                {contractInfo?.heightWarehouse}
                              </p>
                            </li>
                          </>
                        )}
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <br />
              <br />
              <Row className="headStyle">
                {t("warehouse.contract.listOfItem")}
              </Row>
              <br />
              <Table
                dataSource={this.state.contractInfo?.productOfContracts}
                columns={columns}
              />
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }

  cleanUpWareHouse = () => {
    this.setState({
      ModalConfirmCleanUpWarehouse: {
        ...this.state.ModalConfirmCleanUpWarehouse,
        visible: true,
      },
    });
  };

  cleanUpWarehouseOk = () => {
    const { t } = this.props;
    this.setState({
      ModalConfirmCleanUpWarehouse: {
        ...this.state.ModalConfirmCleanUpWarehouse,
        confirmLoading: true,
      },
    });

    contractDataService
      .cleanUpWarehouseByContractId(this.state.contractInfo?.id)
      .then((rs) => {
        this.setState({
          ModalConfirmCleanUpWarehouse: {
            ...this.state.ModalConfirmCleanUpWarehouse,
            confirmLoading: false,
            visible: false,
          },
        });
        if (rs.success) {
          message.success(t("message.cleanUpWarhouseSuccess"));
          this.InitDataTable();
        } else {
          message.error(t("message.cleanUpWarhouseUnSuccess"));
        }
      });
  };

  cleanUpWarehouseCancel = () => {
    this.setState({
      ModalConfirmCleanUpWarehouse: {
        ...this.state.ModalConfirmCleanUpWarehouse,
        visible: false,
      },
    });
  };
}

const mapStateToProps = (state) => {
  return {
    justLoggedIn: state?.app?.justLoggedIn,
    currentUserId: state?.session?.auth?.user?.userId,
    role: state?.session?.auth?.user?.role,
    currentStationId: state?.session?.auth?.user?.currentStationId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetSession,
    },
    dispatch
  );
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ContractDetailPageAdmin);

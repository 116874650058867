const actionTypes = {
    GET_CITIES: 'GET_CITIES',
    GET_DISTRICT_BY_CITYID: 'GET_DISTRICT_BY_CITYID',
    GET_WARDS_BY_DISTRICTID: 'GET_WARDS_BY_DISTRICTID',
    CLEAR_ADDRESS: 'CLEAR_ADDRESS',
    SET_KEY_CITY: 'SET_KEY_CITY',
    SET_KEY_DISTRICT: 'SET_KEY_DISTRICT',
    SET_KEY_WARD: 'SET_KEY_WARD',
    SET_DATA_ADDRESS: 'SET_DATA_ADDRESS',
}

export default actionTypes;
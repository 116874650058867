import companiesDataService from "../../data-services/companies/companies-data.service";
import userDataService from "../../data-services/user/user-data.service";
import actionTypes from "./session.types";

export function setAuth(auth) {
  return { type: actionTypes.SET_AUTH, auth };
}

export function setWorkspace(workspaceId, workspace) {
  return async (dispatch) => {
    if (workspaceId && !workspace) {
      workspace = await companiesDataService.getById(workspaceId);
    }
    dispatch({ type: actionTypes.SET_WORKSPACE, workspaceId, workspace });
  };
}

export function initWorkspace(workspaceId) {
  return async (dispatch) => {
    if (workspaceId) {
      const workspace = await companiesDataService.getById(workspaceId);
      dispatch({ type: actionTypes.SET_WORKSPACE, workspaceId, workspace });
    }
  };
}

export function setAuthentication(data, callback) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_AUTH, auth: data.auth });
    if (callback) {
      callback();
    }
  };
}

export function startSwitchingWorkspace() {
  return { type: actionTypes.START_SWITCHING_WORKSPACE };
}

export function switchWorkspace(workspaceId, workspace) {
  return async (dispatch) => {
    dispatch(startSwitchingWorkspace());
    if (workspaceId && !workspace) {
      workspace = await companiesDataService.getById(workspaceId);
    }
    dispatch({ type: actionTypes.SWITCH_WORKSPACE, workspaceId, workspace });
  };
}

export function finishSwitchingWorkspace() {
  return { type: actionTypes.FINISH_SWITCHING_WORKSPACE };
}

export function setCurrentUser(user) {
  return { type: actionTypes.SET_CURRENT_USER, user };
}

export function resetSession(request) {
  return async (dispatch) => {
    if (request.userId) {
      await userDataService.logout(request);
    }
    dispatch({ type: actionTypes.RESET_SESSION });
  };
}

export const updateUserName = (userName) => {
  return { type: actionTypes.UPDATE_USER_NAME, userName };
};

export const SetChangedPassWord = (changedPassWord) => {
  return { type: actionTypes.SET_CHANGED_PASSWORD, changedPassWord };
};

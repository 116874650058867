import 'moment/locale/vi';
import moment from 'moment';
import './table-users.scss';
import 'moment/locale/es-us';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { Table, Input, Button, Space, Modal } from 'antd';
import languageService from '../../../services/language.service';
import { page, splitToGetCode } from '../../../constants/constants';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export class TableUsers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            dataSource: [],
            paging: {
                pageSize: page.pageSizeDefault,
            }
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.listUsers()
    }

    render() {
        const selection = {
            selectedUserIds: this.props.selectedUserIds,
            onChange: this.onSelectChange,
        };

        const dataSource = this.dataMapping(this.props.users)
        let columns = this.getTableColumns(dataSource);

        return <Table
            className='beecow-table-users'
            bordered
            rowSelection={selection}
            columns={columns}
            dataSource={dataSource}
            pagination={this.state.paging}
            scroll={{ x: '80vw' }}
            onchange={this.onChange} />
    }

    dataMapping = (rows) => rows?.map((u) => {
        const { t } = this.props;
        return {
            id: u.id,
            key: u.id,
            userId: u.code,
            fullName: u.fullName,
            phone: u.phone,
            doB: moment.utc(u.doB).locale(languageService.getLang()).format('DD/MM/YYYY'),
            email: u.email,
            role: u.userType?.name,
            status: t(u.formattedStatus),
            createdTime: moment.utc(u.createdTime).locale(languageService.getLang()).format('DD/MM/YYYY')
        }
    })

    getTableColumns(users) {
        let roles = users?.map(d => { return { text: d.role, value: d.role } });
        let roleOptions = roles?.filter((d, i) => (d.value && roles?.findIndex(o => o.value === d.value) === i));
        let status = users?.map(d => { return { text: d.status, value: d.status } });
        let statusOptions = status?.filter((d, i) => (d.value && status?.findIndex(o => o.value === d.value) === i));
        const { t } = this.props;

        let columns = [
            {
                title: t('user.id'),
                dataIndex: 'userId',
                sorter: (a, b) => splitToGetCode(a.userId) - splitToGetCode(b.userId),
                ...this.getColumnSearchProps('userId'),
            },
            {
                title: t('user.fullName'),
                dataIndex: 'fullName',
                sorter: (a, b) => a.fullName?.localeCompare(b.fullName),
                ...this.getColumnSearchProps('fullName'),
            },
            {
                title: t('user.phone'),
                dataIndex: 'phone',
                sorter: (a, b) => a.phone?.localeCompare(b.phone),
                ...this.getColumnSearchProps('phone'),
            },
            {
                title: t('user.email'),
                dataIndex: 'email',
                sorter: (a, b) => a.email?.localeCompare(b.email),
                ...this.getColumnSearchProps('email'),
            },
            {
                title: t('user.roleName'),
                dataIndex: 'role',
                filters: roleOptions,
                onFilter: (value, record) => record.role?.indexOf(value) === 0,
                sorter: (a, b) => a.role?.localeCompare(b.role)
            },
            {
                title: t('status.status'),
                dataIndex: 'status',
                filters: statusOptions,
                onFilter: (value, record) => record.status?.indexOf(value) === 0,
                sorter: (a, b) => a.status?.localeCompare(b.status)
            },
            {
                title: t('user.createTime'),
                dataIndex: 'createdTime',
                sorter: (a, b) => a.createdTime?.localeCompare(b.createdTime)
            },
            {
                title: t('status.action'),
                render: (text, record) => (
                    <Space>
                        <Link className='mr-3' to={`/edit-user/${record.id}`}><i className='fa fa-pencil-square-o beecow-icon beecow-icon-edit' aria-hidden='true'></i></Link>
                        <a onClick={() => this.confirmDelete(record, `${t('notification.confirm.confirmDeleteThisItem')}`)}><i className='fa fa-trash beecow-icon beecow-icon-delete' aria-hidden='true'></i></a>
                    </Space>
                )
            },
        ];
        return columns;
    }

    onSelectChange = selectedRowKeys => {
        this.props.setSelectedUserIds(selectedRowKeys);
    };

    confirmDelete = (record, title) => {
        const self = this;
        const { t } = this.props;
        confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            okText: `${t('button.ok')}`,
            cancelText: `${t('button.cancel')}`,
            onOk() {
                return new Promise((resolve, reject) => {
                    self.props.deleteUsers([record.id]);
                    setTimeout(resolve, 1000);
                }).catch((errors) => {
                    console.log(errors);
                });
            },
            onCancel() { },
        })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`\`${this.props.t('placeholder.search')}\` ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''} />
            ) : (
                text
            ),
    });

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
}
import React, { Component } from "react";
import { Route } from "react-router-dom";
import _ from "lodash";
import { Table, Button, Form, Modal, Card } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout, { Content } from "antd/lib/layout/layout";
import TopBar from "../../components/topbar";
import shippingFeeDataService from "../../data-services/shipping-fees/shipping-fees-data.service";
import TableInnerCityFees from "./table-inner-city-fees";
import { page, splitToGetCode } from "../../constants/constants";
import actionTypes from "../../modules/shipping-fee/shipping-fee.types";

const listStatus = [
  { value: 0, text: "statuses.inActive" },
  { value: 1, text: "statuses.available" },
];

const { confirm } = Modal;
export class ShippingFeeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      selectedRowKeys: [],
      editingKey: "",
      itemDelete: [],
      feeType: "",
      feePrice: 0,
      FeeNameStatus: "",
      totalShippingFee: 0,
      isCheckHandleAdd: 0,
      listShippingFeeDetail: [],
      file: null,
      dataSource: [],
      total: 0,
      paging: {
        pageSize: page.pageSizeDefault,
      },
    };

    this.getNameStatusShippingFee = this.getNameStatusShippingFee.bind(this);
    this.editShippingFee = this.editShippingFee.bind(this);
    this.formRef = React.createRef();
  }

  getTableColumns() {
    const { t } = this.props;

    let columns = [
      {
        title: t("shippingFee.interCity.id"),
        dataIndex: "shippingFeeID",
        sorter: (a, b) =>
          splitToGetCode(a.shippingFeeID) - splitToGetCode(b.shippingFeeID),
        width: "15%",
      },
      {
        title: t("shippingFee.interCity.name"),
        dataIndex: "shippingFeeName",
        sorter: (a, b) => a?.shippingFeeName.localeCompare(b.shippingFeeName),
        width: "37%",
      },
      {
        title: t("shippingFee.interCity.price"),
        dataIndex: "shippingFeePrice",
        width: "15%",
        ellipsis: true,
        render: (text) => {
          return <span>{text}</span>;
        },
      },
      {
        title: t("status.status"),
        dataIndex: "status",
        sorter: (a, b) => a.status.length - b.status.length,
        width: "15%",
        ellipsis: true,
      },
      {
        title: t("status.action"),
        width: "13%",
        render: (_, record) => {
          return (
            <span className="action">
              <Route
                render={({ history }) => (
                  <a
                    disabled={this.state.editingKey !== ""}
                    onClick={() => this.editShippingFee(record, history)}
                    style={{ marginRight: 8 }}
                  >
                    <i
                      className="fa fa-pencil-square-o edit"
                      aria-hidden="true"
                    ></i>
                  </a>
                )}
              />
              <a
                disabled={this.state.editingKey !== ""}
                onClick={() =>
                  this.confirmDelete(
                    record,
                    true,
                    `${t("notification.confirm.confirmDeleteThisItem")}`
                  )
                }
              >
                <i className="fa fa-trash delete" aria-hidden="true"></i>
              </a>
            </span>
          );
        },
      },
    ];

    let mergedColumns = columns.map((col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: "text",
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    return mergedColumns;
  }

  InitDataTable() {
    this.getShippingFee();
  }

  getShippingFee() {
    shippingFeeDataService.getListShippingFee().then((response) => {
      const fees = this.getData(response.rows);
      this.currentData = fees;

      this.setState({
        total: response.records,
        dataSource: fees,
        totalShippingFee: response.records,
      });
    });
  }

  getData = (listData) => {
    return listData?.map((item) => ({
      key: item.id,
      shippingFeeID: item.code,
      shippingFeeName: item.name,
      status: this.getNameStatusShippingFee(item.status),
      shippingFeePrice:
        item.min === item.max
          ? item.min.toString() + " VND "
          : item.min.toString() + " - " + item.max.toString() + " VND",
      checked: false,
      id: item.id,
    }));
  };

  getNameStatusShippingFee = (StatusId) => {
    const { t } = this.props;
    listStatus.map((value) => {
      if (value.value === StatusId) {
        this.state.FeeNameStatus = t(value.text);
        return this.state.FeeNameStatus;
      }
    });

    return this.state.FeeNameStatus;
  };

  confirmDelete = (record, isSingleDelete, title) => {
    const currentComponent = this;
    const { t } = this.props;

    var ids = [];
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      okText: `${t("button.ok")}`,
      cancelText: `${t("button.cancel")}`,
      onOk() {
        return new Promise((resolve, reject) => {
          if (isSingleDelete) {
            ids = [record.id];
          } else {
            ids = currentComponent.state.selectedRowKeys;
            currentComponent.setState({ selectedRowKeys: [] });
          }
          shippingFeeDataService.deleteShippingFee(ids).then(
            (data) => {
              currentComponent.getShippingFee();
              setTimeout(resolve, 1000);
            },
            (error) => {
              setTimeout(reject(error), 500);
            }
          );
        }).catch((errors) => {
          console.log(errors);
        });
      },
      onCancel() {},
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys: selectedRowKeys });
  };

  editShippingFee = (record, history) => {
    this.props.dispatch({
      type: actionTypes.SET_SHIPPINGFEE_ID,
      payload: record.key,
    });
    history.push(`/admin/edit-shippingfee/${record.key}`);
  };

  clickBtnAdd = () => {
    window.location.href = "/add-shippingfee";
  };

  handleSearch(e) {
    const keySearch = e.currentTarget?.value?.toLowerCase();
    let data = this.currentData;
    if (keySearch) {
      const filteredData = data.filter(
        (x) =>
          x.shippingFeeID.toLowerCase().includes(keySearch) ||
          x.shippingFeeName.toLowerCase().includes(keySearch) ||
          x.status.toLowerCase().includes(keySearch)
      );
      this.setState({ dataSource: filteredData });
    } else {
      this.setState({ dataSource: data });
    }
  }

  addNewShippingFee = (history) => {
    this.props.dispatch({ type: actionTypes.SET_SHIPPINGFEE_ID, payload: "" });
    history.push("/add-shippingfee");
  };

  componentWillMount() {
    this.InitDataTable();
  }

  render() {
    const { dataSource, selectedRowKeys, totalShippingFee } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;
    var { t } = this.props;
    let columns = this.getTableColumns();

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("shippingFee.title")} />
          <Content className="content">
            <Card>
              <TableInnerCityFees />
              <div className="list-station">
                <h4>
                  <b>{t("shippingFee.interCity.title")}</b>
                </h4>
                <div className="select-items clearfix">
                  <div className="lbl-selected">
                    {hasSelected
                      ? `Selected ${selectedRowKeys.length} in ${totalShippingFee}`
                      : ""}{" "}
                  </div>
                  <div className="btn-item">
                    <Route
                      render={({ history }) => (
                        <Button
                          className="btn-add"
                          onClick={() => this.addNewShippingFee(history)}
                        >
                          {t("button.add")}{" "}
                          <span className="btn-label">
                            <i
                              className="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </Button>
                      )}
                    />

                    {hasSelected && (
                      <Button
                        onClick={() =>
                          this.confirmDelete(
                            null,
                            false,
                            `${t(
                              "notification.confirm.confirmDeleteTheseItems"
                            )}`
                          )
                        }
                        className="btn-remove"
                      >
                        {" "}
                        {t("button.remove")}{" "}
                        <span className="btn-label">
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </span>
                      </Button>
                    )}
                  </div>
                  <div className="input-search">
                    <form className="form-search">
                      <input
                        className="search form-control mr-sm-2"
                        onChange={(e) => this.handleSearch(e)}
                        type="search"
                        placeholder={t("placeholder.search")}
                        aria-label="Search"
                      />
                    </form>
                  </div>
                </div>
              </div>
              <Form ref={this.formRef}>
                <Table
                  rowSelection={rowSelection}
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={this.state.paging}
                />
              </Form>
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }
}

import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  getCities,
  getWardsByDistrictId,
  getDistrictByCityId,
} from "../../modules/address/address.actions";
import { withTranslation } from "react-i18next";
import { superAdmin } from "../../constants/user-constants";
import { StationManagementScreen } from "./station-management.screen";
import { getStations } from "../../modules/stations/stations.actions";
import { setWarehouseId } from "../../modules/warehouses/warehouses.actions";

const mapStateToProps = (state) => {
  let companyId =
    state?.session?.auth?.user?.role === superAdmin
      ? state.session?.workspace?.switchWorkspaceId
      : state.session?.workspace?.workspaceId;

  return {
    infoAddress: state.address.InfoAddress,
    stations: state.stations.listStations,
    companyId,
    role: state?.session?.auth?.user?.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStations,
      getCities,
      getDistrictByCityId,
      getWardsByDistrictId,
      setWarehouseId,
    },
    dispatch
  );
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(StationManagementScreen);

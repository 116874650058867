export const leftMenu = [];
export const EStatusContractLeasing = { 
    Active:{

        statusName : "Active",
        color : "#52c41a"
    },
    WaitingForVerify:{

        statusName : "Waiting for Verify",
        color:"#faad14"
    },
    WaitingForImport:{

        statusName : "Waiting for Import",
        color:"#faad14"
    },
    Renewing:{
  
        statusName : "Renewing",
        color : "#52c41a"
    },
    Expired:{
 
        statusName : "Expired",
        color : "#ff4d4f"
    },
    Cleared:{
 
        statusName : "Cleared",
        color : "#ff4d4f"
    }
}
export const EStatusProductLeasing = { 
    WaitingForImport:{

        statusName : "Waiting for Import",
        color : "#faad14"
    },
    OutOfStock:{

        statusName : "Out of Stock",
        color:"#ff4d4f"
    },
    ReadyToSell:{

        statusName : "Ready to Sell",
        color : "#52c41a"
    }
}

export const FORMAT_DATETIME = "DD-MM-YYYY HH:mm";

export const page = {
    pageDefault: 1,
    pageSizeDefault: 20
}

export const splitToGetCode = (code) =>{
    var array = [];
        if(code && code.includes('-'))
           array = code.split('-');
        else
           array = [0, 0];
    return array[1];
}
import { DataService } from "../base/base-data.service";

class WarehouseDataService extends DataService {
    apiUrl = '/warehouse';

    async getWarehouseCategories() {
        return this.get(`${this.apiUrl}/categories`);
    }

    async getWarehouseLocations() {
        return this.get(`${this.apiUrl}/locations`);
    }    

    async getListOfWarehouseByUserId(userId) {
        return this.get(`${this.apiUrl}/get-list-of-warehouse-by-userid/${userId}`);
    }

}

let warehouseDataService = new WarehouseDataService();
export default warehouseDataService;
import actionTypes from "./stations.types";
import stationDataService from '../../data-services/stations/stations-data.service';

export function fetchStations(stations) {
    return { type: actionTypes.FETCH_STATIONS, stations }
}

export const getStations = (companyId) => {
    return async (dispatch) => {
        const res = await stationDataService.getListStation(companyId);
        dispatch({
            type: actionTypes.LIST_STATIONS,
            payload: res
        })
    }
}
import { DataService } from "../base/base-data.service";

class StationDataService extends DataService {
    apiUrl = '/station';

    async getListStation(data) {
        return this.post(`${this.apiUrl}/loadData`, { companyId: data });
    }

    async getStations() {
        return this.get(`${this.apiUrl}/stations`);
    }

    async getStationId(id) {
        return this.get(`${this.apiUrl}/${id}`);
    }

    async addStation(data) {
        return this.post(`${this.apiUrl}/insert`, data);
    }

    async updateStation(data) {
        return this.post(`${this.apiUrl}/update`, data);
    }

    async deleteStation(data) {
        return this.post(`${this.apiUrl}/delete`, data);
    }

    async exportAllStation() {
        return this.get(`${this.apiUrl}/export`);
    }

    async importStation(data) {
        return this.post(`${this.apiUrl}/import`, data);
    }

    async warehouseRegistration(data) {
        return this.post(`${this.apiUrl}/warehouse-registration`, data);
    }

}

let stationDataService = new StationDataService();

export default stationDataService;
import { Component } from 'react';
import { Table, Input, Button, Space, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './table-roles.scss';

const { confirm } = Modal;

export class TableRoles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            dataSource: []
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.listRoles().then(() => {
            var data = this.dataMapping(this.props.roles);
            this.setState({ dataSource: data })
        });
    }

    render() {
        const { selectedRoleIds } = this.props;
        const rowSelection = {
            selectedRoleIds,
            onChange: this.onSelectChange,
        };
        
        let columns = this.getTableColumns();

        return <Table
            className='beecow-table-roles'
            bordered
            columns={columns}
            dataSource={this.state.dataSource}
            onchange={this.onChange}
        />
    }

    dataMapping = (rows) => rows.map((r) => {
        return {
            id: r.id,
            key: r.id,
            roleId: r.code,
            name: r.name,
            status: r.formattedStatus,
        }
    })

    getTableColumns(roles) {
        let status = roles?.map(d => { return {text: d.status, value: d.status} });
        let statusOptions = status?.filter((d, i) => (d.value && status?.findIndex(o => o.value === d.value) === i));
        const {t} = this.props;
        let columns = [
            {
                title: `${t('role.id')}`,
                dataIndex: 'roleId',
                key: 'roleId',
                sorter: (a, b) => a.roleId.localeCompare(b.roleId),
                ...this.getColumnSearchProps('roleId'),
            },
            {
                title: `${t('role.name')}`,
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                ...this.getColumnSearchProps('name'),
            },
            {
                title: `${t('status.status')}`,
                dataIndex: 'status',
                key: 'status',
                sorter: (a, b) => a.status.localeCompare(b.status),
                filters: statusOptions,
                onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
        ];
        return columns;
    }

    onSelectChange = selectedRowKeys => {
        this.props.setSelectedRoleIds(selectedRowKeys);
    };

    confirmDelete = (record, title) => {
        const self = this;
        const { t } = this.props;
        confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            okText: `${t('button.ok')}`,
            cancelText: `${t('button.cancel')}`,
            onOk() {
                return new Promise((resolve, reject) => {
                    self.props.deleteRoles([record.id]);
                    setTimeout(resolve, 1000);
                }).catch((errors) => {
                    console.log(errors);
                });
            },
            onCancel() { },
        })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`\`${this.props.t('placeholder.search')}\` ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''} />
            ) : (
                text
            ),
    });

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
}
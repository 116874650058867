import actionTypes from "./companies.types";
import companiesDataService from "../../data-services/companies/companies-data.service";

export function setListCompanies(data, pageSize) {
    return { 
        type: actionTypes.SET_LIST_COMPANY, 
        total: data.records, pageSize: pageSize, current: data.page, companies: data.rows
    };
}

export function setSelectedCompany(company) {
    return {
        type: actionTypes.SET_EDIT_COMAPNY,
        company: company,
    }
}

export function fetchListCompanies(page, pageSize) {
    return async (dispatch) => {
        let data = await companiesDataService.list(page, pageSize);
        dispatch(setListCompanies(data, pageSize));
    }
}

export function fetchCompanyDetail(companyId) {
    return async (dispatch) => {
        dispatch(setSelectedCompany(null));
        let data = await companiesDataService.getById(companyId);
        dispatch(setSelectedCompany(data));
    }
}
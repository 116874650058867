import actionTypes from "./session.types";
import Moment from "moment";

const sessionInitialState = {
    auth: {},
    workspace: {
        workspaceId: null,
        switchWorkspaceId: null,
        info: null,
        switchInfo: null,
    },
    currentUser: {},
    lastUpdated: 1439478405547,

};

const sessionReducer = (state = sessionInitialState, action) => {
    const auth = state.auth
    switch (action.type) {
        case actionTypes.SET_AUTH:
            return { ...state, auth: action.auth, lastUpdated: Moment.utc().format('x') };
        case actionTypes.SET_WORKSPACE:
            return { ...state, workspace: { ...state.workspace, workspaceId: action.workspaceId, info: action.workspace } };
        case actionTypes.SWITCH_WORKSPACE:
            return { ...state, workspace: { ...state.workspace, switchWorkspaceId: action.workspaceId, switchInfo: action.workspace } };
        case actionTypes.START_SWITCHING_WORKSPACE:
            return { ...state, workspace: { ...state.workspace, isSwitching: true } };
        case actionTypes.FINISH_SWITCHING_WORKSPACE:
            return { ...state, workspace: { ...state.workspace, isSwitching: false } };
        case actionTypes.SET_CURRENT_USER:
            return { ...state, currentUser: action.currentUser, lastUpdated: Moment.utc().format('x') };
        case actionTypes.RESET_SESSION:          
            return { ...state, auth: {}, workspace: {}, currentUser: {}, lastUpdated: Moment.utc().format('x') };
        case actionTypes.UPDATE_USER_NAME:
            if (auth.user) auth.user.username = action.userName
            return { ...state, auth: { ...auth } };
        case actionTypes.SET_CHANGED_PASSWORD:
            if (auth.user) auth.user.changedPassWord = action.changedPassWord
            return { ...state, auth: { ...auth } };
        case actionTypes.SET_AUTH_TOKEN:
            return { ...state, auth: {token:action.token,refreshToken:action.refreshToken,exprire:action.exprire ,...auth } };
        default:
            return state;
    }
}

export default sessionReducer;
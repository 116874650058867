import _ from "lodash";
import moment from "moment";
import "./space-detail.screen.scss";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Table, Form, Row, Col } from "antd";
import TopBar from "../../../../components/topbar";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import areaStationDataService from "../../../../data-services/area-stations/area-stations-data.service";
import { splitToGetCode } from "../../../../constants/constants"

export class SpaceDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOrders: [],
      model: [],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    let stationId = this.props?.location?.state?.stationId;
    if (id && !stationId) {
      areaStationDataService.getSpaceDetail(id).then((res) => {
        var orders = this.getData(res.orders);
        this.setState({
          model: res,
          dataOrders: orders,
        });
      });
    }
    if (id && stationId) {
      areaStationDataService.getSpaceDetailWithStationId(id, stationId)
        .then((res) => {
          var orders = this.getData(res.orders);
          this.setState({
            model: res,
            dataOrders: orders,
          });
        });
    }
  }

  getSenderInfo(order) {
    let formattedAddress =
      this.getFormattedAddress(order?.addressSender) || "No address";
    let senderInfo = [order.senderName, order.senderPhone, formattedAddress];
    return senderInfo.join("<br/>");
  }

  getReceiverInfo(order) {
    let formattedAddress =
      this.getFormattedAddress(order?.addressRecipient) || "No address";
    let senderInfo = [
      order.receiverName,
      order.receiverPhone,
      formattedAddress,
    ];
    return senderInfo.join("<br/>");
  }

  getFormattedAddress(address) {
    let arr = [];
    if (address && address.street) {
      arr.push(address.street);
    }
    if (address && address.ward) {
      let ward = address.ward.prefix + " " + address.ward.name;
      arr.push(ward);
    }
    if (address && address.district) {
      let district = address.district.prefix + " " + address.district.name;
      arr.push(district);
    }
    if (address && address.city) {
      arr.push(address.city.name);
    }
    return arr.join(", ");
  }

  getData = (orders) => {
    return orders?.map((item) => ({
      key: item.id,
      orderCode: item.code,
      from: this.getSenderInfo(item),
      to: this.getReceiverInfo(item),
      importedAmount: item.importedAmount,
      importedProduct: item.importedProduct,
      importDate: moment(item.createdTime).format("DD/MM/YYYY")
    }));
  };

  getColumns = () => {
    const { t } = this.props;
    let columns = [
      {
        title: t("text.orderId"),
        dataIndex: "orderCode",
        sorter: (a, b) => splitToGetCode(a.orderCode) - splitToGetCode(b.orderCode),
        width: "9%",
        render: (data, { isNew }) => {
          return <span>{data}</span>;
        },
      },
      {
        title: t("text.from"),
        dataIndex: "from",
        width: "30%",
        render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
      },
      {
        title: t("text.to"),
        dataIndex: "to",
        width: "30%",
        render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
      },
      {
        title: t("text.importDate"),
        dataIndex: "importDate",
        width: "11%",
        sorter: (a, b) => splitToGetCode(a.orderCode) - splitToGetCode(b.orderCode),
      },
      {
        title: t("text.importedProductsItems"),
        dataIndex: "importedAmount",
        width: "20%",
        render: (text, items) => {
          return (
            <span>{`${items.importedProduct} / ${items.importedAmount}`}</span>
          );
        },
      },
    ];

    let mergedColumns = columns.map((col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });
    return mergedColumns;
  };

  render() {
    const { t } = this.props;
    const { dataOrders, model } = this.state;
    let columns = this.getColumns();
    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("title.spaceDetails")} />
          <Content className="content">
            <Layout className="card-body">
              <div className="col-12">
                <div className="mt-4">
                  <Route
                    render={({ history }) => (
                      <button
                        className="text-primary btn-link"
                        onClick={() => history.goBack()}
                      >
                        {t("button.back")}
                      </button>
                    )}
                  />
                </div>
                <div>
                  <Row>
                    <Col span={12}>
                      <h3 className="header-page title-space mL30 ">
                        {t("text.forShippingArea")}
                      </h3>
                    </Col>
                    <Col span={6} offset={2}>
                      <h3
                        className="header-page float-right status-label"
                        style={{ color: `${model.status?.color}` }}
                      >
                        {t(model.status?.languageKey)}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="col-11 info-space">
                    <Col span={10}>
                      <ul>
                        <li>
                          <span className="title">{t("text.areaId")}: </span>
                          {model.area?.code}
                        </li>
                        <li>
                          <span className="title">{t("text.spaceId")}: </span>
                          {model.code}
                        </li>
                        <li>
                          <span className="title">
                            {t("text.spaceSize")} (LxWxH / m):{" "}
                          </span>
                          {model.length} x {model.width} x {model.height}
                        </li>
                      </ul>
                    </Col>
                    <Col span={10} offset={2}>
                      <ul>
                        <li>
                          <span className="title">{t("text.used")}: </span>
                          {` ${model.used} / ${model.max}`}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="form-table col-12 mt-5">
                <Form>
                  <Table dataSource={dataOrders} columns={columns} />
                </Form>
              </div>
            </Layout>
          </Content>
        </div>
      </Layout>
    );
  }
}

import React, { Component } from "react";
import { Input, Select, Form } from "antd";
import { Content } from "antd/lib/layout/layout";
import { MailOutlined } from "@ant-design/icons";
import AddressComponent from "../../../components/address/address-component";

export default class WarehouseInfoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: props?.initialData,
    };
    this.formRef = React.createRef();
    this.addressRef = React.createRef();
  }

  componentDidMount() {
    this.refresh();
  }

  render() {
    const { t, locations } = this.props;
    const { initialData } = this.state;

    return (
      <Content>
        <Form ref={this.formRef} layout="vertical">
          <p style={{ marginBottom: "5%" }}>
            {t("text.pleaseInputWarehouseInfo")}
          </p>
          <Form.Item
            label={t("warehouseRegistration.warehouseName")}
            name={["name"]}
            rules={[
              {
                required: true,
                message: t("createOrEditOrderPage.form.pleaseInputValidPhone"),
              },
            ]}
          >
            <Input
              className="rounded"
              placeholder={t("warehouseRegistration.inputWarehouseName")}
            />
          </Form.Item>
          <Form.Item
            label={t("warehouseRegistration.phoneNumber")}
            name={["phoneNumber"]}
            rules={[
              {
                required: true,
                message: t("createOrEditOrderPage.form.pleaseInputValidPhone"),
              },
              {
                pattern:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                message: t("createOrEditOrderPage.form.pleaseInputValidPhone"),
              },
            ]}
          >
            <Input placeholder={t("warehouseRegistration.inputPhoneNumber")} />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("warehouseAdmin.warehouseAdminEmail")}
            rules={[
              {
                type: "email",
                message: t("profilePage.inputIsNotValidEmailAddress"),
              },
              {
                required: true,
                message: t("profilePage.pleaseInputEmail"),
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder={t("profilePage.email")}
            />
          </Form.Item>
          <Form.Item
            label={t("warehouseRegistration.address")}
            className="col-12 pr-2"
            name={["address"]}
            rules={[{ required: true }]}
          >
            <AddressComponent
              t={t}
              ref={this.addressRef}
              initialData={initialData?.address}
            />
          </Form.Item>
          <Form.Item
            label={t("warehouseRegistration.location")}
            name={["location", "id"]}
            rules={[
              {
                required: true,
                message: t("warehouseRegistration.inputLocation"),
              },
            ]}
          >
            <Select
              optionFilterProp="children"
              placeholder={t(
                "createOrEditOrderPage.productForm.selectCategory"
              )}
            >
              {locations?.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {t(name)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Content>
    );
  }

  getFieldsValue = () => {
    const { locations } = this.props;
    let value = this.formRef.current.getFieldsValue();
    if (value?.location?.id) {
      let location = locations?.find((i) => i.id === value?.location?.id);
      value.location = location;
    }

    return value;
  };

  setFieldsValue = (values) => {
    if (this.addressRef && this.addressRef.current) {
      this.addressRef.current.setFieldsValue(values?.address);
    }
    this.setState({ initialData: values }, () => {
      this.refresh();
    });
  };

  validateFields = () => {
    return this.formRef.current.validateFields();
  };

  refresh = () => {
    const { initialData } = this.state;
    if (initialData) {
      this.formRef.current.setFieldsValue(initialData);
    }
  };
}

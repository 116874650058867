import actionTypes from "./address.types";
import addressDataService from '../../data-services/address/address-data.service'

export const getCities = () => {
  return async (dispatch) => {
    const res = await addressDataService.getCities()
    dispatch({
      type: actionTypes.GET_CITIES,
      payload: res
    })
  }
}

export const clearData = () => {
  return {
    type: actionTypes.CLEAR_ADDRESS,
    payload: []
  }
}

export const getDistrictByCityId = (cityId) => {
  return async (dispatch) => {
    const res = await addressDataService.getDistrictByCityId(cityId)
    dispatch({
      type: actionTypes.GET_DISTRICT_BY_CITYID,
      payload: { data: res?.districts || [] }
    })
  }
}

export const getWardsByDistrictId = (districtId) => {
  return async (dispatch) => {
    const res = await addressDataService.getWardsByDistrictId(districtId)
    dispatch({
      type: actionTypes.GET_WARDS_BY_DISTRICTID,
      payload: { data: res?.wards || [] }
    })
  }
}

export const setKeyCity = (key) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_KEY_CITY,
      payload: key
    })
  }
}

export const setKeyDistrict = (key) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_KEY_DISTRICT,
      payload: key
    })
  }
}

export const setKeyWard = (key) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_KEY_WARD,
      payload: key
    })
  }
}

export const setDataAddress = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_DATA_ADDRESS,
      payload: data
    })
  }
}

import actionTypes from "./address.types";

const addressInitialState = {
    cities: [],
    wards: [],
    districts: [],
    initKeyData: {
        keyCity: '',
        keyDistrict: '',
        keyWard: '',
        address: ''
    },
};

const addressReducer = (state = addressInitialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CITIES:
            return { ...state, cities: action.payload };
        case actionTypes.GET_DISTRICT_BY_CITYID:
            return { ...state, districts: action.payload?.data, wards: [] };
        case actionTypes.GET_WARDS_BY_DISTRICTID:
            return { ...state, wards: action.payload?.data };
        case actionTypes.SET_KEY_CITY:
            return { ...state, InfoAddress: { ...state.InfoAddress, keyCity: action.payload } };
        case actionTypes.SET_KEY_DISTRICT:
            return { ...state, InfoAddress: { ...state.InfoAddress, keyDistrict: action.payload } };
        case actionTypes.SET_KEY_WARD:
            return { ...state, InfoAddress: { ...state.InfoAddress, keyWard: action.payload } };
        case actionTypes.SET_DATA_ADDRESS:
            return { ...state, InfoAddress: { ...state.InfoAddress, address: action.payload } };
        default:
            return state;
    }
};

export default addressReducer;
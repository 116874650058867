import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Form, Modal, Table, message } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { t } from "i18next";
import React, { Component } from "react";
import TopBar from "../../components/topbar";
import { page, splitToGetCode } from "../../constants/constants";
import { images } from "../../constants/imagesConstant";
import stationTypeDataService from "../../data-services/station-types/station-types.service";
import stationDataService from "../../data-services/stations/stations-data.service";
import { env } from "../../env";

const key = "updatable";
const { confirm } = Modal;

export class StationManagementScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      stationList: [],
      filteredVehicle: [],

      count: 0,
      listStationType: [],
      dataSource: [],
      paging: {
        pageSize: page.pageSizeDefault,
      },
      rowIndexIsFocusing: "",
    };
    this.detailWarehouse = this.detailWarehouse.bind(this);
    this.handleExportData = this.handleExportData.bind(this);
    this.getInfoAddress = this.getInfoAddress.bind(this);
    this.handleImport = this.handleImport.bind(this);

    this.formRef = React.createRef();
  }

  detailWarehouse = (record, history) => {
    this.props.setWarehouseId(record.key);
    history.push(`/admin/warehouse/${record.key}`);
  };

  handleChange = (e) => {
    const isCheckbox = e.target.name === "radioGroup";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
    });
  };

  componentWillMount() {
    this.InitDataTable().then(() => {
      stationTypeDataService.getAllStationType().then((data) => {
        this.setState({ listStationType: data.rows });
      });
      this.props.getCities();
    });
  }

  async InitDataTable() {
    this.fetchStations();
  }

  fetchStations() {
    const { companyId } = this.props;
    if (companyId) {
      this.props.getStations(companyId).then(() => {
        this.getListStationData();
      });
    }
  }

  async getListStationData() {
    const { stations } = this.props;
    const dataSource = this.getData(stations?.rows);

    this.currentData = dataSource;
    this.setState({
      stationList: stations?.rows,
      dataSource: dataSource,
      count: stations?.records,
    });
  }

  openMessage = () => {
    const { t } = this.props;

    message.loading({ content: `${t("status.saving")}`, key });
    setTimeout(() => {
      message.success({ content: `${t("status.loaded")}`, key, duration: 2 });
    }, 1000);
  };

  confirmDelete = (record, isSingleDelete, title) => {
    const currentComponent = this;
    var ids = [];
    const { t } = this.props;
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      okText: `${t("button.ok")}`,
      cancelText: `${t("button.cancel")}`,
      onOk() {
        return new Promise((resolve, reject) => {
          if (isSingleDelete) {
            ids = [record.id];
          } else {
            ids = currentComponent.state.selectedRowKeys;
            currentComponent.setState({ selectedRowKeys: [] });
          }
          stationDataService.deleteStation(ids).then(
            (data) => {
              currentComponent.InitDataTable();
              if (!data.success) message.error({ content: data.message, key, duration: 5 });
              setTimeout(resolve, 1000);
            },
            (error) => {
              setTimeout(reject(error), 500);
            }
          );
        }).catch((errors) => {
          console.log(errors);
        });
      },
      onCancel() {},
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys: selectedRowKeys });
  };

  getData = (stationList) => {
    const { t } = this.props;
    return stationList?.map((x, i) => ({
      key: x.id,
      stationCode: x.code,
      stationName: x.name,
      stationPhone: x.phone,
      status: x.status === 0 ? t("statuses.inActive") : t("statuses.available"),
      location: x.location,
      stationTypeId: x.stationType?.name,
      checked: false,
      id: x.id,
      addressId: x.address?.id,
      infoAddress: x.address,
      stationAddress: this.getInfoAddress(x.address),
      hasWarehouse: x.hasWarehouse,
    }));
  };

  getInfoAddress = (address) => {
    if (address != null) {
      const textDistrict =
        address?.district?.prefix == null
          ? address?.district?.name
          : address?.district?.prefix + " " + address?.district?.name;
      const infoAddress =
        address?.street +
        " " +
        address?.ward?.prefix +
        " " +
        address?.ward?.name +
        " " +
        textDistrict +
        " " +
        address?.city?.name +
        " City";
      return infoAddress;
    } else {
      return null;
    }
  };

  onHandleAddButton = () => {
    this.props.history.push("/station-registration");
  };

  editStation = () => {
    this.props.history.push("/station-registration");
  };

  handleExportData() {
    const link = document.createElement("a");
    link.href = `${env.REACT_APP_API_DOMAIN}/api/Station/export`;
    link.click();
  }

  handleSearch(e) {
    const keySearch = e.currentTarget?.value?.toLowerCase();
    let data = this.currentData;
    if (keySearch) {
      const filteredData = data?.filter(
        (x) =>
          x.stationCode?.toLowerCase()?.includes(keySearch) ||
          x.stationName?.toLowerCase()?.includes(keySearch) ||
          x.stationPhone?.toLowerCase()?.includes(keySearch) ||
          x.stationTypeId?.toLowerCase()?.includes(keySearch) ||
          t(x.status)?.toLowerCase()?.includes(keySearch) ||
          t(x.location)?.toLowerCase()?.includes(keySearch) ||
          x.stationAddress?.toLowerCase()?.includes(keySearch)
      );
      this.setState({ dataSource: filteredData });
    } else {
      this.setState({ dataSource: data });
    }
  }

  handleImport() {
    window.location.href = "/import-station";
  }

  getTableColumns(stationList) {
    var { t } = this.props;

    let statuses = stationList?.map((aStation) => {
      return {
        text: aStation?.status,
        value: aStation?.status,
      };
    });

    let statusOptions = statuses.filter((d, i) => statuses?.findIndex((o) => o.value === d.value) === i);

    let locations = stationList?.map((aStation) => {
      return {
        text: aStation?.location,
        value: aStation?.location,
      };
    });

    let locationOptions = locations.filter((d, i) => locations?.findIndex((o) => o.value === d.value) === i);

    let columns = [
      {
        title: t("station.code"),
        dataIndex: "stationCode",
        sorter: (a, b) => splitToGetCode(a.stationCode) - splitToGetCode(b.stationCode),
        width: 80,
        fixed: "left",
      },
      {
        title: t("station.name"),
        dataIndex: "stationName",
        sorter: (a, b) => a?.stationName?.localeCompare(b.stationName),
        width: 140,
        editable: true,
        render: (text, record) => {
          return <span>{text}</span>;
        },
        fixed: "left",
      },
      {
        title: t("station.phone"),
        dataIndex: "stationPhone",
        sorter: (a, b) => a.stationPhone?.localeCompare(b.stationPhone),
        width: 100,
        editable: true,
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: t("station.address"),
        dataIndex: "stationAddress",
        editable: true,
        width: 400,
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: t("station.location"),
        dataIndex: "location",
        sorter: (a, b) => a.location?.localeCompare(b.location),
        filters: locationOptions,
        onFilter: (value, record) => record.location.indexOf(value) === 0,
        width: 100,
        editable: true,
        ellipsis: true,
        render: (text, record) => {
          return <span>{t(text)}</span>;
        },
      },
      {
        title: t("status.status"),
        dataIndex: "status",
        sorter: (a, b) => a.status?.localeCompare(b.status),
        filters: statusOptions,
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        width: 110,
        editable: true,
        ellipsis: true,
        render: (text, record) => {
          return <span>{t(text)}</span>;
        },
      },
      {
        title: t("status.action"),
        width: 100,
        render: (_, record) => {
          let href = `admin/station/${record.key}/add-warehouse`;
          let stationModificationHref = `admin/station-modification/${record.id}`;
          let warehouseManagementHref = `admin/warehouse/${record.id}`;
          return (
            <span className="action">
              <a className="icon mr-2" href={warehouseManagementHref}>
                <img className="warehouseLogo" src={images.warehouse} />
              </a>

              <a className="icon" href={stationModificationHref}>
                <i className="fa fa-pencil-square-o beecow-icon-edit-color" aria-hidden="true"></i>
              </a>

              <a
                className="icon"
                onClick={() => this.confirmDelete(record, true, `${t("notification.confirm.confirmDeleteThisItem")}`)}
              >
                <i className="fa fa-trash beecow-icon-delete-color" aria-hidden="true"></i>
              </a>
            </span>
          );
        },
      },
    ];

    let mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType:
            col.dataIndex === "stationTypeId" ||
            col.dataIndex === "status" ||
            col.dataIndex === "location" ||
            col.dataIndex === "stationPhone"
              ? "select"
              : "text",
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    return mergedColumns;
  }

  render() {
    var { t } = this.props;
    const { dataSource, selectedRowKeys, count } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      columnWidth: 25,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const columns = this.getTableColumns(dataSource);

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("station.title")} />
          <Content className="content">
            <Card>
              <div className="list-station">
                <div className="select-items clearfix">
                  <div className="lbl-selected">
                    {hasSelected ? `Selected ${selectedRowKeys.length} in ${count}` : ""}
                  </div>
                  <div className="btn-item">
                    <Button className="btn-add" onClick={this.onHandleAddButton}>
                      {" "}
                      {t("button.add")}{" "}
                      <span className="btn-label">
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      </span>
                    </Button>
                    {hasSelected && (
                      <Button
                        onClick={() => this.confirmDelete(null, false, "Do you want to delete these items?")}
                        className="btn-remove"
                      >
                        {" "}
                        {t("button.delete")}{" "}
                        <span className="btn-label">
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </span>
                      </Button>
                    )}
                    <Button onClick={this.handleImport} className="btn-import">
                      {t("button.import")}{" "}
                      <span className="btn-label">
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </span>
                    </Button>
                    <Button onClick={this.handleExportData} className="btn-import">
                      {" "}
                      {t("button.export")}{" "}
                      <span className="btn-label">
                        <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                      </span>
                    </Button>
                  </div>
                  <div className="input-search">
                    <form className="form-search">
                      <input
                        className="search form-control mr-sm-2"
                        onChange={(e) => this.handleSearch(e)}
                        type="search"
                        placeholder={t("placeholder.search")}
                        aria-label="Search"
                      />
                    </form>
                  </div>
                </div>

                <Form ref={this.formRef}>
                  <Table
                    rowSelection={rowSelection}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={this.state.paging}
                    scroll={{ x: "max-content" }}
                  />
                </Form>
              </div>
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }

  editStation() {}
}

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, InputNumber, Modal, Popconfirm, Select, Table } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { Option } from "antd/lib/mentions";
import { t } from "i18next";
import React from "react";
import TopBar from "../../components/topbar";
import { page, splitToGetCode } from "../../constants/constants";
import stationDataService from "../../data-services/stations/stations-data.service";
import vehicleDataService from "../../data-services/vehicles/vehicles-data.service";
import { env } from "../../env";
import languageService from "../../services/language.service";

const listStatus = [
  { id: 42, statusName: "statuses.waitingForImportLastStation" },
  { id: 40, statusName: "statuses.inTransit" },
  { id: 38, statusName: "statuses.assignedToExportList" },
  { id: 35, statusName: "statuses.inActive" },
  { id: 28, statusName: "statuses.available" },
  { id: 26, statusName: "statuses.assignedDriver" },
  { id: 25, statusName: "statuses.assignedPickupList" },
];
const { confirm } = Modal;

export class VehicleManagementScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      itemDelete: [],
      nameItem: "",
      vehicleList: [],
      filteredVehicle: [],
      editingKey: "",
      checkdVehicleId: [],
      listStation: [],
      wokingDayEnums: {},
      listTransportTypes: [],
      //Model
      vehicleModel: {
        id: "",
        type: "",
        stationId: "",
        status: {},
        workingDays: [],
        transportType: "",
        volume: 0,
      },

      dataSource: [],
      vehicleTypes: [],
      count: 0,
      paging: {
        pageSize: page.pageSizeDefault,
      },
    };
    this.handleImport = this.handleImport.bind(this);
    this.onSelectedType = this.onSelectedType.bind(this);
    this.edit = this.edit.bind(this);
    this.isEditing = this.isEditing.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.formRef = React.createRef();
    this.editData = {};
    this.isCheckAll = this.isCheckAll.bind(this);
  }

  componentWillMount() {
    this.initTableData();

    vehicleDataService.getTransportTypes().then((data) => {
      this.setState({ listTransportTypes: data });
    });

    vehicleDataService.getWorkingDays().then((data) => {
      this.setState({ wokingDayEnums: data });
    });

    vehicleDataService.getVehicleTypes().then((data) => {
      this.setState({
        vehicleTypes: data,
      });
    });

    stationDataService.getStations().then((data) => {
      this.setState({ listStation: data });
    });
  }

  getTableColumns(vehicleList) {
    const { t } = this.props;

    let vehicleTypes = vehicleList?.map((aVehicle) => {
      return {
        text: t(aVehicle?.type?.name),
        value: aVehicle?.type?.id,
      };
    });

    let volume = vehicleList?.map((aVehicle) => {
      return aVehicle.volume;
    });

    let vehicleTypeOptions = vehicleTypes?.filter((d, i) => vehicleTypes?.findIndex((o) => o.value === d.value) === i);

    let transportTypes = vehicleList?.map((aVehicle) => {
      return {
        text: t(aVehicle?.transportType),
        value: aVehicle?.transportType,
      };
    });

    let transportTypeOptions = transportTypes?.filter(
      (d, i) => transportTypes?.findIndex((o) => o.value === d.value) === i
    );

    let currentStations = vehicleList
      ?.filter((aVehicle) => aVehicle?.currentStation?.length > 0)
      ?.map((aVehicle) => {
        return {
          text: t(aVehicle?.currentStation),
          value: aVehicle?.currentStation,
        };
      });

    let currentStationOptions = currentStations?.filter(
      (d, i) => currentStations?.findIndex((o) => o.value === d.value) === i
    );

    let stations = vehicleList
      ?.filter((aVehicle) => aVehicle?.stationId?.length > 0)
      ?.map((aVehicle) => {
        return {
          text: aVehicle?.stationId,
          value: aVehicle?.stationId,
        };
      });

    let stationOptions = stations?.filter((d, i) => stations?.findIndex((o) => o.value === d.value) === i);

    let statuses = vehicleList?.map((aVehicle) => {
      return {
        text: t(aVehicle?.status?.languageKey || aVehicle?.status?.statusName),
        value: aVehicle?.status?.id,
      };
    });

    let statusOptions = statuses?.filter((d, i) => statuses?.findIndex((o) => o.value === d.value) === i);

    let columns = [
      {
        title: t("vehicle.id"),
        dataIndex: "code",
        width: "120px",
        fixed: "left",
        sorter: (a, b) => splitToGetCode(a.code) - splitToGetCode(b.code),
      },
      {
        title: t("vehicle.type"),
        dataIndex: "type",
        width: "150px",
        editable: true,
        fixed: "left",
        filters: vehicleTypeOptions,
        onFilter: (value, record) => {
          if (record.type === undefined || record.type === "" || record.type === null) return true;
          return record.type.id.indexOf(value) === 0;
        },
        sorter: (a, b) => a.type?.name.localeCompare(b.type?.name),
        render: (text, { isNew }) => {
          const { vehicleTypes } = this.state;
          const vehicleType = text;

          if (isNew) {
            const children = [];
            vehicleTypes.forEach(function (item, index) {
              children.push(
                <Option key={item?.id} value={item?.id}>
                  {t(item?.name)}
                </Option>
              );
            });

            return (
              <>
                <Select onChange={this.onSelectedType} optionFilterProp="children" style={{ width: "100%" }}>
                  {children}
                </Select>
              </>
            );
          }

          return <span>{t(vehicleType?.name)}</span>;
        },
      },
      {
        title: t("vehicle.volume"),
        dataIndex: "volume",
        editable: true,
        width: "150px",
        render: (data, { isNew }) => {
          let localLanguage = languageService.getLang();
          if (isNew) {
            return (
              <InputNumber
                onChange={(value) =>
                  this.setState({
                    ...this.state,
                    vehicleModel: {
                      ...this.state.vehicleModel,
                      volume: value,
                    },
                  })
                }
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                parser={(value) => value.replace(/\$\s?|(\.*)/g, "")}
                style={{ width: "100%" }}
              />
            );
          }
          return <span>{data.toLocaleString(localLanguage)}</span>;
        },
      },
      {
        title: t("vehicle.workingDay"),
        dataIndex: "workingDays",
        editable: true,
        width: "200px",
        ellipsis: true,
        render: (data, { isNew }) => {
          const { wokingDayEnums } = this.state;
          if (isNew) {
            const children = [];
            wokingDayEnums.forEach(function (item, index) {
              children.push(
                <Option key={item.id} value={item.id}>
                  {t(item.name)}
                </Option>
              );
            });

            return (
              <>
                <Select onChange={this.onSelectedWorkingDays} mode="multiple" allowClear style={{ width: "100%" }}>
                  {children}
                </Select>
              </>
            );
          }

          return this.formatWorkingDayText(data);
        },
      },
      {
        title: t("vehicle.transportType"),
        dataIndex: "transportType",
        editable: true,
        filters: transportTypeOptions,
        onFilter: (value, record) => {
          if (record.transportType === undefined || record.transportType === "" || record.transportType === null)
            return true;
          return record.transportType.indexOf(value) === 0;
        },
        width: "150px",
        sorter: (a, b) => a.transportType?.localeCompare(b.transportType),
        render: (transportType, { isNew }) => {
          const { listTransportTypes } = this.state;
          if (isNew) {
            return (
              <>
                <Select
                  value={this.state.TransportType}
                  onChange={this.onSelectedTransportTypes}
                  style={{ width: "100%" }}
                >
                  {listTransportTypes.map(({ id, name }) => (
                    <Option value={id}>{t(name)}</Option>
                  ))}
                </Select>
              </>
            );
          }

          return <span>{t(transportType)}</span>;
        },
      },
      {
        title: t("vehicle.currentStation"),
        dataIndex: "currentStation",
        editable: true,
        width: "200px",
        ellipsis: true,
        filters: currentStationOptions,
        onFilter: (value, record) => {
          if (record.currentStation === undefined || record.currentStation === "" || record.currentStation === null)
            return true;
          return record.currentStation?.indexOf(value) === 0;
        },
        sorter: (a, b) => a.currentStation?.localeCompare(b.currentStation),
        render: (text, { isNew }) => {
          return <span>{text}</span>;
        },
      },
      {
        title: t("vehicle.assignedStation"),
        dataIndex: "stationId",
        editable: true,
        width: "15%",
        ellipsis: true,
        filters: stationOptions,
        onFilter: (value, record) => {
          if (record.stationId === undefined || record.stationId === "" || record.stationId === null) return true;
          return record.stationId?.indexOf(value) === 0;
        },
        sorter: (a, b) => a.stationId?.localeCompare(b.stationId),
        render: (text, { isNew }) => {
          const { listStation } = this.state;

          if (isNew) {
            const children = [];
            listStation.forEach(function (item, index) {
              children.push(
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            });

            return (
              <>
                <Select onChange={this.onSelectedStationId} style={{ width: "100%" }}>
                  {children}
                </Select>
              </>
            );
          }

          return <span>{text}</span>;
        },
      },
      {
        title: t("status.status"),
        dataIndex: "status",
        editable: true,
        width: "15%",
        filters: statusOptions,
        onFilter: (value, record) => {
          if (record.status === undefined || record.status === "" || record.status === null) return true;
          return record.status.id === value;
        },
        sorter: (a, b) => a.status?.statusName?.localeCompare(b?.status?.statusName),
        render: (status, { isNew }) => {
          if (isNew) {
            return (
              <>
                <Select
                  value={this.state.status.id}
                  className="status-vehicle"
                  onChange={this.onSelectedStatus}
                  style={{ width: "100%" }}
                >
                  {listStatus.map(({ id, statusName }) => (
                    <Option className="status-vehicle" value={id}>
                      {t(statusName)}
                    </Option>
                  ))}
                </Select>
              </>
            );
          }
          return <div className="status available">{t(status?.languageKey || status?.statusName)}</div>;
        },
      },
      {
        title: t("status.action"),
        width: "10%",
        render: (_, record) => {
          const editable = this.isEditing(record);
          const renderNotEdit = () => {
            if (record.isNew) {
              return (
                <>
                  <a onClick={() => this.handleAdd()} style={{ marginRight: 8 }}>
                    <i className="fa fa-floppy-o save" aria-hidden="true"></i>
                  </a>
                  <Popconfirm
                    title={t("notification.confirm.confirmCancel")}
                    onConfirm={() => this.buttonCancelAdd(record)}
                    okText={t("button.ok")}
                    cancelText={t("button.cancel")}
                  >
                    <a>
                      <i className="fa fa-times-circle-o delete" aria-hidden="true"></i>
                    </a>
                  </Popconfirm>
                </>
              );
            }

            return (
              <>
                <a onClick={() => this.edit(record)}>
                  <i className="fa fa-pencil-square-o edit" aria-hidden="true" />
                </a>
                <a
                  disabled={this.state.editingKey !== ""}
                  onClick={() => this.confirmDelete(record, true, `${t("notification.confirm.confirmDeleteThisItem")}`)}
                >
                  <i className="fa fa-trash delete" aria-hidden="true"></i>
                </a>
              </>
            );
          };

          return editable == true ? (
            <span className="action">
              <a onClick={() => this.save(record)} style={{ marginRight: 8 }}>
                <i className="fa fa-floppy-o save" aria-hidden="true"></i>
              </a>
              <Popconfirm
                title={t("notification.confirm.confirmCancel")}
                onConfirm={this.cancel}
                okText={t("button.ok")}
                cancelText={t("button.cancel")}
              >
                <a>
                  <i className="fa fa-times-circle-o delete" aria-hidden="true"></i>
                </a>
              </Popconfirm>
            </span>
          ) : (
            <span className="action">{renderNotEdit()}</span>
          );
        },
      },
    ];

    let mergedColumns = columns.map((col, index) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType:
            col.dataIndex === "workingDays" ||
            col.dataIndex === "currentStation" ||
            col.dataIndex === "status" ||
            col.dataIndex === "transportType"
              ? "select"
              : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return mergedColumns;
  }

  render() {
    const { dataSource, editingKey } = this.state;

    const totalSelected = dataSource?.filter((p) => p.checked)?.length || 0;
    const selectedRowKeys = dataSource?.filter((p) => p.checked).map((x) => x.key);
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const isDisable = editingKey.length > 0;

    let columns = this.getTableColumns(dataSource);
    var { t } = this.props;

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("vehicle.title")} />
          <Content className="content">
            <Card>
              <div className="list-vehicle">
                <div className="select-items clearfix">
                  <div className="row">
                    <div className="col-sm-4 select-number gutter-row">
                      <p>
                        {t("label.selected")} {totalSelected} {t("vehicle.vehicle")}
                      </p>
                    </div>
                    <div className="col-sm-8">
                      <div className="col-sm-8 action gutter-row">
                        <div className="group-btn btn-item">
                          <Button
                            disabled={isDisable}
                            className="btn-labeled btn-add"
                            onClick={this.buttonAdd}
                            style={{
                              marginBottom: 16,
                            }}
                          >
                            {" "}
                            {t("button.add")}{" "}
                            <span className="btn-label">
                              <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            </span>
                          </Button>
                          {this.isShowDelete() && (
                            <Button
                              disabled={isDisable}
                              onClick={() =>
                                this.confirmDelete(
                                  null,
                                  false,
                                  `${this.props.t("notification.confirm.confirmDeleteTheseItems")}`
                                )
                              }
                              className="btn-labeled remove btn-remove"
                            >
                              {" "}
                              {t("button.remove")}{" "}
                              <span className="btn-label">
                                <i className="fa fa-trash" aria-hidden="true"></i>
                              </span>
                            </Button>
                          )}
                          <Button disabled={isDisable} className="btn-labeled import btn-import">
                            {t("button.import")}{" "}
                            <span className="btn-label">
                              <i className="fa fa-download" aria-hidden="true"></i>
                            </span>
                          </Button>
                          <Button disabled={isDisable} className="btn-labeled import btn-import">
                            {" "}
                            {t("button.export")}{" "}
                            <span className="btn-label">
                              <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                            </span>
                          </Button>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <input
                          disabled={isDisable}
                          className="search form-control mr-sm-2"
                          onChange={(e) => this.handleSearch(e)}
                          type="search"
                          placeholder={t("placeholder.search")}
                          aria-label="Search"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Form ref={this.formRef}>
                  <Table
                    rowSelection={rowSelection}
                    components={{
                      body: {
                        cell: this.editInline,
                      },
                    }}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={this.state.paging}
                    scroll={{ x: "100vw" }}
                  />
                </Form>
              </div>
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }

  initTableData() {
    vehicleDataService.getListVehicle().then((data) => {
      const vehicles = this.mapping(data);
      this.currentData = vehicles;
      this.setState({
        dataSource: vehicles,
        count: data.records,
      });
    });
  }

  isEditing = (record) => record.key === this.state.editingKey;

  edit = (record) => {
    this.formRef?.current?.setFieldsValue({
      vehicleID: "",
      vehicleType: "",
      workingDays: "",
      stationId: "",
      status: 0,
      transportType: 0,
      volume: 0,
      ...record,
    });

    if (record?.key) {
      vehicleDataService.getVehicleById(record.key).then((res) => {
        const model = {
          id: res.id,
          type: res.type?.id,
          workingDays: res.workingDays?.map((i) => i.id),
          stationId: res.assignedStation?.id,
          status: res.status?.id,
          transportType: res.transportType,
          volume: res.volume,
        };

        this.setState({
          vehicleModel: model,
          editingKey: record.key,
        });
      });
    }
  };

  save = async (record) => {
    const updateModel = this.state.vehicleModel;
    vehicleDataService.updateVehicle(updateModel).then(() => this.initTableData());
    this.setState({ editingKey: "" });
  };

  confirmDelete = (record, isSingleDelete, title) => {
    const currentComponent = this;
    var ids = [];
    const { t } = this.props;
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      okText: `${t("button.ok")}`,
      cancelText: `${t("button.cancel")}`,
      onOk() {
        return new Promise((resolve, reject) => {
          if (isSingleDelete) {
            ids = [record.id];
          } else {
            ids = currentComponent.state.selectedRowKeys;
            currentComponent.setState({ selectedRowKeys: [] });
          }
          vehicleDataService.deleteVehicles(ids).then(
            (data) => {
              currentComponent.initTableData();
              setTimeout(resolve, 1000);
            },
            (error) => {
              setTimeout(reject(error), 500);
            }
          );
        }).catch((errors) => {
          console.log(errors);
        });
      },
      onCancel() {},
    });
  };

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  onSelectedType = (value) => {
    var vehicleModel = this.state.vehicleModel;
    vehicleModel.type = value;
    this.setState({
      vehicleModel: vehicleModel,
    });
  };

  onSelectedWorkingDays = (value) => {
    var vehicleModel = this.state.vehicleModel;
    vehicleModel.workingDays = value;
    this.setState({
      vehicleModel: vehicleModel,
    });
  };

  onSelectedStationId = (value) => {
    var vehicleModel = this.state.vehicleModel;
    vehicleModel.stationId = value;
    this.setState({
      vehicleModel: vehicleModel,
    });
  };

  onSelectedTransportTypes = (value) => {
    var vehicleModel = this.state.vehicleModel;
    vehicleModel.transportType = value;
    this.setState({
      vehicleModel: vehicleModel,
    });
  };

  onSelectedStatus = (value) => {
    var vehicleModel = this.state.vehicleModel;
    vehicleModel.status = value;
    this.setState({
      vehicleModel: vehicleModel,
    });
  };

  onChecked(key) {
    const { dataSource } = this.state;
    const updateProduct = dataSource.find((x) => x.key === key);

    if (updateProduct) {
      updateProduct.checked = !updateProduct.checked;
    }

    this.isCheckAll();
    this.setState({ dataSource });
  }

  isCheckAll() {
    const elm = document.getElementById("check-all");
    const { dataSource } = this.state || {};

    if (elm) {
      elm.checked = dataSource?.length && dataSource?.every((p) => p.checked);
    }
  }

  isShowDelete() {
    const { dataSource } = this.state;
    return dataSource?.some((p) => p.checked);
  }

  onSelectChange = (selectedRowKeys) => {
    const { dataSource } = this.state;
    dataSource?.forEach((p) => {
      p.checked = selectedRowKeys.includes(p.key);
    });
    this.setState({ dataSource, selectedRowKeys: selectedRowKeys });
  };

  /**
   * Get working day text
   * @param {object} data
   */
  formatWorkingDayText(data) {
    const { t } = this.props;
    const days = "1234567";
    let sortDay = data?.map((item) => `${item?.sort}`).join("");
    let printDay = "";

    if (data.length >= 3) {
      if (days.includes(sortDay)) {
        printDay = `${t(data[0].name)} → ${t(data[data.length - 1].name)}`;
      } else {
        let workingDaysValue = this.handleWorkingDays(sortDay);
        if (workingDaysValue != undefined && workingDaysValue != null && workingDaysValue != "") {
          let { daySingle, dayMultiple } = workingDaysValue;
          let coutDayMultiple = dayMultiple.length;
          let daySingleLength = daySingle.split("");
          if (dayMultiple.length > 0) {
            dayMultiple.forEach((ele) => {
              let firstDay = data?.filter((a) => a.sort == ele[0]);
              let lastDay = data?.filter((a) => a.sort == ele[ele.length - 1]);
              let singleDaysGreaterThanFirstDay = daySingleLength.filter((a) => a < ele[0]);
              let singleDaysLessThanFirstDay = daySingleLength.filter((a) => a > ele[ele.length - 1]);

              if (singleDaysGreaterThanFirstDay.length > 0) {
                singleDaysGreaterThanFirstDay.forEach((day) => {
                  let nameDay = data?.filter((a) => a.sort == day)[0].name;
                  printDay = printDay + `${t(nameDay)}, `;
                });
              }

              printDay = printDay + `${t(firstDay[0].name)} → ${t(lastDay[0].name)}`;

              if (singleDaysLessThanFirstDay.length > 0) {
                singleDaysLessThanFirstDay.forEach((day) => {
                  let nameDay = data?.filter((a) => a.sort == day)[0].name;
                  printDay = printDay + `, ${t(nameDay)}`;
                });
              }

              coutDayMultiple--;
            });
          }
        } else {
          printDay = data?.map((item) => t(item?.name)).join(", ");
        }
      }
    } else {
      printDay = data?.map((item) => t(item?.name)).join(", ");
    }

    return <span className="cellTable">{printDay}</span>;
  }

  mapping = ({ rows }) =>
    rows?.map((item) => {
      return {
        key: item.id,
        id: item.id,
        code: item.code,
        type: item.type,
        currentStation: item.currentStation?.name,
        stationId: item.assignedStation?.name,
        transportType: item.vehicleTransportType?.name,
        status: item.status,
        workingDays: item.workingDays,
        checked: false,
        volume: item.volume,
      };
    });

  /**
   * Add functions
   */
  handleAdd() {
    const model = this.state.vehicleModel;
    var requestModel = {
      type: model.type,
      workingDays: model.workingDays,
      stationId: model.stationId,
      status: model.status,
      transportType: model.transportType,
      volume: model.volume,
    };

    vehicleDataService.addVehicle(requestModel).then(() => this.initTableData());
  }

  buttonCancelAdd(data) {
    const { dataSource } = this.state;
    const newData = dataSource.filter((x) => x.key !== data.key);
    this.setState({ dataSource: newData });
  }

  buttonAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      isNew: true,
    };

    this.setState({
      dataSource: [newData, ...dataSource],
      count: count + 1,
      type: "",
      workingDays: [],
      stationId: "",
      status: "",
      transportType: "",
      volume: 0,
    });
  };

  editInline = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const renderContent = () => {
      const { t } = this.props;
      const { listStation, wokingDayEnums, vehicleTypes, listTransportTypes } = this.state;
      if (dataIndex === "workingDays") {
        var currentWorkingDays = record[dataIndex];
        var workingDays = wokingDayEnums;
        var selected = currentWorkingDays.map((item) => item.id);

        const children = [];
        workingDays.forEach(function (item, index) {
          children.push(
            <Option key={item.id} value={item.id}>
              {t(item.name)}
            </Option>
          );
        });

        return (
          <Select
            defaultValue={selected}
            mode="multiple"
            allowClear
            onChange={this.onSelectedWorkingDays}
            optionFilterProp="children"
            style={{ width: "100%" }}
          >
            {children}
          </Select>
        );
      } else if (dataIndex === "transportType") {
        return (
          <Select
            defaultValue={t(record[dataIndex])}
            onChange={this.onSelectedTransportTypes}
            style={{ width: "100%" }}
          >
            {listTransportTypes.map((value) => (
              <Option value={value.id}>{t(value.name)}</Option>
            ))}
          </Select>
        );
      } else if (dataIndex === "stationId") {
        return (
          <Select defaultValue={record[dataIndex]} onChange={this.onSelectedStationId} style={{ width: "100%" }}>
            {listStation.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </Select>
        );
      } else if (dataIndex === "status") {
        return (
          <Select
            defaultValue={record[dataIndex].id}
            onChange={this.onSelectedStatus}
            className="status-vehicle"
            style={{ width: "100%" }}
          >
            {listStatus.map(({ id, statusName }) => (
              <Option className="status-vehicle" value={id}>
                {t(statusName)}
              </Option>
            ))}
          </Select>
        );
      } else if (dataIndex === "type") {
        var data = record[dataIndex];

        return (
          <Select defaultValue={data.id} onChange={this.onSelectedType} style={{ width: "100%" }}>
            {vehicleTypes.map(({ id, name }) => (
              <Option key={id} value={id}>
                {t(name)}
              </Option>
            ))}
          </Select>
        );
      } else if (dataIndex === "currentStation") {
        return <span>{record[dataIndex]}</span>;
      } else if (dataIndex === "volume") {
        let data = record[dataIndex];
        return (
          <InputNumber
            onChange={(value) =>
              this.setState({
                ...this.state,
                vehicleModel: {
                  ...this.state.vehicleModel,
                  volume: value,
                },
              })
            }
            defaultValue={data}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
          />
        );
      }

      return <Input defaultValue={record[dataIndex]} />;
    };

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `\`${this.props.t("notification.input")}\` ${title}!`,
              },
            ]}
          >
            {renderContent()}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  handleCheckAll(e, data) {
    const { dataSource } = this.state;
    dataSource.forEach((p) => {
      p.checked = data.checked;
    });

    this.setState({ dataSource });
  }

  handleSearch(e) {
    const keySearch = e.currentTarget?.value;
    let data = this.currentData;

    if (keySearch) {
      const filteredData = data?.filter(
        (x) =>
          x.code?.toLowerCase()?.includes(keySearch) ||
          x.type?.name?.toLowerCase()?.includes(keySearch) ||
          x.currentStation?.toLowerCase()?.includes(keySearch) ||
          x.stationId?.toLowerCase()?.includes(keySearch) ||
          x.transportType?.toLowerCase()?.includes(keySearch) ||
          t(x?.status?.languageKey)?.toLowerCase()?.includes(keySearch) ||
          x.workingDays?.filter((w) => w?.name?.toLowerCase()?.includes(keySearch))?.length > 0
      );
      this.setState({ dataSource: filteredData });
    } else {
      this.setState({ dataSource: data });
    }
  }

  handleExportData() {
    const link = document.createElement("a");
    link.href = `${env.REACT_APP_API_DOMAIN}/api/Vehicle/export`;
    link.click();
  }

  handleImport() {
    window.location.href = "/import-vehicle";
  }

  handleWorkingDays(stringDays, dayMultiple = [], daySingle = "") {
    const days = "1234567";
    let print = {
      dayMultiple: dayMultiple,
      daySingle: daySingle,
    };
    let isContinue = false;
    for (let i = 0; i < stringDays.length; i++) {
      for (let j = 0; j < stringDays.length; j++) {
        let subStringSortDay = stringDays.substring(i, stringDays.length - j);
        if (subStringSortDay.length >= 3 && days.includes(subStringSortDay)) {
          print.dayMultiple.push(subStringSortDay);
          stringDays = stringDays.replace(subStringSortDay, "");
          print.daySingle = stringDays;
          if (stringDays.length >= 3) {
            print.daySingle = "";
            isContinue = true;
            break;
          } else {
            isContinue = false;
            return print;
          }
        }
      }
    }
    if (isContinue) {
      this.callBackHandleWorkingDays(stringDays, print.dayMultiple, print.daySingle);
    }
  }

  callBackHandleWorkingDays(stringDays, dayMultiple, daySingle) {
    this.handleWorkingDays(stringDays, dayMultiple, daySingle);
  }
}

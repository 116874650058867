import actionTypes from "./products.types";

const productsInitialState = {
    productDetails: {}
};

const productsReducer = (state = productsInitialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCT_DETAILS:
            return { ...state, productDetails: action.payload };
        default:
            return state;
    }
};

export default productsReducer;
import { connect } from 'react-redux';
import { TopBar } from './topbar.component';
import { resetSession, switchWorkspace } from '../../modules/session/session.actions';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { setJustLoggedIn } from '../../modules/app/app.actions';

const mapStateToProps = (state) => {
    return {
        signedInUser: state.session?.auth?.user,
        workspaceId: state.session?.workspace?.workspaceId,
        switchWorkspaceId: state.session?.workspace?.switchWorkspaceId,
        companyLogoUrl: (state.session?.workspace?.switchWorkspaceId)? state.session?.workspace?.switchInfo?.logoPath : state.session?.workspace?.info?.logoPath,
        companyName: (state.session?.workspace?.switchWorkspaceId)? state.session?.workspace?.switchInfo?.name : state.session?.workspace?.info?.name,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchWorkspace: (workspaceId, workspace) => dispatch(switchWorkspace(workspaceId, workspace)),
        signOut: (request) => dispatch(resetSession(request)),
        setJustLoggedIn: (value) => dispatch(setJustLoggedIn(value))
    }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(TopBar)
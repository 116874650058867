import { bindActionCreators } from 'redux';
import TableInnerCityFees from './table-inner-city-fees.component'
import { getInnerCityFees } from '../../../modules/shipping-fee/shipping-fee.actions'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ shippingFee }) => {
  return {
    cities: shippingFee.cities || [],
    innerCityFees: shippingFee.innerCityFees || [],
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getInnerCityFees
  }, dispatch)
}

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
  )(TableInnerCityFees)
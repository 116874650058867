import moment from "moment";
import languageService from "../services/language.service";
import { FORMAT_DATETIME, locationList, statusList } from "../constants/common";

export const formatDate = (date, format) => {
  if (format) {
    return moment
      .utc(date)
      .local()
      .locale(languageService.getLang())
      .format(format);
  }
  return moment
    .utc(date)
    .local()
    .locale(languageService.getLang())
    .format(FORMAT_DATETIME);
};

export const formatAddress = (address, t) => {
  if (languageService.getLang().includes("vi")) {
    return (
      address?.street +
      ", " +
      t("text.ward") +
      " " +
      address?.ward?.name +
      ", " +
      t("text.district") +
      " " +
      address?.district?.name +
      ", " +
      t("text.city") +
      " " +
      address?.city?.name
    );
  } else {
    return (
      address?.street +
      ", " +
      address?.ward?.name +
      " " +
      t("text.ward") +
      ", " +
      address?.district?.name +
      " " +
      t("text.district") +
      ", " +
      address?.city?.name +
      " " +
      t("text.city")
    );
  }
};

export const formatMoney = (number) => {
  if (isNaN(number) || number == null) {
    return "0 VNĐ";
  }

  return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " VNĐ";
};

export const getStatusName = (value) => {
  let currentStatus = statusList.find((status) => status.value === value);
  return currentStatus?.text;
};

export const getLocationName = (value) => {
  let currentLocation = locationList.find(
    (location) => location.text === value
  );
  return currentLocation?.text;
};

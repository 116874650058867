import { DataService } from "../base/base-data.service";
class ProductsDataService extends DataService {
  apiUrl = '/products'

  
  async getProductDetail(productId) {
      return await this.get(`${this.apiUrl}/get-product-details?id=${productId}`);
  }

}

let productsDataService = new ProductsDataService();

export default productsDataService;
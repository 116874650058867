import moment from "moment";
import React, { Component } from "react";
import { Option } from "antd/lib/mentions";
import languageService from "../../../services/language.service";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import userDataService from "../../../data-services/user/user-data.service";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
  message,
  Modal,
} from "antd";
import SelectAdress from "../../shared/components/select-address/select-address-container";
import {
  station,
  driverInnerCity,
  driverInterProvince,
  admin,
  superAdmin,
  warehouseAdmin,
} from "../../../constants/user-constants";

export class EditUserScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      showStation: false,
      selectedTypeUser: "",
      ruleChangeTypeUser: false,
      ModalConfirmChangePassword: {
        visible: false,
        modalText: `${props.t("notification.changePassword.confirmAgain")}`,
        confirmLoading: false,
      },
    };
    this.formRef = React.createRef();
    this.changeUserType = this.changeUserType.bind(this);
    this.backPage = this.backPage.bind(this);
  }

  componentDidMount() {
    const { userId } = this.props?.match?.params || {};
    this.props.getCities();
    this.props.getInitUser();
    userDataService.getUserId(userId).then((res) => {
      const { address, userTypeId } = res || {};
      const { cityId, districtId, wardId } = address || {};

      if (res.doB === "0001-01-01T00:00:00") {
        res.doB = "1970-01-01T00:00:00";
      }
      res.doB = moment(res.doB);
      this.phone = res.phone;
      this.email = res.email;
      this.passwordHash = res.passwordHash;
      this.setFieldToEmpty({ ...res });
      this.forceUpdate();

      if (districtId) this.props.getDistrictByCityId(cityId);
      if (wardId) this.props.getWardsByDistrictId(districtId);
      let ruleChangeTypeUser = false;
      if (this.props?.auth?.user?.userId !== userId) {
        ruleChangeTypeUser = true;
      }
      this.setState({
        showStation: this.props.initUser.roles?.some(
          (x) =>
            x.id === userTypeId &&
            (x.code === station ||
              x.code === driverInnerCity ||
              x.code === driverInterProvince ||
              x.code === warehouseAdmin)
        ),
        ruleChangeTypeUser: ruleChangeTypeUser,
        selectedStationId: userTypeId,
      });
    });
  }

  setFieldToEmpty(data) {
    this.formRef.current.setFieldsValue(data);
  }

  getCity(city) {
    return this.props.cities?.cities.find((x) => x.id === city)?.name || "";
  }

  updateUser(values) {
    if (typeof values.stationIds === "string")
      values.stationIds = [values.stationIds];

    userDataService
      .update(values)
      .then(() => {
        message.success(
          `${this.props.t("notification.userProfile.updateSuccess")}`
        );
        this.props.updateUserName(values.fullName);
        this.backPage();
      })
      .catch(({ request }) => {
        message.error(request.message);
      });
  }

  resetPassword = () => {
    const { history } = this.props;
    const { userId } = this.props?.match?.params || {};

    this.setState({
      ModalConfirmChangePassword: {
        ...this.state.ModalConfirmChangePassword,
        confirmLoading: true,
      },
    });

    if (userId) {
      userDataService
        .resetPassword({
          id: userId,
        })
        .then(() => {
          this.setState({
            ModalConfirmChangePassword: {
              ...this.state.ModalConfirmChangePassword,
              confirmLoading: false,
              visible: false,
            },
          });
          message.success(
            `${this.props.t(
              "notification.changePassword.resetPasswordSuccess"
            )}`
          );
          if (userId === this.props?.auth?.user?.userId) {
            try {
              history?.push(`/login/${userId}`);
              this.props.signOut({ userId });
            } catch (e) {
              console.log(e);
            }
          }
        });
    }
  };

  showModal = () => {
    const { userId } = this.props?.match?.params || {};
    const { t } = this.props;

    let modalText = `${t("notification.changePassword.confirmChangePassword")}`;

    if (userId === this.props?.auth?.user?.userId) {
      modalText = `${t("notification.changePassword.confirmAgain")}`;
    }
    this.setState({
      ModalConfirmChangePassword: {
        ...this.state.ModalConfirmChangePassword,
        visible: true,
        modalText: modalText,
      },
    });
  };

  handleCancel = () => {
    this.setState({
      ModalConfirmChangePassword: {
        ...this.state.ModalConfirmChangePassword,
        visible: false,
      },
    });
  };

  changeUserType(value) {
    this.setState({ selectedStationId: value });
    this.formRef.current.setFieldsValue({ stationIds: "" });
    this.forceUpdate();
  }

  backPage() {
    this.props.history?.goBack();
  }

  checkShowStation() {
    const { userTypeId } = this.formRef.current?.getFieldsValue() || {};
    return this.props.initUser.roles?.some(
      (x) =>
        x.id === userTypeId &&
        (x.code === station ||
          x.code === driverInnerCity ||
          x.code === driverInterProvince ||
          x.code === warehouseAdmin)
    );
  }

  getListUserTypeCanEdit = (roles) => {
    var result = [];
    const { userId } = this.props?.match?.params;
    if (this.props?.auth?.user?.userId !== userId) {
      switch (this.props?.auth?.user?.role) {
        case superAdmin:
          result = roles?.filter((x) => x.code !== superAdmin);
          break;
        case admin:
          result = roles?.filter(
            (x) => x.code !== admin && x.code !== superAdmin
          );
          break;
        default:
          break;
      }
    } else {
      result = roles?.filter((x) => x.code === this.props?.auth?.user?.role);
    }
    return result;
  };

  render() {
    const { selectedStationId } = this.state;
    const { initUser } = this.props;
    const { status, stations } = initUser;
    const showMultiStation =
      initUser.roles?.find(
        (x) => x.code.toLowerCase() === driverInterProvince.toLowerCase()
      )?.id === selectedStationId;

    const roles = this.getListUserTypeCanEdit(initUser.roles);
    const showStation = this.checkShowStation();
    var { t } = this.props;

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <Header className="header">
            <div className="lbl-header">
              <p>{t("button.update")}</p>
            </div>
          </Header>
          <div className="bg-white add-edit-user clearfix">
            <Content>
              <Form
                ref={this.formRef}
                layout="vertical"
                requiredMark={false}
                onFinish={this.updateUser.bind(this)}
              >
                <Row>
                  <Form.Item name="id" hidden />
                  <Col span={8} offset={2}>
                    <Form.Item
                      label={t("user.fullName")}
                      name="fullName"
                      rules={[
                        {
                          required: true,
                          message: `${t("notification.login.userName")}`,
                        },
                      ]}
                    >
                      <Input placeholder={t("placeholder.inputName")} />
                    </Form.Item>
                    <Form.Item
                      label={t("user.phone")}
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: `${t("notification.login.phone")}`,
                        },
                        {
                          pattern:
                            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                          message: `${t("notification.login.validPhone")}`,
                        },
                      ]}
                    >
                      <Input
                        type="tel"
                        placeholder={t("placeholder.inputPhone")}
                        disabled={this.phone?.length > 0}
                      />
                    </Form.Item>
                    <Form.Item
                      name="doB"
                      label={t("user.dateOfBirth")}
                      rules={[
                        {
                          required: true,
                          message: `${t("notification.userProfile.inputDob")}`,
                        },
                      ]}
                    >
                      <DatePicker locale={languageService.getLocale()} />
                    </Form.Item>

                    {this.email ? (
                      <Form.Item
                        name="email"
                        label={t("user.userCreate.email")}
                        rules={[
                          {
                            type: "email",
                            message: `${t(
                              "notification.userProfile.emailValidate"
                            )}`,
                          },
                          {
                            required: true,
                            message: `${t(
                              "notification.userProfile.inputEmail"
                            )}`,
                          },
                        ]}
                      >
                        <Input disabled={this.email?.length > 0} />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name="email"
                        label={t("user.userCreate.email")}
                        rules={[
                          {
                            type: "email",
                            message: `${t(
                              "notification.userProfile.emailValidate"
                            )}`,
                          },
                        ]}
                      >
                        <Input disabled={this.email?.length > 0} />
                      </Form.Item>
                    )}

                    <div className="select-address">
                      <SelectAdress
                        setFieldToEmpty={this.setFieldToEmpty.bind(this)}
                      />
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <div className="generator generator-edit">
                      <label for="html">{t("label.password")}</label>
                      <h4 className="generator-pass">
                        <Input
                          value={this.passwordHash?.length > 0 ? "******" : ""}
                          disabled
                        />
                      </h4>
                    </div>
                    <Form.Item
                      name="userTypeId"
                      label={t("user.type")}
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            "notification.userProfile.selectUserType"
                          )}`,
                        },
                      ]}
                    >
                      <Select
                        disabled={!this.state.ruleChangeTypeUser}
                        placeholder={t("placeholder.selectUserType")}
                        onChange={this.changeUserType}
                      >
                        {roles?.map(({ id, name }) => (
                          <Option value={id} key={id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {showStation && (
                      <Form.Item
                        label={t("label.station")}
                        name="stationIds"
                        rules={[
                          {
                            required: showStation,
                            message: `${t(
                              "notification.userProfile.selectStation"
                            )}`,
                          },
                        ]}
                      >
                        <Select
                          mode={showMultiStation ? "multiple" : ""}
                          placeholder={t("placeholder.selectStation")}
                        >
                          {stations?.map(({ id, code, name }) => (
                            <Option
                              value={id}
                              key={id}
                            >{`${code} ${name}`}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}

                    <Form.Item
                      name="statusId"
                      label={t("status.status")}
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            "notification.userProfile.selectStatus"
                          )}`,
                        },
                      ]}
                    >
                      <Select placeholder={t("placeholder.selectStatus")}>
                        {status?.map(({ id, name }) => (
                          <Option value={id} key={id}>
                            {t(name)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item className="actions">
                  <Button
                    className="btn-action btn-back"
                    onClick={this.backPage}
                  >
                    {t("button.back")}
                  </Button>
                  <Button
                    className="btn-action btn-reset"
                    onClick={this.showModal}
                  >
                    {t("button.resetPassword")}
                  </Button>
                  <Button className="btn-action btn-insert" htmlType="submit">
                    {t("button.update")}
                  </Button>
                </Form.Item>
              </Form>
              <Modal
                visible={this.state.ModalConfirmChangePassword.visible}
                onOk={this.resetPassword}
                confirmLoading={
                  this.state.ModalConfirmChangePassword.confirmLoading
                }
                onCancel={this.handleCancel}
              >
                <p>{this.state.ModalConfirmChangePassword.modalText}</p>
              </Modal>
            </Content>
          </div>
        </div>
      </Layout>
    );
  }
}

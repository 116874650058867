import actionTypes from "./stations.types";
import Moment from "moment";

const stationsInitialState = {
    items: [],
    lastUpdated: 1439478405547,
    listStations: [],
};

const stationsReducer = (state = stationsInitialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_STATIONS:
            return { ...state, items: action.stations, lastUpdated: Moment.utc().format('x') };
        case actionTypes.LIST_STATIONS:
            return { ...state, listStations: action.payload };
        default: 
            return state;
    }
};

export default stationsReducer;
import { connect } from "react-redux";
import { deleteRoles } from "../../modules/roles/roles.actions";
import { RoleManagementScreen } from "./role-management.screen";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
  return {
    selectedRoleIds: state.roles?.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRoles: (roleIds) => dispatch(deleteRoles(roleIds)),
  };
};
export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps)
)(RoleManagementScreen);

import { Table } from "antd";
import React, { Component } from "react";
import { Content } from "antd/lib/layout/layout";
import { formatMoney } from "../../../utils/common";
import AddAreaSpaceComponent from "./add-area.component";
import { showErrorMessage } from "../../../services/notification/notification.services";
import DeletePopConfirmComponent from "../../../components/delete-popconfirm/delete-popconfirm.component";

export default class DefineAreaSpaceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: props.initialData ?? [],
      editArea: null,
      categories: props?.categories,
    };
    this.addAreaSpaceRef = React.createRef();
  }

  render() {
    const { t } = this.props;
    const { initialData, editArea, categories } = this.state;
    const dataSource = initialData?.map((i) => this.mappingToDataTable(i));
    return (
      <Content>
        <AddAreaSpaceComponent
          t={t}
          ref={this.addAreaSpaceRef}
          initialData={editArea}
          categories={categories}
          onChange={this.addNewArea}
        />
        <Table
          className="mt-5"
          dataSource={dataSource}
          columns={this.columns()}
        />
      </Content>
    );
  }

  columns = () => {
    const { t } = this.props;
    return [
      {
        title: t("warehouseRegistration.column.areaName"),
        dataIndex: "areaName",
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.category"),
        dataIndex: "category",
        render: (category, data) => {
          return <>{t(category)}</>;
        },
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.areaSize"),
        dataIndex: "areaSize",
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.numberOfSpace"),
        dataIndex: "numberOfSpace",
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.spaceSize"),
        dataIndex: "spaceSize",
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.price"),
        dataIndex: "priceDetail",
        align: "center",
      },
      {
        title: t("warehouseRegistration.column.action"),
        align: "center",
        render: (_, record) => {
          return (
            <>
              <div className="button-action">
                <a
                  className="mr-3 edit"
                  type="primary"
                  onClick={() => this.editArea(record)}
                >
                  {" "}
                  <i
                    className="fa fa-pencil-square-o mt-1"
                    aria-hidden="true"
                  ></i>
                </a>
                <DeletePopConfirmComponent
                  t={t}
                  okText={t("button.ok")}
                  cancelText={t("button.cancel")}
                  message={t("message.deleteRecordConfirm")}
                  callBack={() => this.removeArea(record)}
                />
              </div>
            </>
          );
        },
      },
    ];
  };

  mappingToDataTable = (data) => {
    const { categories } = this.state;
    let category = categories?.find((x) => x.id === data?.category.id);
    let mappingValue = {
      id: data?.id,
      areaName: data?.name,
      category: category?.languageKey,
      areaSize: `${data?.length} x ${data?.width} x ${data?.height}`,
      numberOfSpace: "",
      spaceSize: `${data?.space?.length} x ${data?.space?.width} x ${data?.space?.height}`,
      priceDetail: formatMoney(data?.price),
      numberOfSpace: data?.totalSpace,
    };
    return mappingValue;
  };

  addNewArea = (isUpdate) => {
    this.addAreaSpaceRef.current.validateFields().then(() => {
      if (isUpdate) {
        let record = this.addAreaSpaceRef.current.getFieldsValue();
        let { initialData } = this.state;
        if (record && initialData) {
          let index = initialData?.findIndex((item) => item.id === record?.id);
          if (index !== -1) {
            initialData.splice(index, 1, record);
            this.setState({ initialData: initialData });
          }
        }
      } else {
        let { initialData } = this.state;
        let areaFormData = this.addAreaSpaceRef.current.getFieldsValue();
        this.setState({ initialData: [...initialData, areaFormData] });
      }
    });
  };

  editArea = (record) => {
    const { initialData } = this.state;
    let item = initialData?.find((item) => item.id === record?.id);
    if (
      this.addAreaSpaceRef &&
      this.addAreaSpaceRef.current &&
      this.addAreaSpaceRef.current.setFieldsValue
    ) {
      this.addAreaSpaceRef.current.setFieldsValue(item);
    }
  };

  removeArea = (record) => {
    let { initialData } = this.state;
    if (record && initialData) {
      let index = initialData?.findIndex((item) => item.id === record?.id);
      if (index !== -1) {
        initialData.splice(index, 1);
        this.setState({ initialData: initialData });
      }
    }
  };

  getFieldsValue = () => {
    const { initialData } = this.state;
    return initialData;
  };

  validateFields = async () => {
    const { initialData } = this.state;
    if (!initialData || initialData.length <= 0) {
      showErrorMessage("Please define at least one area");
      throw "";
    }
  };
}

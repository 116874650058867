import { combineReducers } from "redux";
import sessionReducer from "./session/session.reducers";
import stationsReducer from "./stations/stations.reducers";
import vehiclesReducer from "./vehicles/vehicles.reducers";
import shippingFeesReducer from "./shipping-fee/shipping-fee.reducers";
import warehousesReducer from "./warehouses/warehouses.reducers";
import usersReducer from "./users/users.reducers";
import rolesReducer from "./roles/roles.reducers";
import addressReducer from "./address/address.reducers";
import companiesReducer from "./companies/companies.reducers";
import appReducer from "./app/app.reducers";
import productsReducer from "./products/products.reducers"

const rootReducer = combineReducers({
    app: appReducer,
    session: sessionReducer,
    companies: companiesReducer,
    stations: stationsReducer,
    vehicle: vehiclesReducer,
    shippingFee: shippingFeesReducer,
    warehouse: warehousesReducer,
    users: usersReducer,
    roles: rolesReducer,
    address: addressReducer,
    product: productsReducer
});

export default rootReducer;
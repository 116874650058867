import { Component } from "react";
import { Button, Card, Modal } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import TopBar from "../../components/topbar";
import TableUsers from "./table-users";
import "./user-management.scss";

const { confirm } = Modal;

export class UserManagementScreen extends Component {
  constructor(props) {
    super(props);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.handleAddUser = this.handleAddUser.bind(this);
  }

  handleAddUser() {
    this.props.history.push("/add-user");
  }

  render() {
    const { t } = this.props;
    return (
      <Layout className="bg-content beecow-user-management">
        <div className="bg-body">
          <TopBar title={t("user.title")} />
          <Content className="content">
            <Card>
              <div className="beecow-center">
                {this.props.selectedUserIds?.length > 0 && (
                  <Button
                    size="medium"
                    type="primary"
                    className="beecow-btn"
                    onClick={() =>
                      this.confirmDelete(
                        `${t("notification.confirm.confirmDeleteTheseItems")}`
                      )
                    }
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;{" "}
                    {t("button.remove")}
                  </Button>
                )}
                &nbsp;&nbsp;
                <Button
                  size="medium"
                  type="primary"
                  className="beecow-btn"
                  onClick={this.handleAddUser}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;{" "}
                  {t("button.add")}
                </Button>
                &nbsp;&nbsp;
                <Button size="medium" type="primary" className="beecow-btn">
                  <i className="fa fa-download"></i>&nbsp; {t("button.import")}
                </Button>
                &nbsp;&nbsp;
                <Button size="medium" type="primary" className="beecow-btn">
                  <i className="fa fa-upload"></i>&nbsp; {t("button.export")}
                </Button>
                &nbsp;&nbsp;
                <Link to="roles" className="btn beecow-btn">
                  <i className="fa fa-users"></i>&nbsp; {t("button.role")}
                </Link>
              </div>
              <br />
              <TableUsers />
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }

  confirmDelete = (title) => {
    const self = this;
    const { t } = this.props;
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      okText: `${t("button.ok")}`,
      cancelText: `${t("button.cancel")}`,
      onOk() {
        return new Promise((resolve, reject) => {
          self.props.deleteUsers(self.props.selectedUserIds);
          setTimeout(resolve, 1000);
        }).catch((errors) => {
          console.log(errors);
        });
      },
      onCancel() { },
    });
  };
}

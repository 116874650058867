import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { setJustLoggedIn } from "../../../modules/app/app.actions";
import { initWorkspace, resetSession, setAuthentication } from "../../../modules/session/session.actions";
import { admin, superAdmin, warehouseAdmin } from "./../../../constants/user-constants";
import { LoginScreen } from "./login.screen";

const mapStateToProps = (state) => {
  return {
    isAuthenticated:
      state.session?.auth?.token &&
      (state.session?.auth?.user?.role === superAdmin ||
        state.session?.auth?.user?.role === admin ||
        state.session?.auth?.user?.role === warehouseAdmin)
        ? true
        : false,
    isSuperAdmin: state.session?.auth?.token && state.session?.auth?.user?.role === superAdmin ? true : false,
    userId: state?.session?.auth?.user?.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setJustLoggedIn: (value) => dispatch(setJustLoggedIn(value)),
    setAuthentication: (data, callback) => dispatch(setAuthentication(data, callback)),
    initWorkspace: (data) => dispatch(initWorkspace(data)),
    signOut: (userId) => dispatch(resetSession(userId)),
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(LoginScreen);

import actionTypes from "./companies.types";
import Moment from "moment";

const companiesInitialState = {
    current: 0,
    pageSize: 0,
    total: 0,
    list: [],
    edit: {
        selectedCompany: {}
    },
    lastUpdated: 1439478405547,
};

const companiesReducer = (state = companiesInitialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LIST_COMPANY:
            return { ...state, total: action.total, pageSize: action.pageSize, current: action.current, list: action.companies, lastUpdated: Moment.utc().format('x') };
        case actionTypes.SET_EDIT_COMAPNY:
            return { ...state, edit: { ...state.edit, selectedCompany: action.company}};
        default:
            return state;
    }
}

export default companiesReducer;
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { EditImportListScreen } from "./edit-import-list.screen";

const mapStateToProps = (state) => {
  return {
    currentStationId: state.session?.auth?.user?.currentStationId,
  };
};

export default connect(mapStateToProps, null)(withRouter(EditImportListScreen));

export const USER_ID = "5DE4C30E-D277-4989-859A-368245647EDC";
export const STATION_ID = "dff7e52c-3a7f-4ce5-87a9-37cd415a5bc2";

export const pageSize = 10

export const initalContract = "Inital Contract"
export const orderToSell = "Order to sell"
export const addMore = "Add more"

export const FORMAT_DATETIME = "DD-MM-YYYY HH:mm";

export const thumbColors = {
    rentedOut: "thumb-rented-out",
    available: "thumb-normal",
    warning: "thumb-available",
    full: "thumb-full",
}
import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
  message,
} from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import SelectAdress from "../../../screens/shared/components/select-address/select-address-container";
import { Option } from "antd/lib/mentions";
import userDataService from "../../../data-services/user/user-data.service";
import {
  station,
  driverInnerCity,
  driverInterProvince,
  superAdmin,
  warehouseAdmin,
} from "../../../constants/user-constants";
import TopBar from "../../../components/topbar";
import languageService from "../../../services/language.service";

export class AddUserScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      showStation: false,
      showMultiStation: false,
    };
    this.formRef = React.createRef();
    this.changeUserType = this.changeUserType.bind(this);
    this.backPage = this.backPage.bind(this);
  }

  componentDidMount() {
    this.props.getCities();
    this.props.getInitUser();
  }

  setFieldToEmpty(data) {
    this.formRef.current.setFieldsValue(data);
  }

  getCity(city) {
    return this.props.cities?.cities.find((x) => x.id === city)?.name || "";
  }

  createUser(values) {
    const { t } = this.props;
    if (typeof values.stationIds === "string")
      values.stationIds = [values.stationIds];
    userDataService
      .insert(values)
      .then((res) => {
        if (!res.success) {
          message.error(t(res.message));
        } else {
          this.backPage();
        }
      })
      .catch(({ data }) => {
        message.error(data.message);
      });
  }

  changeUserType(value) {
    const { roles } = this.props.initUser;
    const showStation = roles.some(
      (x) =>
        x.id === value &&
        (x.code === station ||
          x.code === driverInnerCity ||
          x.code === driverInterProvince ||
          x.code === warehouseAdmin)
    );
    this.setState({ showStation: showStation, selectedStationId: value });
    this.formRef?.current?.setFieldsValue({ stationIds: [] });
  }

  backPage() {
    this.props.history.push("/users");
  }

  render() {
    const { showStation, selectedStationId } = this.state;
    const { initUser } = this.props;
    let { roles, status, stations } = initUser;
    const showMultiStation =
      roles?.find(
        (x) => x.code.toLowerCase() === driverInterProvince.toLowerCase()
      )?.id === selectedStationId;

    roles = roles?.filter((x) => x.code !== superAdmin);
    var { t } = this.props;

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("user.userCreate.title")} />
          <div className="bg-white add-edit-user clearfix">
            <Content>
              <Form
                ref={this.formRef}
                layout="vertical"
                requiredMark={false}
                onFinish={this.createUser.bind(this)}
              >
                <Row>
                  <Col span={8} offset={2}>
                    <Form.Item
                      label={t("user.fullName")}
                      name="fullName"
                      rules={[
                        {
                          required: true,
                          message: `${t("notification.login.userName")}`,
                        },
                      ]}
                    >
                      <Input placeholder={t("placeholder.inputName")} />
                    </Form.Item>
                    <Form.Item
                      label={t("user.phone")}
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: `${t("notification.login.phone")}`,
                        },
                        {
                          pattern:
                            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                          message: `${t("notification.login.validPhone")}`,
                        },
                      ]}
                    >
                      <Input
                        type="tel"
                        placeholder={t("placeholder.inputPhone")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="doB"
                      label={t("user.dateOfBirth")}
                      rules={[
                        {
                          required: true,
                          message: `${t("notification.userProfile.inputDob")}`,
                        },
                      ]}
                    >
                      <DatePicker locale={languageService.getLocale()} />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label={t("user.userCreate.email")}
                      rules={[
                        {
                          type: "email",
                          message: `${t(
                            "notification.userProfile.emailValidate"
                          )}`,
                        },
                        {
                          required: true,
                          message: `${t(
                            "notification.userProfile.inputEmail"
                          )}`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <div className="select-address">
                      <SelectAdress
                        setFieldToEmpty={this.setFieldToEmpty.bind(this)}
                      />
                    </div>
                  </Col>
                  <Col span={8} offset={2}>
                    <Form.Item
                      name="userTypeId"
                      label={t("user.type")}
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            "notification.userProfile.selectUserType"
                          )}`,
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("placeholder.selectUserType")}
                        onChange={this.changeUserType}
                      >
                        {roles?.map(({ id, name }) => (
                          <Option value={id} key={id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {showStation && (
                      <Form.Item
                        label={t("label.station")}
                        name="stationIds"
                        rules={[
                          {
                            required: showStation,
                            message: `${t(
                              "notification.userProfile.selectStation"
                            )}`,
                          },
                        ]}
                      >
                        <Select
                          mode={showMultiStation ? "multiple" : ""}
                          placeholder={t("placeholder.selectStation")}
                        >
                          {stations?.map(({ id, code, name }) => (
                            <Option
                              value={id}
                              key={id}
                            >{`${code} ${name}`}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item
                      name="statusId"
                      label={t("status.status")}
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            "notification.userProfile.selectStatus"
                          )}`,
                        },
                      ]}
                    >
                      <Select placeholder={t("placeholder.selectStatus")}>
                        {status?.map(({ id, name }) => (
                          <Option value={id} key={id}>
                            {t(name)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item className="actions">
                  <Button
                    className="btn-action btn-back"
                    onClick={this.backPage}
                  >
                    {t("button.back")}
                  </Button>
                  <Button className="btn-action btn-insert" htmlType="submit">
                    {t("button.create")}
                  </Button>
                </Form.Item>
              </Form>
            </Content>
          </div>
        </div>
      </Layout>
    );
  }
}

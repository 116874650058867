import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import userDataService from "../../../data-services/user/user-data.service";
import jwt_decode from "jwt-decode";
import TopBar from "../../../components/topbar";
import Layout from "antd/lib/layout/layout";
import {
  admin,
  superAdmin,
  warehouseAdmin,
} from "../../../constants/user-constants";
import { claimTypes } from "../../../constants/claims";

export class ResetPassWordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props?.match?.params?.userId,
      userCurrent: {},
    };

    this.callBack = this.callBack.bind(this);
  }

  componentDidMount() {
    userDataService.getUserId(this.state.userId).then((data) => {
      this.setState({
        userCurrent: data,
      });
    });
  }

  render() {
    var { t } = this.props;

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          {this.props.isAuthenticated && <TopBar />}

          <Form
            name="normal_login"
            className="login-form col-2 m-auto centered"
            initialValues={{ rememberMe: true }}
            onFinish={this.onFinish}
          >
            <div className="mb-5 text-center">
              <h1>{t("button.resetPassword")}</h1>
            </div>

            {this.state.userCurrent && (
              <p>
                <b>Hi {this.state.userCurrent?.email}</b>
              </p>
            )}

            <Form.Item
              name="oldPassWord"
              rules={[
                {
                  required: true,
                  message: `${t("notification.changePassword.oldPassword")}`,
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t("placeholder.oldPassword")}
              />
            </Form.Item>
            <Form.Item
              name="newPassWord"
              rules={[
                {
                  required: true,
                  message: `${t("notification.changePassword.newPassword")}`,
                },
                {
                  min: 8,
                  message: `${t("notification.changePassword.passwordMini")}`,
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t("placeholder.newPassword")}
              />
            </Form.Item>
            <Form.Item
              name="confirmNewPassWord"
              rules={[
                {
                  required: true,
                  message: `${t(
                    "notification.changePassword.confirmPassword"
                  )}`,
                },
                {
                  min: 8,
                  message: `${t("notification.changePassword.passwordMini")}`,
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t("placeholder.confirmPassword")}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button col-12"
              >
                {t("button.reset")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    );
  }

  onFinish = (values) => {
    const { t } = this.props;

    if (values.newPassWord !== values.confirmNewPassWord) {
      message.error(`${t("notification.changePassword.alertNewPassword")}`);
      return;
    }
    let self = this;
    let request = {
      Id: self.state.userId,
      NewPassWord: values?.newPassWord,
      OldPassWord: values?.oldPassWord,
    };
    userDataService
      .resetPasswordByUser(request)
      .then((data) => {
        if (data.success) {
          let token = data.token || "";
          let refreshToken = data.refreshToken || "";
          let exprire = data.exprire;
          let userInfo = self.getUserInfo(token);
          let workspaceId = self.getWorkspaceId(token);
          self.setupWorkspace(
            token,
            refreshToken,
            exprire,
            userInfo,
            workspaceId
          );
          this.setState({
            ...self.state,
            userCurrent: {
              ...self.state.userCurrent,
              changedPassWord: true,
              userRole: userInfo?.role,
            },
          });
        } else {
          message.error(data.message);
        }
      })
      .catch((err) => {
        message.error(`${t("notification.changePassword.resetFail")}`);
      });
  };
  getUserInfo(token) {
    let claims = jwt_decode(token);
    let user = {
      userId: claims[claimTypes.CLAIMS_SID],
      username: claims[claimTypes.CLAIMS_NAME],
      emailaddress: claims[claimTypes.CLAIMS_EMAIL],
      role: claims[claimTypes.CLAIMS_ROLE],
      currentStationId: claims[claimTypes.CLAIMS_CURRENT_STATION_ID],
      changedPassWord: claims[claimTypes.CLAIMS_CHANGED_PASSWORD],
    };
    return user;
  }

  getWorkspaceId(token) {
    let claims = jwt_decode(token);
    return claims[claimTypes.CLAIMS_COMPANY_IDENTITY];
  }

  setupWorkspace(token, refreshToken, exprire, userInfo, workspaceId) {
    const { t, setAuthentication, initWorkspace } = this.props;

    if (
      userInfo.role === superAdmin ||
      userInfo.role === admin ||
      userInfo.role === warehouseAdmin
    ) {
      let auth = {
        token: token,
        refreshToken: refreshToken,
        exprire: exprire,
        user: userInfo,
      };
      message.success(`${t("notification.login.success")}`);
      this.setState({
        isLoggedIn: true,
        isSuperAdmin: userInfo.role === superAdmin ? true : false,
      });

      setAuthentication({ auth, workspaceId }, this.callBack);
      initWorkspace(workspaceId);
    } else {
      message.error(`${t("notification.login.failAccept")}`);
    }
  }

  callBack() {
    const { history } = this.props;
    if (this.state.isSuperAdmin) {
      history.push("/companies");
    } else {
      if (this.state?.userCurrent?.userRole === warehouseAdmin) {
        history.push("/admin/warehouse");
      } else {
        history.push("/station");
      }
    }
  }
}

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { EditCompanyScreen } from "./edit-company.screen";
import { fetchCompanyDetail, setSelectedCompany } from "../../../modules/companies/companies.actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
    return {
        selectedCompany: state.companies?.edit?.selectedCompany,
        auth: state.session?.auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCompanyDetail: (companyId) => dispatch(fetchCompanyDetail(companyId)),
        setSelectedCompany: (company) => dispatch(setSelectedCompany(company)),
    }
}
export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter)(EditCompanyScreen)
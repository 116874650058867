import { DataService } from "../base/base-data.service";

class UnitDataService extends DataService {
    apiUrl = '/Unit';

    async getListUnit() {
        return await this.get(`${this.apiUrl}/loadData`);
    }

    async addUnit() {
        const data = 
        [
            {
                name: "Size",
                descriptions : "0 - 0,5 m"
            },
            {
                name: "Size",
                descriptions : "0,5 - 1 m",
            },
            {
                name: "Size",
                descriptions : "Over 1 m"
            },
            {
                name: "Weight",
                descriptions : "0 - 0,5 kg"
            },
            {
                name: "Weight",
                descriptions : "0,5 - 1 kg"
            },
            {
                name: "Weight",
                descriptions : "Over 1 kg"
            }
        ];
        return await this.post(`${this.apiUrl}/addListUnit`, data);
    }
}

let unitDataService = new UnitDataService();

export default unitDataService;
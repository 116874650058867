import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ShippingFeeScreen } from "./shipping-fee.screen";

const mapStateToProps = (state) => {
  return {
    idShippingFee: state.shippingFee,
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, null)
)(ShippingFeeScreen);

import _ from "lodash";
import Button from "antd-button-color";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import {
  Input,
  Row,
  Checkbox,
  message,
  Form,
  Table,
  Radio,
  Col,
  Select,
} from "antd";
import {
  UnorderedListOutlined,
  AppstoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Route } from "react-router-dom";
import TopBar from "../../../components/topbar";
import { thumbColors } from "../../../common/common";
import Layout, { Content } from "antd/lib/layout/layout";
import WarehouseThumbnail from "../../../components/warehouse-thumbnail";
import stationTypeDataService from "../../../data-services/station-types/station-types.service";
import areaStationDataService from "../../../data-services/area-stations/area-stations-data.service";
import "./add-area.scss";
import { warehouseAdmin } from "../../../constants/user-constants";

const { Search } = Input;
const { Option } = Select;
const filterOptions = {
  all: 0,
  onlyRentedSpace: 1,
};

const filterOptionsForShipping = {
  all: 0,
  moreThan70: 1,
  lowerThan70: 2
};

const spaceFilterOption = [
  {
    id: filterOptions.all,
    title: "warehouse.selectOption.all",
  },
  {
    id: filterOptions.onlyRentedSpace,
    title: "warehouse.selectOption.onlyRentedSpace",
  },
];

const spaceFilterOptionForShipping = [
  {
    id: filterOptionsForShipping.all,
    title: "warehouse.selectOption.all",
  },
  {
    id: filterOptionsForShipping.moreThan70,
    title: "warehouse.selectOption.usedMoreThan70",
  },
  {
    id: filterOptionsForShipping.lowerThan70,
    title: "warehouse.selectOption.usedLowerThan70",
  }
];

const thumbDescriptions = [
  {
    id: 0,
    color: thumbColors.available,
    title: "warehouse.thumbDescription.onlyAvailable",
    titleForShipping: "warehouse.thumbDescription.onlyAvailable"
  },
  {
    id: 1,
    color: thumbColors.full,
    title: "warehouse.thumbDescription.rentedOut",
    titleForShipping: "warehouse.thumbDescription.inUse"
  },
];

export class AddAreaScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnailMode: false,
      areaID: "",
      stationId: props?.match?.params?.warehouseId,
      areaCode: "",
      areaName: "",
      areaLength: 1,
      areaWidth: 1,
      areaHeight: 1,
      spaceLength: 1,
      spaceWidth: 1,
      spaceHeight: 1,
      spaceMax: 0,
      spacePrice: 0,
      areaLease: true,
      statusForLease: false,
      spaceUsed: 0,
      tableSpace: true,
      dataShipping: [],
      isForLease: null,
      dataLease: [],
      current: 1,
      page: 1,
      pageSize: 20,
      total: 0,
      thumbDataWarehouse: [],
      backupThumbDataWarehouse: [],
      lazyLoad: {
        page: 1,
        pageSize: 50,
        canLoadMore: true,
        filter: "",
      },
      search: "",
      stationTypes: [],
      stationTypeId: null,
      sortField: "",
      sortOrder: "",
      listSpaceStatus: [],
      filterByStatusId: [],
    };
  }

  componentWillMount() {
    const { warehouseId, areaId } = this.props?.match?.params || {};
    const { page } = this.state;
    this.setState({
      stationId: warehouseId,
      areaID: areaId,
    });

    if (areaId) {
      areaStationDataService
        .getAreaDetailSpace(areaId)
        .then((data) => {
          this.setState({
            areaCode: data.code,
            areaName: data.name,
            areaLength: data.length,
            areaWidth: data.width,
            areaHeight: data.height,
            areaLease: data.isForLease,
            statusForLease: data.disableEditImportantInfo,
            spacePrice: data.price,
            isForLease: data.isForLease,
            stationTypes: data.stationTypeList,
            stationTypeId: data.stationTypeId,
          });
        })
        .then(() => {
          this.fetchSpaces(
            page,
            this.state.pageSize,
            this.state.sortField,
            this.state.sortOrder,
            this.state.filterByStatusId
          );
        });
    } else {
      stationTypeDataService.getAllStationType().then((data) => {
        this.setState({ stationTypes: data.rows });
      });
    }
  }

  columnsShipping = () => {
    const { t } = this.props;
    let filterStatus = this.state.listSpaceStatus.map((spaceStatus) => {
      return {
        text: spaceStatus.name,
        value: spaceStatus.id,
      };
    });

    return [
      {
        title: `${t("warehouse.space.id")}`,
        align: "center",
        dataIndex: "spaceId",
        width: "20%",
        sorter: (a, b) => a.spaceId.localeCompare(b.spaceId),
        ellipsis: true,
      },
      {
        title: `${t("warehouse.space.currentSpace")}`,
        align: "center",
        dataIndex: "spacePackage",
        width: "50%",
        sorter: (a, b) => a.spacePackage.localeCompare(b.spacePackage),
      },
      {
        title: `${t("status.status")}`,
        align: "center",
        dataIndex: "spaceStatus",
        width: "30%",
        sorter: (a, b) => a.spaceStatus.localeCompare(b.spaceStatus),
        filters: filterStatus,
        render: (text, record) => {
          if (record.used > 0) {
            return (
              <Route
                render={({ history }) => (
                  <span
                    className="lbl-use border-bottom border-dark"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.spaceDetail(history, record)}
                  >
                    {record.spaceStatus}
                  </span>
                )}
              />
            );
          } else {
            return <span className="lbl-available">{record.spaceStatus}</span>;
          }
        },
      },
    ];
  };

  columnsLease = () => {
    const { t } = this.props;

    let filterStatus = this.state.listSpaceStatus.map((spaceStatus) => {
      return {
        text: spaceStatus.name,
        value: spaceStatus.id,
      };
    });
    return [
      {
        title: `${t("warehouse.space.id")}`,
        align: "center",
        dataIndex: "spaceId",
        width: "8%",
        editable: true,
        sorter: (a, b) => a.spaceId - b.spaceId,
      },
      {
        title: `${t("warehouse.contract.id")}`,
        align: "center",
        dataIndex: "contractId",
        width: "10%",
        editable: true,
        sorter: (a, b) => a.contractId - b.contractId,
        render: (code, contract) => {
          let href = `/admin/contract/${contract.contractIdDB}`;
          return <Link to={href}>{contract.contractId}</Link>;
        },
      },
      {
        title: `${t("warehouse.contract.ownerInformation")}`,
        align: "center",
        dataIndex: "spaceOwner",
        width: "41%",
        editable: true,
        render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
      },
      {
        title: `${t("createdDate")}`,
        align: "center",
        dataIndex: "SpaceCreatedDate",
        width: "10%",
        editable: true,
        sorter: (a, b) => a.SpaceCreatedDate - b.SpaceCreatedDate,
      },
      {
        title: `${t("warehouse.contract.expirationDate")}`,
        align: "center",
        dataIndex: "spaceExpiration",
        width: "10%",
        editable: true,
        sorter: (a, b) => a.spaceExpiration - b.spaceExpiration,
      },
      {
        title: `${t("status.status")}`,
        align: "center",
        dataIndex: "spaceStatus",
        width: "10%",
        sorter: (a, b) => a.spaceStatus - b.spaceStatus,
        filters: filterStatus,
        render: (_, record) => {
          if (record.spaceStatus === "In use") {
            return <span className="lbl-use">{record.spaceStatus}</span>;
          } else {
            return <span className="lbl-available">{record.spaceStatus}</span>;
          }
        },
      },
    ];
  };

  fetchSpaces = (page, pageSize, sortField, sortOrder, filterByStatusId) => {
    const { areaID, isForLease } = this.state;
    if (isForLease == null) {
      return;
    }
    var request = {
      areaId: areaID,
      page: page,
      pageSize: pageSize,
      search: this.state.search,
      sortField: sortField,
      sortOrder: sortOrder,
      isDropdownList: this.state.thumbnailMode ? false : true,
      filterByStatusId: filterByStatusId,
    };

    areaStationDataService
      .getSpacesByAreaIdWithSearch(request)
      .then((response) => {
        if (isForLease) {
          const dataLease = this.getDataLease(response.rows);
          this.setState({
            dataLease: dataLease,
            tableSpace: true,
          });
        } else {
          const dataShipping = this.getDataShipping(response.rows);
          this.setState({
            dataShipping: dataShipping,
            tableSpace: false,
          });
        }

        this.setState({
          page: response.page,
          total: response.records,
          current: response.page,
          spaceMax: response.rows[0]?.max,
          spaceLength: response.rows[0]?.length,
          spaceWidth: response.rows[0]?.width,
          spaceHeight: response.rows[0]?.height,
          listSpaceStatus: response.spaceStatuses,
        });
      });
  };

  lazyLoading = (page, size) => {
    const { areaID, isForLease, lazyLoad } = this.state;
    if (isForLease == null) {
      return;
    }
    if (lazyLoad.canLoadMore === false) {
      return;
    }
    areaStationDataService
      .getSpacesByAreaIdWithFilter(
        areaID,
        page,
        size,
        this.state.lazyLoad.filter,
        this.state.thumbnailMode ? false : true
      )
      .then((response) => {
        if (isForLease) {
          const dataLease = this.getDataLease(response.rows);
          const arrayMerged = [
            ...this.state.backupThumbDataWarehouse,
            ...dataLease,
          ];
          this.setState({
            lazyLoad: {
              ...this.state.lazyLoad,
              page: page,
              pageSize: size,
              canLoadMore: dataLease.length === 0 ? false : true,
            },
            thumbDataWarehouse: arrayMerged,
            backupThumbDataWarehouse: arrayMerged,
            tableSpace: true,
          });
        } else {
          const dataShipping = this.getDataShipping(response.rows);
          const arrayMerged = [
            ...this.state.thumbDataWarehouse,
            ...dataShipping,
          ];
          this.setState({
            lazyLoad: {
              ...this.state.lazyLoad,
              page: page,
              pageSize: size,
              canLoadMore: dataShipping.length === 0 ? false : true,
            },
            backupThumbDataWarehouse: arrayMerged,
            thumbDataWarehouse: arrayMerged,
            tableSpace: false,
          });
        }
      });
  };

  spaceDetail = (history, record) => {
    let stationId = this.props?.match?.params?.warehouseId;
    this.props.dispatch({ type: "SET_SPACE_ID", payload: record.key });
    history.push({
      pathname: `/admin/space-detail/${record.key}`,
      state: {
        stationId: stationId
      }
    });
  };

  getDataLease = (data) => {
    return data?.map((x, i) => ({
      key: x.id,
      spaceId: x.code,
      code: x.code,
      contractId: x.codeContract,
      contractCode: x.codeContract,
      spaceOwner: x.infoOwner,
      SpaceCreatedDate: x.createdDate,
      spaceExpiration: x.expiryDate,
      spacePrice: x.price,
      spaceStatus: x.spaceStatus,
      contractIdDB: x.contractId,
    }));
  };

  getDataShipping = (data) => {
    return data.map((x, i) => ({
      key: x.id,
      spaceId: x.code,
      code: x.code,
      used: x.used,
      max: x.max,
      spacePackage: x.used.toString() + "/" + x.max.toString(),
      spaceStatus: x.spaceStatus,
    }));
  };

  handleChange = (e) => {
    const isCheckbox = e.target.name === "radioGroup";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
    });
  };

  onChangeCheckLease = (e) => {
    this.setState({
      areaLease: e.target.checked,
    });
  };

  addAreaStation = (history) => {
    const { t } = this.props;
    const {
      stationId,
      areaName,
      areaLength,
      areaWidth,
      areaHeight,
      spaceLength,
      spaceWidth,
      spaceHeight,
      spaceMax,
      areaLease,
      spacePrice,
      stationTypeId,
    } = this.state;
    const sizeArea = areaLength * areaWidth * areaHeight;
    const sizeSpace = spaceLength * spaceWidth * spaceHeight;
    const countSpace = Math.floor(sizeArea / sizeSpace);
    let countMax = 0;
    let countPrices = 0;
    var dataArea = {
      stationId: stationId,
      stationTypeId,
      name: areaName,
      length: parseInt(areaLength),
      width: parseInt(areaWidth),
      height: parseInt(areaHeight),
      isForLease: areaLease,
    };

    if (areaLease) {
      countPrices = spacePrice;
    } else {
      countMax = spaceMax;
    }

    const dataSpace = {
      length: parseInt(spaceLength),
      width: parseInt(spaceWidth),
      height: parseInt(spaceHeight),
      max: parseInt(countMax),
      price: parseInt(countPrices),
    };

    const data = {
      areaStations: dataArea,
      spaceAreas: dataSpace,
      countSpace: countSpace,
    };

    if (
      areaName.length === 0 ||
      areaLength === 0 ||
      areaWidth === 0 ||
      areaHeight === 0 ||
      spaceLength === 0 ||
      spaceWidth === 0 ||
      spaceHeight === 0 ||
      stationTypeId === null
    ) {
      message.warn(t("message.invalidData"));
      return;
    }

    areaStationDataService.addAreaStation(data).then((data) => {
      if (data.success) {
        message.success(t("message.dataHasBeenSaved"));
        if (this.props.role === warehouseAdmin)
          history.push(`/admin/warehouse`);
        else history.push(`/admin/warehouse/${stationId}`);
      }
    });
  };

  updateAreaStation = (history) => {
    const {
      areaID,
      areaName,
      spacePrice,
      statusForLease,
      areaLength,
      areaWidth,
      areaHeight,
      spaceLength,
      spaceWidth,
      spaceHeight,
      spaceMax,
      areaLease,
      stationId,
      stationTypeId,
    } = this.state;
    const { t } = this.props;

    let countMax = 0;
    let countPrices = 0;
    if (areaLease) {
      countPrices = spacePrice;
    } else {
      countMax = spaceMax;
    }
    const sizeArea = areaLength * areaWidth * areaHeight;
    const sizeSpace = spaceLength * spaceWidth * spaceHeight;
    const countSpace = Math.floor(sizeArea / sizeSpace);
    const dataArea = {
      id: areaID,
      stationTypeId,
      name: areaName,
      length: parseInt(areaLength),
      width: parseInt(areaWidth),
      height: parseInt(areaHeight),
      isForLease: areaLease,
    };
    const dataSpace = {
      length: parseInt(spaceLength),
      width: parseInt(spaceWidth),
      height: parseInt(spaceHeight),
      max: parseInt(countMax),
      price: parseInt(countPrices),
    };
    const data = {
      countSpace: countSpace,
      status: true,
      areaStation: dataArea,
      spaceArea: dataSpace,
      skipUpdateSpaces: statusForLease,
    };

    if (
      areaName.length === 0 ||
      areaLength === 0 ||
      areaWidth === 0 ||
      areaHeight === 0 ||
      spaceLength === 0 ||
      spaceWidth === 0 ||
      spaceHeight === 0 ||
      stationTypeId === null
    ) {
      message.warn(t("message.invalidData"));
      return;
    }

    areaStationDataService.updateAreaStation(data).then((res) => {
      if (res.success) {
        if (this.props.role === warehouseAdmin)
          history.push(`/admin/warehouse`);
        else history.push(`/admin/warehouse/${stationId}`);
      }
    });
  };

  onChangeViewMode = (e) => {
    const { isForLease, dataLease, dataShipping } = this.state;
    this.setState(
      {
        thumbnailMode: e.target.value,
        thumbDataWarehouse: isForLease === true ? dataLease : dataShipping,
      },
      () => {
        let filter = "rented";
        if (!isForLease) {
          filter = "";
        }
        this.setState(
          {
            lazyLoad: {
              ...this.state.lazyLoad,
              page: 1,
              canLoadMore: true,
              filter: this.state.thumbnailMode === true ? filter : "",
            },
            backupThumbDataWarehouse: [],
            thumbDataWarehouse: [],
          },
          () => {
            if (this.state.thumbnailMode) {
              this.lazyLoading(1, this.state.lazyLoad.pageSize);
            }
          }
        );
      }
    );
  };

  renderModeButtons = () => {
    const { t } = this.props;
    return (
      <div div className="mt-3">
        <Radio.Group
          value={this.state.thumbnailMode}
          onChange={this.onChangeViewMode}
        >
          <Radio.Button className="btn-icon" value={false}>
            <UnorderedListOutlined />
            <span className="ml-1">{t("button.listView")}</span>
          </Radio.Button>
          <Radio.Button className="btn-icon m-3" value={true}>
            <AppstoreOutlined />
            <span className="ml-1">{t("button.thumbnail")}</span>
          </Radio.Button>
        </Radio.Group>
      </div>
    );
  };

  renderThumbs = () => {
    const { thumbDataWarehouse } = this.state;
    return thumbDataWarehouse?.map((data) => {
      let contractDetailHref = `/admin/contract/${data.contractIdDB}`;
      let spaceDetailHref = `/admin/space-detail/${data.key}`;
      let actions = <></>;
      if (data.contractCode) {
        actions = (
          <span className="action thumb-action">
            <p className="m-0 fs-14">
              <b>{data.contractCode}</b>
            </p>
            <Link to={contractDetailHref} target="_blank">
              <SearchOutlined />
            </Link>
          </span>
        );
      } else {
        actions = (
          <span className="action thumb-action">
            <p className="m-0 fs-10">
              <b>{data.code}</b>
            </p>
            <p className="m-0 fs-9">
              {data.used > 0 ? data.spacePackage : null}
            </p>
            <Link to={spaceDetailHref} target="_blank">
              <SearchOutlined />
            </Link>
          </span>
        );
      }
      const content = (
        <>
          <div className="content-center">
            <p className="m-0">
              <b>{data.code}</b>
            </p>
            <p className="m-0">
              {data.used > 0 ? data.spacePackage : null}
            </p>
            <p className="m-0 fs-12">{data.contractCode}</p>
          </div>
        </>
      );

      const color = this.getColorSpace(data);
      return (
        <WarehouseThumbnail
          width="90px"
          height="90px"
          key={data.id}
          color={color}
          className="ml-2 pointer text-center border fs-13 mt-2"
          content={content}
          renderActionButton={actions}
        />
      );
    });
  };

  getColorSpace = (space) => {
    if (space?.contractCode) return thumbColors.full;
    if (space?.used > 0) return thumbColors.full;
    return thumbColors.available;
  };

  onChangeFilterSpace = (value) => {
    const { isForLease } = this.state;
    let filter = "";
    if (value === filterOptionsForShipping.moreThan70 && !isForLease)
      filter = "morethan70";
    if (value === filterOptionsForShipping.lowerThan70 && !isForLease)
      filter = "lowerthan70";
    if (value === filterOptions.onlyRentedSpace && isForLease)
      filter = "rented";
    this.setState(
      {
        backupThumbDataWarehouse: [],
        thumbDataWarehouse: [],
        lazyLoad: {
          ...this.state.lazyLoad,
          filter: filter,
          canLoadMore: true,
        },
      },
      () => {
        this.lazyLoading(1, this.state.lazyLoad.pageSize);
      }
    );
  };

  onSearch = (e) => {
    this.setState({ search: e }, () => {
      this.fetchSpaces(
        1,
        this.state.pageSize,
        this.state.sortField,
        this.state.sortOrder,
        this.state.filterByStatusId
      );
    });
  };

  onScrollSpace = (event) => {
    let target = event.target;
    let top = target.scrollTop;
    let offsetHeight = target.offsetHeight;
    let max = target.scrollHeight;
    let current = top + offsetHeight;
    if (current + 20 >= max) {
      this.lazyLoading(
        this.state.lazyLoad.page + 1,
        this.state.lazyLoad.pageSize
      );
    }
  };

  onChange(pagination, filter, sorter, extra) {
    let sortOrder = "";
    let sortField = "";
    let filterByStatusId =
      filter?.spaceStatus !== "undefined" && filter?.spaceStatus != null
        ? filter?.spaceStatus
        : [];

    if (sorter) {
      sortOrder = sorter.order ? sorter.order : "";
      sortField = sorter.field;
    }
    this.setState(
      {
        sortField: sortField,
        sortOrder: sortOrder,
        filterByStatusId: filterByStatusId,
      },
      () => {
        this.fetchSpaces(
          pagination.current,
          this.state.pageSize,
          sortField,
          sortOrder,
          filterByStatusId
        );
      }
    );
  }

  render() {
    var { t } = this.props;
    const {
      areaCode,
      areaLease,
      areaName,
      areaLength,
      areaWidth,
      areaHeight,
      spaceLength,
      spaceWidth,
      spaceHeight,
      spaceMax,
      spacePrice,
      areaID,
      dataShipping,
      dataLease,
      statusForLease,
      tableSpace,
      stationTypes,
      stationTypeId,
      isForLease
    } = this.state;
    const sizeArea = areaLength * areaWidth * areaHeight;
    const sizeSpace = spaceLength * spaceWidth * spaceHeight;
    const countSpace = Math.floor(sizeArea / sizeSpace);
    const paging = {
      current: this.state.current,
      pageSize: this.state.pageSize,
      total: this.state.total,
    };
    var title = areaID
      ? `${t("warehouse.addNewArea.edit")}`
      : `${t("warehouse.addNewArea.title")}`;
    let colums = this.columnsShipping();
    let dataSource = dataShipping;
    if (tableSpace) {
      colums = this.columnsLease();
      dataSource = dataLease;
    }
    return (
      <Layout className="bg-content mb-5">
        <div className="bg-body">
          <TopBar title={title} />
          <Content>
            <Layout className="content-add-area">
              <div className="form-info col-12">
                {areaID && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: 30,
                    }}
                  >
                    <h1>{areaCode}</h1>
                  </div>
                )}
                <div className="row m-0 mb-3">
                  <div className="col-5 pl-0">
                    <div className="row">
                      <div className="col-md-12">
                        <label>{t("warehouse.area.name")}</label>
                        <Input
                          value={areaName}
                          onChange={this.handleChange}
                          type="text"
                          id="areaName"
                          name="areaName"
                          className="area-name"
                        />
                        <p>
                          {t("warehouse.addNewArea.labelNumberSpaceOfArea")}:{" "}
                          {countSpace}
                        </p>
                      </div>

                      <div className="col-md-12">
                        <div>
                          <label>{t("warehouse.area.category")}</label>
                        </div>
                        <Select
                          onChange={(value) =>
                            this.setState({ stationTypeId: value })
                          }
                          className="area-category"
                          value={stationTypeId}
                        >
                          {stationTypes?.map((data, index) => (
                            <Option value={data.id}>
                              {t(data.languageKey)}
                            </Option>
                          ))}
                        </Select>
                      </div>

                      <div className="col-md-12">
                        <br />
                        <Checkbox
                          label={"For lease"}
                          checked={areaLease}
                          disabled={statusForLease}
                          onChange={this.onChangeCheckLease}
                          className="checkbox"
                        >
                          {t("warehouse.addNewArea.labelForLease")}
                        </Checkbox>
                      </div>
                    </div>
                    {areaLease ?
                      (
                        <div className="mt-3">
                          <label>{t("warehouse.addNewArea.labelPrice")}</label>
                          <Input
                            disabled={statusForLease}
                            value={spacePrice}
                            onChange={this.handleChange}
                            type="number"
                            id="spacePrice"
                            name="spacePrice"
                            className="area-for-lease area-name"
                            thousandSeparator={true}
                          />
                        </div>
                      ) :
                      (
                        <div className="mt-3">
                          <label>{t("warehouse.addNewArea.maximum")}</label>
                          <Input
                            disabled={statusForLease}
                            value={spaceMax}
                            onChange={this.handleChange}
                            type="number"
                            id="spaceMax"
                            name="spaceMax"
                            className="area-for-lease area-name"
                          />
                        </div>
                      )}
                    <div>
                      {!areaID && (
                        <div className="btn-handle mt-3">
                          <Route
                            render={({ history }) => (
                              <Button
                                type="warning"
                                onClick={() => this.addAreaStation(history)}
                              >
                                {t("button.submit")}
                              </Button>
                            )}
                          />
                          <Route
                            render={({ history }) => (
                              <Button
                                type="link"
                                className="m-auto pr-5"
                                onClick={() => this.callBack(history)}
                              >
                                {t("button.cancel")}
                              </Button>
                            )}
                          />
                        </div>
                      )}
                      {areaID && (
                        <div className="mt-3">
                          <Route
                            render={({ history }) => (
                              <Button
                                type="warning"
                                onClick={() => this.updateAreaStation(history)}
                              >
                                {t("button.update")}
                              </Button>
                            )}
                          />
                          <Route
                            render={({ history }) => (
                              <Button
                                type="link"
                                className="m-auto pr-5"
                                onClick={() => this.callBack(history)}
                              >
                                {t("button.cancel")}
                              </Button>
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-6">
                    <div>
                      <label>{t("warehouse.addNewArea.sizeArea")}</label>
                      <div className="d-flex mb-3">
                        <Input
                          disabled={statusForLease}
                          value={areaLength}
                          onChange={this.handleChange}
                          type="number"
                          id="areaLength"
                          name="areaLength"
                          placeholder="Length"
                          className="mr-3"
                        />
                        <Input
                          disabled={statusForLease}
                          value={areaWidth}
                          onChange={this.handleChange}
                          type="number"
                          id="areaWidth"
                          name="areaWidth"
                          placeholder="Width"
                          className="mr-3"
                        />
                        <Input
                          disabled={statusForLease}
                          value={areaHeight}
                          onChange={this.handleChange}
                          type="number"
                          id="areaHeight"
                          name="areaHeight"
                          placeholder="Height"
                          className="mr-3"
                        />
                      </div>
                    </div>
                    <div>
                      <label>{t("warehouse.addNewArea.sizeSpace")}</label>
                      <div className="d-flex">
                        <Input
                          disabled={statusForLease}
                          value={spaceLength}
                          onChange={this.handleChange}
                          type="number"
                          id="spaceLength"
                          name="spaceLength"
                          placeholder="Length"
                          className="mr-3"
                        />
                        <Input
                          disabled={statusForLease}
                          value={spaceWidth}
                          onChange={this.handleChange}
                          type="number"
                          id="spaceWidth"
                          name="spaceWidth"
                          placeholder="Width"
                          className="mr-3"
                        />
                        <Input
                          disabled={statusForLease}
                          value={spaceHeight}
                          onChange={this.handleChange}
                          type="number"
                          id="spaceHeight"
                          name="spaceHeight"
                          placeholder="Height"
                          className="mr-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {areaID ? this.renderModeButtons() : ""}

                <div className="mt-3 mb-5 mr-4">
                  {this.state.thumbnailMode ? (
                    <div
                      className="p-2 pb-3"
                      style={{ backgroundColor: "#f0f2f5" }}
                    >
                      <Row>
                        <Select
                          className="ml-2 mt-3"
                          defaultValue={isForLease === true ? spaceFilterOption[1].id : spaceFilterOptionForShipping[0].id}
                          style={{ width: 200 }}
                          onChange={this.onChangeFilterSpace}
                        >
                          {
                            isForLease === true ?
                              (
                                spaceFilterOption?.map((i) => (
                                  <Option key={i.id} value={i.id}>
                                    {t(i.title)}
                                  </Option>
                                ))
                              ) : (
                                spaceFilterOptionForShipping?.map((i) => (
                                  <Option key={i.id} value={i.id}>
                                    {t(i.title)}
                                  </Option>
                                ))
                              )
                          }
                        </Select>
                      </Row>
                      <Row className="mt-4 d-flex justify-content-center">
                        {thumbDescriptions?.map((data) => {
                          return (
                            <>
                              <Col key={`${data.id}-1`}>
                                <WarehouseThumbnail
                                  color={data.color}
                                  className="ml-2 pointer text-center border fs-11"
                                  width="50px"
                                  height="50px"
                                />
                              </Col>
                              <Col
                                key={`${data.id}-2`}
                                style={{ width: "100px" }}
                                className="mt-4 ml-2 mr-5"
                              >
                                <span className="center">{isForLease === true ? t(data.title) : t(data.titleForShipping)}</span>
                              </Col>
                            </>
                          );
                        })}
                      </Row>

                      <hr />
                      <Row
                        style={{ maxHeight: "350px", overflowY: "auto" }}
                        onScroll={this.onScrollSpace}
                      >
                        {this.renderThumbs()}
                      </Row>
                    </div>
                  ) : (
                    <>
                      {areaID && (
                        <>
                          <Search
                            className="mb-3"
                            placeholder={t("placeholder.searchSpace")}
                            allowClear
                            onSearch={this.onSearch}
                            style={{ width: 400 }}
                          />
                          <Form>
                            <Table
                              columns={colums}
                              dataSource={dataSource}
                              pagination={paging}
                              onChange={this.onChange.bind(this)}
                            />
                          </Form>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Layout>
          </Content>
        </div>
      </Layout>
    );
  }

  callBack = (history) => {
    if (this.props.role === warehouseAdmin) history.push(`/admin/warehouse`);
    else history.push(`/admin/warehouse/${this.state.stationId}`);
  };
}

import { Component } from "react";
import { Card, Modal } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import TableRoles from "./table-roles";
import TopBar from "../../components/topbar";
import "./role-management.scss";

const { confirm } = Modal;

export class RoleManagementScreen extends Component {
  constructor(props) {
    super(props);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  render() {
    return (
      <Layout className="bg-content beecow-role-management">
        <div className="bg-body">
          <TopBar title={this.props.t("role.title")} />
          <Content className="content">
            <Card>
              <TableRoles />
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }

  confirmDelete = (title) => {
    const self = this;
    const { t } = this.props;
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      okText: `${t("button.ok")}`,
      cancelText: `${t("button.cancel")}`,
      onOk() {
        return new Promise((resolve, reject) => {
          self.props.deleteRoles(self.props.selectedRoleIds);
          setTimeout(resolve, 1000);
        }).catch((errors) => {
          console.log(errors);
        });
      },
      onCancel() {},
    });
  };
}

import Button from "antd-button-color";
import React, { Component } from "react";
import { Utilities } from "../../../common/utilities";
import { Input, Form, Select, InputNumber } from "antd";
import "./add-area.component.scss";

const { Option } = Select;

export default class AddAreaSpaceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: props.initialData ?? null,
      isEdit: false,
      totalSpace: 0,
      categories: props.categories ?? null,
    };
    this.formRef = React.createRef();
  }

  render() {
    const { t } = this.props;
    let { isEdit, totalSpace, categories } = this.state;
    let buttonName = isEdit === true ? t("button.update") : t("button.addArea");
    return (
      <>
        <Form ref={this.formRef} layout="vertical">
          <div
            className="col-lg-5 col-sm-6 col-12 mt-5"
            style={{ display: "inline-block" }}
          >
            <Form.Item hidden name={["id"]}>
              <Input className="rounded" />
            </Form.Item>
            <Form.Item
              label={t("warehouseRegistration.column.areaName")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("warehouseRegistration.inputAreaName"),
                },
              ]}
            >
              <Input
                className="rounded"
                placeholder={t("warehouseRegistration.inputAreaName")}
              />
            </Form.Item>
            <Form.Item
              label={t("warehouseRegistration.priceLabel")}
              name={["price"]}
              rules={[
                {
                  required: true,
                  message: t("warehouseRegistration.inputPriceLabel"),
                },
              ]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                placeholder={t("warehouseRegistration.inputPriceLabel")}
              />
            </Form.Item>
            <Form.Item
              label={t("warehouseRegistration.category")}
              name={["category", "id"]}
              rules={[
                {
                  required: true,
                  message: t("warehouseRegistration.inputCategory"),
                },
              ]}
            >
              <Select
                className="rounded"
                placeholder={t(
                  "createOrEditOrderPage.productForm.selectCategory"
                )}
              >
                {categories?.map((typeItem, index) => (
                  <Option key={`${typeItem.id}`} value={typeItem.id}>
                    {t(typeItem.languageKey)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-lg-2" style={{ display: "inline-block" }}>
            {" "}
          </div>
          <div
            className="col-lg-5 col-sm-12"
            style={{ display: "inline-block" }}
          >
            <Form.Item
              label={t("warehouseRegistration.sizeOfAreaLabel")}
              rules={[{ required: true }]}
            >
              <Form.Item
                label={t("warehouseRegistration.length")}
                className="col-4 pr-3 mb-0"
                name={["length"]}
                rules={[
                  {
                    required: true,
                    message: t("warehouseRegistration.inputLength"),
                  },
                ]}
                style={{ display: "inline-block" }}
              >
                <Input
                  placeholder={t("warehouseRegistration.inputLength")}
                  onChange={() => this.calcTotalSpace()}
                />
              </Form.Item>
              <Form.Item
                label={t("warehouseRegistration.width")}
                className="col-4 pr-3 mb-0"
                name={["width"]}
                rules={[
                  {
                    required: true,
                    message: t("warehouseRegistration.inputWidth"),
                  },
                ]}
                style={{ display: "inline-block" }}
              >
                <Input
                  placeholder={t("warehouseRegistration.inputWidth")}
                  onChange={() => this.calcTotalSpace()}
                />
              </Form.Item>
              <Form.Item
                label={t("warehouseRegistration.height")}
                className="col-4 mb-0"
                name={["height"]}
                rules={[
                  {
                    required: true,
                    message: t("warehouseRegistration.inputHeight"),
                  },
                ]}
                style={{ display: "inline-block" }}
              >
                <Input
                  placeholder={t("warehouseRegistration.inputHeight")}
                  onChange={() => this.calcTotalSpace()}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              className="mt-5"
              label={t("warehouseRegistration.sizeOfSpaceLabel")}
              rules={[{ required: true }]}
            >
              <Form.Item
                label={t("warehouseRegistration.length")}
                className="col-4 pr-3 mb-0"
                name={["space", "length"]}
                rules={[
                  {
                    required: true,
                    message: t("warehouseRegistration.inputLength"),
                  },
                ]}
                style={{ display: "inline-block" }}
              >
                <Input
                  placeholder={t("warehouseRegistration.inputLength")}
                  onChange={() => this.calcTotalSpace()}
                />
              </Form.Item>
              <Form.Item
                label={t("warehouseRegistration.width")}
                className="col-4 pr-3 mb-0"
                name={["space", "width"]}
                rules={[
                  {
                    required: true,
                    message: t("warehouseRegistration.inputWidth"),
                  },
                ]}
                style={{ display: "inline-block" }}
              >
                <Input
                  placeholder={t("warehouseRegistration.inputWidth")}
                  onChange={() => this.calcTotalSpace()}
                />
              </Form.Item>
              <Form.Item
                label={t("warehouseRegistration.height")}
                className="col-4 mb-0"
                name={["space", "height"]}
                rules={[
                  {
                    required: true,
                    message: t("warehouseRegistration.inputHeight"),
                  },
                ]}
                style={{ display: "inline-block" }}
              >
                <Input
                  placeholder={t("warehouseRegistration.inputHeight")}
                  onChange={() => this.calcTotalSpace()}
                />
              </Form.Item>
              <p>
                {t("warehouseRegistration.totalSpaces")} {totalSpace}
              </p>
            </Form.Item>
          </div>
        </Form>
        <Button
          className="float-right mb-5"
          type="primary"
          onClick={() => this.props.onChange(isEdit)}
        >
          {buttonName}
        </Button>
      </>
    );
  }

  calcTotalSpace = (values) => {
    if (!values) {
      values = this.formRef.current.getFieldsValue();
    }
    const sizeSpace =
      values?.space?.length * values?.space?.width * values?.space?.height;
    const sizeArea = values?.length * values?.width * values?.height;
    const totalSpace = Math.floor(sizeArea / sizeSpace);
    this.setState({ totalSpace: totalSpace });
  };

  getFieldsValue = () => {
    const { isEdit, totalSpace } = this.state;
    let values = this.formRef.current.getFieldsValue();
    this.formRef.current.resetFields();
    if (isEdit === false) {
      values.id = Utilities.randomUuid();
    }
    this.setState({ isEdit: false });
    values.totalSpace = totalSpace;
    return values;
  };

  setFieldsValue = (values) => {
    this.formRef.current.setFieldsValue(values);
    this.setState({ isEdit: true });
    this.calcTotalSpace(values);
  };

  validateFields = () => {
    return this.formRef.current.validateFields();
  };
}

import actionTypes from "./warehouses.types";
import Moment from "moment";

const warehousesInitialState = {
    id: '',
    idArea: '',
    idSpace: '',
};

const warehousesReducer = (state = warehousesInitialState, action) => {
    switch(action.type){
        case actionTypes.SET_WAREHOUSE_ID:
            return {...state, id: action.payload};
        case actionTypes.SET_AREA_ID:
            return {...state, idArea: action.payload};
        case actionTypes.SET_SPACE_ID:
            return { ...state, idSpace: action.payload };
        default:
            return state;
    }
};

export default warehousesReducer;
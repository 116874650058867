import React from "react";
import LoginScreen from "../screens/shared/login";
import UserManagementScreen from "../screens/user";
import RoleManagementScreen from "../screens/role";
import AddUserScreen from "../screens/user/add-user";
import EditUserScreen from "../screens/user/edit-user";
import ShippingFeeScreen from "../screens/shipping-fee";
import NotFoundScreen from "../screens/shared/not-found";
import StationManagementScreen from "../screens/station";
import VehicleManagementScreen from "../screens/vehicle";
import ViewImportListScreen from "../screens/view-import-list";
import RouteManagementScreen from "../screens/route-management";
import ImportStationScreen from "../screens/station/import-station";
import ImportVehicleScreen from "../screens/vehicle/import-vehicle";
import ProductDetailContainer from "../screens/view-product-detail";
import AddAreaScreen from "../screens/warehouse-management/add-area";
import WarehouseManagementScreen from "../screens/warehouse-management";
import AddShippingFeeScreen from "../screens/shipping-fee/add-shipping-fee";
import EditImportListScreen from "../screens/view-import-list/edit-import-list";
import ResetPassWord from "../screens/user/reset-password/reset-password.container";
import SpaceDetailScreen from "../screens/warehouse-management/area-detail/space-detail";
import StationRegistrationContainer from "../screens/station-registration/station-registration.container";
import ContractDetailPageAdminScreen from "../screens/warehouse-management/contract/contract-detail.screen";
import WarehouseRegistrationScreen from "../screens/warehouse-registration/warehouse-registration.container";
import StationModificationScreen from "../screens/station/station-modification/station-modification.container";

const routes = [
  {
    key: "app.login",
    path: "/login",
    isMenu: false,
    auth: false,
    exact: true,
    main: () => <LoginScreen />,
  },
  {
    key: "app.login",
    path: "/login/:userId",
    isMenu: false,
    auth: false,
    exact: true,
    main: () => <LoginScreen />,
  },
  {
    key: "app.stations.list",
    path: "/",
    icon: "fa fa-dribbble",
    name: "Station Management",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <StationManagementScreen />,
  },
  {
    key: "app.stations.list",
    path: "/station",
    icon: "fa fa-home",
    name: "station.title",
    isMenu: true,
    auth: true,
    exact: true,
    main: () => <StationManagementScreen />,
  },
  {
    key: "app.stations.import",
    path: "/import-station",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <ImportStationScreen />,
  },
  {
    key: "app.stations.edit",
    path: "/admin/warehouse/:id",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <WarehouseManagementScreen />,
  },
  {
    key: "app.stations.areas.new",
    path: "/warehouse/:warehouseId/add-area",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <AddAreaScreen />,
  },
  {
    key: "app.stations.areas.edit",
    path: "/admin/warehouse/:warehouseId/edit-area/:areaId",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <AddAreaScreen />,
  },
  {
    key: "app.stations.areas.spaces.edit",
    path: "/admin/space-detail/:id",
    isMenu: false,
    auth: true,
    exact: false,
    main: () => <SpaceDetailScreen />,
  },
  {
    key: "app.stations.registration",
    path: "/station-registration",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <StationRegistrationContainer />,
  },
  {
    key: "app.vehicles.list",
    path: "/vehicles",
    icon: "fa fa-car",
    name: "vehicle.title",
    isMenu: true,
    auth: true,
    exact: false,
    main: () => <VehicleManagementScreen />,
  },
  {
    key: "app.vehicles.import",
    path: "/import-vehicle",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <ImportVehicleScreen />,
  },
  {
    key: "app.shippingfees.list",
    path: "/shippingfee",
    icon: "fa fa-usd",
    name: "shippingFee.title",
    isMenu: true,
    auth: true,
    exact: false,
    main: () => <ShippingFeeScreen />,
  },
  {
    key: "app.shippingfees.new",
    path: "/add-shippingfee",
    isMenu: false,
    auth: true,
    exact: false,
    main: () => <AddShippingFeeScreen />,
  },
  {
    key: "app.shippingfees.edit",
    path: "/admin/edit-shippingfee/:id",
    isMenu: false,
    auth: true,
    exact: false,
    main: () => <AddShippingFeeScreen />,
  },
  {
    key: "app.routes.list",
    path: "/routes",
    icon: "fa fa-road",
    name: "route.title",
    isMenu: true,
    auth: true,
    exact: false,
    main: () => <RouteManagementScreen />,
  },
  {
    key: "app.users.list",
    path: "/users",
    icon: "fa fa-users",
    name: "user.title",
    isMenu: true,
    auth: true,
    exact: true,
    main: () => <UserManagementScreen />,
  },
  {
    key: "app.users.new",
    path: "/add-user",
    isMenu: false,
    auth: true,
    exact: false,
    main: () => <AddUserScreen />,
  },
  {
    key: "app.users.edit",
    path: "/edit-user/:userId",
    isMenu: false,
    auth: true,
    exact: false,
    main: () => <EditUserScreen />,
  },
  {
    key: "app.roles.list",
    path: "/roles",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <RoleManagementScreen />,
  },
  {
    key: "app.contracts.edit",
    path: "/admin/contract/:id",
    isMenu: false,
    auth: true,
    exact: false,
    main: () => <ContractDetailPageAdminScreen />,
  },
  {
    path: "/reset-password/:userId",
    icon: "",
    name: "Reset PassWord",
    isMenu: false,
    auth: true,
    exact: false,
    main: () => <ResetPassWord />,
  },
  {
    key: "app.products.detail",
    path: "/products-detail/:productId",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <ProductDetailContainer />,
  },
  {
    key: "app.packages.view",
    path: "/packages/view/:id",
    name: "View Import List",
    auth: true,
    exact: true,
    main: () => <ViewImportListScreen />,
  },
  {
    key: "app.packages.export.new",
    path: "/packages/edit/:id",
    name: "Modify Import List",
    auth: true,
    exact: true,
    main: () => <EditImportListScreen />,
  },
  {
    key: "app.page-not-found",
    path: "/page-not-found",
    exact: false,
    auth: false,
    main: () => <NotFoundScreen />,
  },
  {
    key: "app.stations.add.warehouse",
    path: "/admin/station/:id/add-warehouse",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <WarehouseRegistrationScreen />,
  },
  {
    key: "app.stations.modification.warehouse",
    path: "/admin/station-modification/:stationId",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <StationModificationScreen />,
  },
  {
    key: "app.notfound",
    path: "",
    exact: false,
    auth: false,
    main: () => <NotFoundScreen />,
  },
];

export default routes;

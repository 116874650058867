import { Col, Input, Row, Select } from "antd";
import { Form } from "antd";
import { Option } from "antd/lib/mentions";
import React, { Component } from "react";

export class SelectAdress extends Component {
  constructor(props) {
    super(props);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeDistrict = this.onChangeDistrict.bind(this);
  }

  onChangeCity(id) {
    this.props.getDistrictByCityId(id);
    this.props.setFieldToEmpty({
      address: {
        districtId: null,
        wardId: null,
      },
    });
  }

  onChangeDistrict(id) {
    this.props.getWardsByDistrictId(id);
    this.props.setFieldToEmpty({
      address: {
        wardId: null,
      },
    });
  }

  render() {
    const { districts, wards, cities } = this.props;
    const { t } = this.props;

    return (
      <div className="select">
        <label for="html">{t("address.address")}</label>
        <Form.Item name={["address", "id"]} hidden />
        <div className="input-icon">
          <Form.Item
            name={["address", "street"]}
            rules={[
              {
                required: true,
                message: `${t("notification.address.inputAddress")}`,
              },
            ]}
          >
            <Input placeholder={t("placeholder.inputStreet")} />
          </Form.Item>
        </div>
        <Row>
          <Col span={11}>
            <Form.Item
              name={["address", "cityId"]}
              rules={[
                {
                  required: true,
                  message: `${t("notification.address.selectCity")}`,
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t("placeholder.selectCity")}
                optionFilterProp="children"
                onChange={this.onChangeCity}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cities?.cities?.map(({ id, name }) => (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              name={["address", "districtId"]}
              rules={[
                {
                  required: true,
                  message: `${t("notification.address.selectDistrict")}`,
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t("placeholder.selectDistrict")}
                optionFilterProp="children"
                onChange={this.onChangeDistrict}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {districts?.map(({ id, name, prefix }) => (
                  <Option value={id} key={id}>{`${prefix} ${name}`}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item
              name={["address", "wardId"]}
              rules={[
                {
                  required: true,
                  message: `${t("notification.address.selectWard")}`,
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t("placeholder.selectWard")}
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {wards?.map(({ id, name, prefix }) => (
                  <Option value={id} key={id}>{`${prefix} ${name}`}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
}

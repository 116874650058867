import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { superAdmin, admin, warehouseAdmin } from "../constants/user-constants";
const PrivateRoute = (props) => {
  const { isAuthenticated, changedPassWord, userId } = props;
  
  let routeRender;
  if (isAuthenticated) {
    if (
      changedPassWord === false &&
      props.location !== "/reset-password/:userId"
    ) {
      routeRender = (
        <Redirect
          to={{
            pathname: `/reset-password/${userId}`,
            state: { from: props.location },
          }}
        />
      );
    } else {
      routeRender = React.createElement(props.component, props);
    }
  } else {
    if (props.location === "/reset-password/:userId") {
      routeRender = React.createElement(props.component, props);
    } 
    else if (props.location === `/login/:userId` ||
        props.location === `/login`) {
        routeRender = (React.createElement(props.component, props))
    }
    else {
      routeRender = (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }
  }

  return <Route {...props.routeProps} render={() => routeRender} />;
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    isAuthenticated:
      state.session?.auth?.token &&
      (state.session?.auth?.user?.role === superAdmin ||
        state.session?.auth?.user?.role === admin ||
        state.session?.auth?.user?.role === warehouseAdmin)
        ? true
        : false,
    changedPassWord:
      state.session?.auth?.user?.changedPassWord === "True" ||
      state.session?.auth?.user?.changedPassWord === true
        ? true
        : false,
    userId: state.session?.auth?.user?.userId,
    location: ownProps.path,
    routeProps: {
      exact: ownProps.exact,
      path: ownProps.path,
    },
  };
}

export default withRouter(
  connect(mapStateToProps, null, null, { pure: false })(PrivateRoute)
);

import React from "react";
import _ from "lodash";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Modal,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import shippingFeeDataService from "../../../data-services/shipping-fees/shipping-fees-data.service";

const listStatus = [
  { id: 35, statusName: "Inactive" },
  { id: 28, statusName: "Available" },
];
const { confirm } = Modal;
class TableInnerCityFees extends React.Component {
  constructor(props) {
    super(props);
    let ref = this;
    const keySearch =
      props.location?.search?.split("?")?.length > 1 &&
      props.location.search.split("?")[1];
    this.state = {
      selectedRowKeys: [],
      itemDelete: [],
      nameItem: "",
      editingKey: "",
      city: "",
      id: "",
      listData: [],
      cities: [],
      totalFees: 0,
      dataSource: [],
      count: 0,
      vehicleTypes: [],
      current: 1,
      keySearch: keySearch || "",
    };
    this.edit = this.edit.bind(this);
    this.isEditing = this.isEditing.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.formRef = React.createRef();
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSelectedCity = this.onSelectedCity.bind(this);

    this.editData = {};
    this.isCheckAll = this.isCheckAll.bind(this);
  }

  getTableColumns() {
    const { t } = this.props;

    let columns = [
      {
        title: t("shippingFee.innerCity.city"),
        dataIndex: "city",
        width: "20%",
        editable: true,
        sorter: (a, b) => a?.city?.name?.localeCompare(b?.city?.name),
        render: (city, { isNew }) => {
          const { cities } = this.state;
          if (isNew) {
            return (
              <>
                <Form.Item
                  name="city-new"
                  rules={[
                    {
                      required: true,
                      message: `${t("notification.address.selectCity")}`,
                    },
                  ]}
                >
                  <Select
                    onChange={this.onSelectedCity}
                    style={{ width: "100%" }}
                    showSearch
                    placeholder={t("placeholder.selectCity")}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    optionFilterProp="children"
                  >
                    {cities.map(({ id, name }) => (
                      <Option value={id}>{name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            );
          }

          return <span>{city?.name}</span>;
        },
      },
      {
        title: t("shippingFee.innerCity.CODFee"),
        dataIndex: "codFee",
        width: "20%",
        editable: true,
        sorter: (a, b) => a.codFee - b.codFee,
        render: (text, { isNew }) => {
          if (isNew)
            return (
              <Form.Item
                name="codFee-new"
                rules={[
                  {
                    required: true,
                    message: `${t("notification.innerCityFee.codFee")}`,
                  },
                ]}
              >
                <Input
                  min={0}
                  onChange={this.handleChange}
                  type="number"
                  name="codFee"
                  pattern="^\d+\.\d{0,2}$"
                  onKeyDown={(e) =>
                    e.keyCode !== 190 &&
                    e.keyCode != 9 &&
                    e.keyCode !== 8 &&
                    (e.keyCode < 48 || e.keyCode > 57) &&
                    e.preventDefault()
                  }
                />
              </Form.Item>
            );
          else return <span>{text.toString() + " VND "}</span>;
        },
      },
      {
        title: t("shippingFee.innerCity.pickupFee"),
        dataIndex: "pickupFee",
        editable: true,
        width: "20%",
        ellipsis: true,
        sorter: (a, b) => a.pickupFee - b.pickupFee,
        render: (text, { isNew }) => {
          if (isNew)
            return (
              <Form.Item
                name="pickupFee-new"
                rules={[
                  {
                    required: true,
                    message: `${t("notification.innerCityFee.pickupFee")}`,
                  },
                ]}
              >
                <Input
                  min={0}
                  onChange={this.handleChange}
                  type="number"
                  name="pickupFee"
                  pattern="^\d+\.\d{0,2}$"
                  onKeyDown={(e) =>
                    e.keyCode !== 190 &&
                    e.keyCode != 9 &&
                    e.keyCode !== 8 &&
                    (e.keyCode < 48 || e.keyCode > 57) &&
                    e.preventDefault()
                  }
                />
              </Form.Item>
            );
          else return <span>{text.toString() + " VND "}</span>;
        },
      },
      {
        title: t("shippingFee.innerCity.deliveryFee"),
        dataIndex: "deliveryFee",
        editable: true,
        width: "20%",
        sorter: (a, b) => a.deliveryFee - b.deliveryFee,
        render: (text, { isNew }) => {
          if (isNew)
            return (
              <Form.Item
                name="deliveryFee-new"
                rules={[
                  {
                    required: true,
                    message: `${t("notification.innerCityFee.deliveryFee")}`,
                  },
                ]}
              >
                <Input
                  min={0}
                  onChange={this.handleChange}
                  type="number"
                  name="deliveryFee"
                  pattern="^\d+\.\d{0,2}$"
                  onKeyDown={(e) =>
                    e.keyCode !== 190 &&
                    e.keyCode != 9 &&
                    e.keyCode !== 8 &&
                    (e.keyCode < 48 || e.keyCode > 57) &&
                    e.preventDefault()
                  }
                />
              </Form.Item>
            );
          else return <span>{text.toString() + " VND "}</span>;
        },
      },
      {
        title: t("status.action"),
        width: "10%",
        render: (_, record) => {
          const editable = this.isEditing(record);
          const renderNotEdit = () => {
            if (record.isNew) {
              return (
                <>
                  <a
                    onClick={() => this.handleAdd()}
                    style={{ marginRight: 8 }}
                  >
                    <i className="fa fa-floppy-o save" aria-hidden="true"></i>
                  </a>
                  <Popconfirm
                    title={t("notification.confirm.confirmCancel")}
                    onConfirm={() => this.buttonCancelAdd(record)}
                    okText={t("button.ok")}
                    cancelText={t("button.cancel")}
                  >
                    <a>
                      <i
                        className="fa fa-times-circle-o delete"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </Popconfirm>
                </>
              );
            }

            return (
              <>
                <a onClick={() => this.edit(record)}>
                  <i
                    className="fa fa-pencil-square-o edit"
                    aria-hidden="true"
                  />
                </a>
                <a
                  disabled={this.state.editingKey !== ""}
                  onClick={() =>
                    this.confirmDelete(
                      record,
                      true,
                      `${t("notification.confirm.confirmDeleteThisItem")}`
                    )
                  }
                >
                  <i className="fa fa-trash delete" aria-hidden="true"></i>
                </a>
              </>
            );
          };

          return editable == true ? (
            <span className="action">
              <a onClick={() => this.save(record)} style={{ marginRight: 8 }}>
                <i className="fa fa-floppy-o save" aria-hidden="true"></i>
              </a>
              <Popconfirm
                title={t("notification.confirm.confirmCancel")}
                onConfirm={this.cancel}
                okText={t("button.ok")}
                cancelText={t("button.cancel")}
              >
                <a>
                  <i
                    className="fa fa-times-circle-o delete"
                    aria-hidden="true"
                  ></i>
                </a>
              </Popconfirm>
            </span>
          ) : (
            <span className="action">{renderNotEdit()}</span>
          );
        },
      },
    ];

    let mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "city" ? "select" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return mergedColumns;
  }

  onPageChanged(page) {
    this.initData(page);
  }

  handleChange = (e) => {
    const isCheckbox = e.target.name === "radioGroup";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
    });
  };

  isEditing = (record) => record.key === this.state.editingKey;

  edit = (record) => {
    this.formRef?.current?.setFieldsValue({
      ...record,
      city: record?.city?.id || "",
    });

    this.setState({ editingKey: record.key });
  };

  save = async (record) => {
    const { dataSource } = this.state;
    const data = dataSource.find((x) => x.key === record.key);
    const editData = this.editData[record.key];

    if (editData && typeof editData === "object") {
      Object.keys(data).forEach((attribute) => {
        if (attribute == "city" && !editData[attribute])
          data[attribute] = data[attribute]?.id;
        else data[attribute] = editData[attribute] || data[attribute];
      });
    }
    shippingFeeDataService.updateInnerCityFee(data).then(() => this.initData());
    this.setState({ editingKey: "" });
  };

  confirmDelete = (record, isSingleDelete, title) => {
    const currentComponent = this;
    var ids = [];
    const { t } = this.props;

    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      okText: `${t("button.ok")}`,
      cancelText: `${t("button.cancel")}`,
      onOk() {
        return new Promise((resolve, reject) => {
          if (isSingleDelete) {
            ids = [record.id];
          } else {
            ids = currentComponent.state.selectedRowKeys;
            currentComponent.setState({ selectedRowKeys: [] });
          }
          shippingFeeDataService.deleteInnerCityFees(ids).then(
            (data) => {
              currentComponent.initData();
              setTimeout(resolve, 1000);
            },
            (error) => {
              setTimeout(reject(error), 500);
            }
          );
        }).catch((errors) => {
          console.log(errors);
        });
      },
      onCancel() {},
    });
  };

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  onSelectedCity = (value) => {
    this.setState({
      city: value,
    });
  };

  onChecked(key) {
    const { dataSource } = this.state;
    const updateProduct = dataSource.find((x) => x.key === key);

    if (updateProduct) {
      updateProduct.checked = !updateProduct.checked;
    }

    this.isCheckAll();
    this.setState({ dataSource });
  }

  isCheckAll() {
    const elm = document.getElementById("check-all");
    const { dataSource } = this.state || {};

    if (elm) {
      elm.checked = dataSource?.length && dataSource?.every((p) => p.checked);
    }
  }

  isShowDelete() {
    const { dataSource } = this.state;
    return dataSource?.some((p) => p.checked);
  }

  onSelectChange = (selectedRowKeys) => {
    const { dataSource } = this.state;
    dataSource?.forEach((p) => {
      p.checked = selectedRowKeys.includes(p.key);
    });
    this.setState({ dataSource, selectedRowKeys: selectedRowKeys });
  };

  handleAdd() {
    this.formRef.current.validateFields().then(() => {
      const model = this.state;
      var requestModel = {
        codFee: model.codFee,
        cityId: model.city,
        pickupFee: model.pickupFee,
        deliveryFee: model.deliveryFee,
      };

      shippingFeeDataService
        .addInnerCityFee(requestModel)
        .then(() => this.initData());
    });
  }

  buttonCancelAdd(data) {
    const { dataSource } = this.state;
    const newData = dataSource.filter((x) => x.key !== data.key);
    this.setState({ dataSource: newData });
  }

  buttonAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      isNew: true,
    };
    this.setState({
      dataSource: [newData, ...dataSource],
      count: count + 1,
      codFee: "",
      city: "",
      pickupFee: "",
      deliveryFee: "",
    });
    this.formRef.current.resetFields();
  };

  editInline = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const renderContent = () => {
      const onEditColumn = (value, text) => {
        const { key } = record;
        if (this.editData[key]) {
          this.editData[key][value] = text;
        } else {
          this.editData[key] = {};
          this.editData[key][value] = text;
        }
      };

      const { cities } = this.state;
      const { t } = this.props;

      if (dataIndex === "city") {
        return (
          <Select
            defaultValue={record[dataIndex].id}
            onChange={(e) => onEditColumn("city", e)}
            name="city"
            showSearch
            placeholder={t("placeholder.selectCity")}
          >
            {cities.map(({ id, name }) => (
              <Option value={id}>{name}</Option>
            ))}
          </Select>
        );
      }

      return (
        <Input
          defaultValue={record[dataIndex]}
          onChange={(e) => onEditColumn(dataIndex, e.currentTarget.value)}
        />
      );
    };

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `\`${this.props.t("notification.input")}\` ${title}!`,
              },
            ]}
          >
            {renderContent()}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  handleCheckAll(e, data) {
    const { dataSource } = this.state;
    dataSource.forEach((p) => {
      p.checked = data.checked;
    });

    this.setState({ dataSource });
  }

  initData() {
    shippingFeeDataService.getInnerCityFees().then((data) => {
      const dataSource = data.innerCityFees?.map((x) => ({ ...x, key: x.id }));
      this.currentData = dataSource;
      this.setState({
        dataSource: dataSource,
        totalFees: data.record,
        cities: data.cities || [],
      });
    });
  }

  componentDidMount() {
    this.initData();
  }

  handleSearch(e) {
    const keySearch = e.currentTarget?.value?.toLowerCase();
    let data = this.currentData;
    if (keySearch) {
      const filteredData = data?.filter(
        (x) =>
          x.city?.name?.toLowerCase().includes(keySearch) ||
          x.codFee?.toString()?.toLowerCase().includes(keySearch) ||
          x.pickupFee?.toString()?.toLowerCase().includes(keySearch) ||
          x.deliveryFee?.toString()?.toLowerCase().includes(keySearch)
      );
      this.setState({ dataSource: filteredData });
    } else {
      this.setState({ dataSource: data });
    }
  }

  render() {
    const { dataSource } = this.state;
    const selectedRowKeys = dataSource
      ?.filter((p) => p.checked)
      .map((x) => x.key);
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys?.length > 0;
    const { t } = this.props;
    let columns = this.getTableColumns();
    return (
      <div className="list-inner-city-fees">
        <h4>
          <b>{t("shippingFee.innerCity.title")}</b>
        </h4>
        <div className="select-items clearfix">
          <Row>
            <Col className="select-number gutter-row" span={4}>
              <div className="lbl-selected">
                {hasSelected ? `Selected ${selectedRowKeys.length} fees` : ""}{" "}
              </div>
            </Col>
            <Col className="action gutter-row" span={14}>
              <div className="group-btn">
                <Button
                  className="btn-labeled"
                  onClick={this.buttonAdd}
                  style={{
                    marginBottom: 16,
                  }}
                >
                  {" "}
                  {t("button.add")}{" "}
                  <span className="btn-label">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  </span>
                </Button>
                {this.isShowDelete() && (
                  <Button
                    onClick={() =>
                      this.confirmDelete(
                        null,
                        false,
                        `${this.props.t(
                          "notification.confirm.confirmDeleteTheseItems"
                        )}`
                      )
                    }
                    className="btn-labeled remove"
                  >
                    {" "}
                    {t("button.remove")}{" "}
                    <span className="btn-label">
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </span>
                  </Button>
                )}
              </div>
            </Col>
            <Col className="search-table gutter-row" span={5} offset={1}>
              <form>
                <input
                  defaultValue={this.state.keySearch}
                  className="form-control mr-sm-2"
                  onChange={(e) => this.handleSearch(e)}
                  type="search"
                  placeholder={t("placeholder.search")}
                  aria-label="Search"
                />
              </form>
            </Col>
          </Row>
        </div>
        <Form ref={this.formRef}>
          <Table
            rowSelection={rowSelection}
            components={{
              body: {
                cell: this.editInline,
              },
            }}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={dataSource}
            columns={columns}
          />
        </Form>
      </div>
    );
  }
}

export default TableInnerCityFees;

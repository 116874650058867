import React, { Component } from "react";
import Flags from "country-flag-icons/react/3x2";
import { Button, Modal, Radio } from "antd";
import languageService from "./../../services/language.service";
import "./change-language.scss";

export class ChangeLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible,
      langModalVisible: false,
      selectedLang: null,
    };
  }

  render() {
    var { t } = this.props;
    var langCode = languageService.getLang();
    var languages = this.getLanguages();
    var currentLanguage = languages.find((l) => l.code === langCode);
    var display = this.props.visible ? "d-block" : "d-none";
    return (
      <>
        <div className={`header float-right ${display}`}>
          <Button
            title={t("language.changeLanguage")}
            onClick={this.onOpenDialog}
            className="lang-button mt-3"
          >
            {currentLanguage?.flagIcon}
            <span className="ml-2">{currentLanguage?.title}</span>
          </Button>
        </div>
        <Modal
          title={t("language.chooseLanguage")}
          visible={this.state.langModalVisible}
          maskClosable={false}
          onOk={this.onOk}
          okText={t("button.ok")}
          onCancel={() => this.setState({ langModalVisible: false })}
          cancelText={t("button.cancel")}
          width={500}
        >
          {this.renderLanguages()}
        </Modal>
      </>
    );
  }

  renderLanguages = () => {
    var languages = this.getLanguages();
    var langCode = languageService.getLang();
    var currentLanguage = languages.find((l) => l.code === langCode);
    return (
      <Radio.Group
        onChange={this.onChangeLanguage}
        defaultValue={currentLanguage?.code}
      >
        {languages.map((lang, index) => {
          return (
            <div key={index}>
              <Radio value={lang.code} className="mb-4">
                {lang.flagIcon} {lang.title}
              </Radio>
            </div>
          );
        })}
      </Radio.Group>
    );
  };

  onChangeLanguage = (e) => {
    var value = e.target.value;
    this.setState({ selectedLang: value });
  };

  onOpenDialog = () => {
    this.setState({ langModalVisible: true });
  };

  onOk = () => {
    var { i18n } = this.props;
    var { selectedLang } = this.state;
    if (selectedLang) {
      languageService.setLang(selectedLang);
      i18n.changeLanguage(selectedLang);
    }
    this.setState({ langModalVisible: false });
  };

  getLanguages = () => {
    var { t } = this.props;
    return [
      {
        code: "vi",
        title: t("language.vietnamese"),
        flagCode: "VN",
        flagIcon: <Flags.VN title="Tiếng Việt" className="flag" />,
      },
      {
        code: "en",
        title: t("language.english"),
        flagCode: "US",
        flagIcon: <Flags.GB title="English" className="flag" />,
      },
    ];
  };
}

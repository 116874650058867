import React, { Component } from "react";
import {
  formatAddress,
  getLocationName,
  getStatusName,
} from "../../../utils/common";
import { Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";

export default class SummarizeComponentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, initialData } = this.props;
    const { stationInformation, warehouseAdminInformation } = initialData;
    return (
      <Content>
        <label className="mb-3">
          {t("stationRegistration.stationInformation")}
        </label>
        <Row className="ml-3">
          <Col className="mr-5">
            <p>{t("station.name")}</p>
            <p>{t("station.phone")}</p>
            <p>{t("station.address")}</p>
            <p>{t("station.location")}</p>
            <p>{t("status.status")}</p>
          </Col>
          <Col>
            <p>{stationInformation?.name}</p>
            <p>{stationInformation?.phoneNumber}</p>
            <p>{formatAddress(stationInformation?.address, t)}</p>
            <p>{t(getLocationName(stationInformation?.location?.id))}</p>
            <p>{t(getStatusName(stationInformation?.status))}</p>
          </Col>
        </Row>

        <label className="mb-3">
          {t("stationRegistration.warehouseAdminInformation")}
        </label>
        <Row className="ml-3">
          <Col className="mr-5">
            <p>{t("user.fullName")}</p>
            <p>{t("user.phone")}</p>
            <p>{t("user.dateOfBirth")}</p>
            <p>{t("user.userCreate.email")}</p>
            <p>{t("address.address")}</p>
          </Col>
          <Col>
            <p>{warehouseAdminInformation?.fullName}</p>
            <p>{warehouseAdminInformation?.phoneNumber}</p>
            <p>
              {warehouseAdminInformation?.dateOfBirth !== "" &&
              warehouseAdminInformation?.dateOfBirth
                ? warehouseAdminInformation?.dateOfBirth?.format("yyyy-MM-DD")
                : "_"}
            </p>
            <p>{warehouseAdminInformation?.warehouseAdminEmail}</p>
            <p>{formatAddress(warehouseAdminInformation?.address, t)}</p>
          </Col>
        </Row>
      </Content>
    );
  }
}

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { StationModificationScreen } from './station-modification.screen';


const mapStateToProps = (state) => {
    return {
        infoAddress: state.address.InfoAddress,
        stations: state.stations.listStations,
        role: state?.session?.auth?.user?.role
    };
};

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null),
    withRouter
)(StationModificationScreen);
import { connect } from 'react-redux';
import { deleteUsers, fetchListUsers, setSelectedUserIds } from '../../../modules/users/users.actions';
import { TableUsers } from './table-users.component';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        total: state.users?.total,
        users: state.users?.list,
        selectedUserIds: state.users?.selected,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        listUsers: () => dispatch(fetchListUsers()),
        setSelectedUserIds: (userIds) => dispatch(setSelectedUserIds(userIds)),
        deleteUsers: (userIds) => dispatch(deleteUsers(userIds)),
    }
}
export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps)
)(TableUsers)
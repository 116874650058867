import React from 'react';
import EditUserContainer from '../screens/user/edit-user';
import NotFoundContainer from '../screens/shared/not-found';
import AddOrEditAreaContainer from '../screens/warehouse-management/add-area';
import WarehouseManagementContainer from '../screens/warehouse-management';
import ResetPassWordContainer from '../screens/user/reset-password/reset-password.container';
import ContractDetailPageAdminContainer from '../screens/warehouse-management/contract/contract-detail.screen';
import LoginScreen from "../screens/shared/login";

const routes = [
    {
        key: "app.login",
        path: "/login/:userId",
        isMenu: false,
        auth: false,
        exact: true,
        main: () => <LoginScreen />,
    },
    {
        key: "app.login",
        path: "/login",
        isMenu: false,
        auth: false,
        exact: true,
        main: () => <LoginScreen />,
    },    
    {
        key: 'app.warehouse-management-for-warehouse-admin',
        path: '/admin/warehouse',
        icon: "fa fa-home",
        name: 'warehouse.title',
        isMenu: true,
        auth: true,
        exact: true,
        main: () => <WarehouseManagementContainer />
    },
    {
        key: 'app.warehouse-management-for-admin',
        path: '/admin/warehouse/:warehouseId',
        icon: "fa fa-home",
        name: 'warehouse.title',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <WarehouseManagementContainer />
    },
    {
        path: '/reset-password/:userId',
        icon: '',
        name: 'Reset PassWord',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ResetPassWordContainer />
    },
    {
        key: 'app.users.edit',
        path: '/edit-user/:userId',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <EditUserContainer />
    },
    {
        key: 'app.stations.areas.new',
        path: '/warehouse/:warehouseId/add-area',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <AddOrEditAreaContainer />
    },
    {
        key: 'app.stations.areas.edit',
        path: '/admin/warehouse/:warehouseId/edit-area/:areaId',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <AddOrEditAreaContainer />
    },
    {
        key: 'app.contracts.edit',
        path: '/admin/contract/:id',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ContractDetailPageAdminContainer />
    },
    {
        path: '',
        exact: false,
        auth: false,
        main: () => <NotFoundContainer />
    }
];

export default routes;
import React from "react";
import UserManagement from "../screens/user";
import LoginScreen from "../screens/shared/login";
import EditUserScreen from "../screens/user/edit-user";
import NotFoundScreen from "../screens/shared/not-found";
import CompanyManagementScreen from "../screens/company";
import AddCompanyScreen from "../screens/company/add-company";
import EditCompanyScreen from "../screens/company/edit-company";
import ResetPassword from "../screens/user/reset-password/reset-password.container";
import AddUserScreen from "../screens/user/add-user";

const routes = [
  {
    key: "app.login",
    path: "/login",
    isMenu: false,
    auth: false,
    exact: true,
    main: () => <LoginScreen />,
  },
  {
    key: "app.login",
    path: "/login/:userId",
    isMenu: false,
    auth: false,
    exact: true,
    main: () => <LoginScreen />,
  },
  {
    key: "app.companies.list",
    path: "/companies",
    icon: "fa fa-building",
    name: "listProviders.title",
    isMenu: true,
    auth: true,
    exact: true,
    main: () => <CompanyManagementScreen />,
  },
  {
    key: "app.companies.new",
    path: "/companies/new",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <AddCompanyScreen />,
  },
  {
    key: "app.companies.edit",
    path: "/companies/:id/edit",
    isMenu: false,
    auth: true,
    exact: true,
    main: () => <EditCompanyScreen />,
  },
  {
    key: "app.users.edit",
    path: "/edit-user/:userId",
    isMenu: false,
    auth: true,
    exact: false,
    main: () => <EditUserScreen />,
  },
  {
    path: "/reset-password/:userId",
    icon: "",
    name: "Reset PassWord",
    isMenu: false,
    auth: true,
    exact: false,
    main: () => <ResetPassword />,
  },
  {
    key: "app.users.list",
    path: "/users",
    icon: "fa fa-building",
    name: "listProviders.userManagement",
    isMenu: true,
    auth: true,
    exact: true,
    main: () => <UserManagement />,
  },
  {
    key: "app.users.new",
    path: "/add-user",
    isMenu: false,
    auth: true,
    exact: false,
    main: () => <AddUserScreen />,
  },
  {
    path: "",
    exact: false,
    auth: false,
    main: () => <NotFoundScreen />,
  },
];

export default routes;

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { deleteUsers } from "../../modules/users/users.actions";
import { UserManagementScreen } from "./user-management.screen";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
  return {
    selectedUserIds: state.users?.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUsers: (userIds) => dispatch(deleteUsers(userIds)),
  };
};
export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(UserManagementScreen);

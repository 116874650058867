import React, { Component } from "react";
import { Content } from "antd/lib/layout/layout";
import { Button, Checkbox, Divider, Layout, Modal, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";

import TopBar from "../../../components/topbar";
import importDataService from "../../../data-services/import/import-data.service";
import { FORMAT_DATETIME } from "../../../constants/constants";
import moment from "moment";
import "../view-import-list.scss";
import { Link } from "react-router-dom";
import _ from "lodash";
import areaStationDataService from "../../../data-services/area-stations/area-stations-data.service";
import EditSpacesComponent from "../../../components/edit-spaces/edit-spaces.component";

export class EditImportListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Modify Import List",
      editSpaceDialog: false,
      editingProduct: null,
      editingOrder: null,
      areaList: [],
      initialData: {
        areas: [],
        total: 0,
      },
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.refresh();
  }

  render() {
    let { code, createdTime, orders } = this.state.initialData;
    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={this.state.title} />
          <div className="bg-white clearfix beecow-import-packages">
            <Content className="beecow-view-import-list">
              <h2 className="text-center">{this.state.title}</h2>
              <div className="row mt-5">
                <div className="col-sm-4">
                  <p>
                    <b>Import ID: {code}</b>
                  </p>
                </div>
                <div className="col-sm-4">
                  <p>
                    <b>Total Orders: {orders?.length}</b>
                  </p>
                </div>
                <div className="col-sm-4">
                  <p>
                    <b>Import Date:</b>{" "}
                    {moment.utc(createdTime).local().format(FORMAT_DATETIME)}
                  </p>
                </div>
              </div>
              <Button
                onClick={() => this.props.history?.goBack()}
                className="float-right mb-3"
                type="warning"
                ghost
              >
                Back
              </Button>
              <Divider />
              {orders?.map((order, index) => this.displayOrder(order, index))}
            </Content>
            {this.renderEditSpacesDialog()}
          </div>
        </div>
      </Layout>
    );
  }

  refresh() {
    let importListId = this.props.match?.params?.id;
    if (importListId) {
      importDataService.getById(importListId).then(
        (data) => {
          this.setState({ initialData: data });
        },
        () => {
          if (
            this.formRef &&
            this.formRef.current &&
            this.formRef.current.clearCache
          ) {
            this.formRef.current.clearCache();
          }
        }
      );
    }
  }

  renderEditSpacesDialog = () => {
    var { editingOrder, editingProduct, areaList } = this.state;
    var values = this.getInitialEditData();

    return (
      <Modal
        title="Edit Assigned Spaces"
        centered
        visible={this.state.editSpaceDialog}
        maskClosable={false}
        onOk={this.onSaveAssignedSpace}
        onCancel={this.cancelEditSpaces}
        okText="Save"
        cancelText="Cancel"
        width={680}
      >
        <div className="row col-12 m-0 p-0">
          <div className="col-12">
            <p>
              <b>Order:</b> {editingOrder?.code}
            </p>
          </div>
          <div className="row col-12">
            <p className="col-6 pr-3">
              <b>Product:</b> {editingProduct?.name} ({editingProduct?.code})
            </p>
            <p className="col-3 pr-3">
              <b>Amount:</b> {editingProduct?.total ?? 0}
            </p>
            <p className="col-3 pr-3">
              <b>Remaining:</b>{" "}
              <span class="text-danger">
                {editingProduct?.remainingAmount ?? 0}
              </span>
            </p>
          </div>
          <div className="row col-12">
            <EditSpacesComponent
              ref={this.formRef}
              areas={areaList}
              initialData={values}
              onChangeRemainingAmount={this.onChangeRemainingAmount}
            />
          </div>
        </div>
      </Modal>
    );
  };

  displayOrder(order, index) {
    return (
      <div className="beecow-products-info" key={index}>
        <div className="row">
          <div className="col-sm-6">
            <p>
              <b>
                Order ID:{" "}
                <Link
                  style={{ textDecoration: "underline" }}
                  to={`/view-order/${order?.id}`}
                >
                  {order?.code}
                </Link>
              </b>
            </p>
            <p>
              <b>Order Created Date:</b>{" "}
              {moment.utc(order?.createdTime).local().format(FORMAT_DATETIME)}
            </p>
            <p>
              <b>Total Items in Order:</b>{" "}
              {_.sumBy(order?.orderItems, "importedAmount")}
            </p>
            <p>
              <b>Total Weight in Order:</b>{" "}
              {_.sumBy(order?.orderItems, function (i) {
                return (i.importedAmount || 0) * i.product?.weight;
              })}
              kg
            </p>
          </div>
          <div className="col-sm-6">
            <p>
              <b>
                Picked List ID:{" "}
                <Link
                  style={{ textDecoration: "underline" }}
                  to={`/view-pickup-list/${order?.orderPickup?.id}`}
                >
                  {order?.orderPickup?.code}
                </Link>
              </b>
            </p>
            <p>
              <b>Picked Date:</b>{" "}
              {moment.utc(order?.pickedDate).local().format(FORMAT_DATETIME)}
            </p>
            <p>
              <b>Assigned Vehicle:</b> {order?.orderPickup?.vehicle?.code}
            </p>
            <p>
              <b>Assigned Driver:</b> {order?.orderPickup?.driver?.fullName}
            </p>
          </div>
        </div>
        <table className="table table-borderless table-border-none">
          <thead>
            <tr>
              <th>No.</th>
              <th>Product Code</th>
              <th>Product Name</th>
              <th>Product Category</th>
              <th>Size (m)</th>
              <th>Weight (kg)</th>
              <th>Amount</th>
              <th>Assigned Spaces</th>
              <th className="text-center">Imported</th>
            </tr>
          </thead>
          <tbody>
            {order?.orderItems?.map((orderItem, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{orderItem?.product?.code}</td>
                  <td>{orderItem?.product?.name}</td>
                  <td>{orderItem?.product?.packageType?.name}</td>
                  <td>
                    {orderItem?.product?.length}m - {orderItem?.product?.width}m
                    - {orderItem?.product?.height}m
                  </td>
                  <td>{orderItem?.product?.weight}kg</td>
                  <td>
                    {orderItem?.importedAmount || 0}/{orderItem?.amount}
                  </td>
                  <td>{this.displaySpaces(order, orderItem?.product)}</td>
                  <td className="text-center">
                    {orderItem?.importedAmount === orderItem?.amount ? (
                      <Checkbox checked={orderItem?.imported} disabled />
                    ) : (
                      <span>{orderItem?.importFailedReason}</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Divider />
      </div>
    );
  }

  displaySpaces(order, product) {
    let isShipping = order?.contractId ? false : true;
    let spaces =
      product?.spaceAreas && product?.spaceAreas?.length > 0
        ? product?.spaceAreas
        : product?.productSpaceShippings?.map((ps) => ps.space);
    let areas = [];
    spaces?.forEach((space) => {
      let index = areas.findIndex((a) => a.id === space.area?.id);
      if (index === -1) {
        let area = space.area;
        area.spaces = [space];
        areas.push(area);
      } else {
        let area = areas[index];
        area.spaces.push(space);
        areas[index] = area;
      }
    });

    let spaceOptions =
      this.state.editData?.pagingSpaces?.rows?.map((space, index) => {
        return (
          <Select.Option key={index} value={space.code}>
            {space.code} ({space.used}/{space.max})
          </Select.Option>
        );
      }) || [];
    spaceOptions = !this.state.editData?.isLoadingMore
      ? spaceOptions
      : [
          ...spaceOptions,
          <Select.Option key="loading">Loading...</Select.Option>,
        ];
    if (areas.length > 0) {
      var area = areas[0];
      var areaHtml = areas.map((area, index) => {
        return (
          <p>
            {area.code +
              ": " +
              _.join(
                area.spaces?.map((space) => space.code),
                ", "
              )}
          </p>
        );
      });

      return (
        <>
          <div className="beecow-spaces">
            <div>{areaHtml}</div>
            <Button
              className="beecow-spaces-action-edit"
              icon={<EditOutlined />}
              type="text"
              onClick={() => this.editSpaces(order, product, area)}
            ></Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          {isShipping && (
            <Button
              className="beecow-spaces-action-edit"
              icon={<EditOutlined />}
              type="text"
              onClick={() => this.editSpaces(order, product, null)}
            ></Button>
          )}
        </>
      );
    }
  }

  getInitialEditData = () => {
    var { editingProduct } = this.state;
    var spaces =
      editingProduct?.spaceAreas && editingProduct?.spaceAreas?.length > 0
        ? editingProduct?.spaceAreas
        : editingProduct?.productSpaceShippings?.map((ps) => {
            return {
              space: ps.space,
              total: ps.total ?? 0,
            };
          });

    var areas = spaces?.map((i) => {
      return {
        areaId: i?.space?.area?.id,
        area: i?.space?.area,
        spaceId: i?.space?.id,
        space: i?.space,
        amount: i?.total,
      };
    });

    return {
      areas: areas,
      total: editingProduct?.total,
    };
  };

  onSaveAssignedSpace = () => {
    var { currentStationId } = this.props;
    var { editingProduct } = this.state;

    if (this.formRef) {
      var remainingAmount = this.formRef.current.getRemainingAmount();
      if (remainingAmount < 0 || remainingAmount > 0) {
        console.log("Can not save assigned space");
        return;
      }

      var editSpacesData = this.formRef.current.getFieldsValue();
      var { areas } = editSpacesData;
      var spaceShippings = areas?.map((a) => {
        return {
          spaceId: a.spaceId,
          total: a.amount,
        };
      });
      var spaceNewIds = areas?.map((a) => a.spaceId);
      let request = {
        stationId: currentStationId,
        productId: editingProduct?.id,
        spaceShippings: spaceShippings,
        spaceNewIds: spaceNewIds,
      };

      importDataService
        .updateProductSpaceShipping(request)
        .then((res) => {
          this.refresh();
          if (
            this.formRef &&
            this.formRef.current &&
            this.formRef.current.clearCache
          ) {
            this.formRef.current.clearCache();
            var values = this.getInitialEditData();
            this.formRef.current.setFieldsValue(values);
          }
        })
        .finally(() => {
          this.cancelEditSpaces();
        });
    }
  };

  async editSpaces(order, product, area) {
    product.remainingAmount = product.total;
    let promises = [];
    promises.push(
      areaStationDataService.getAreasForShippingByStationId(order?.stationId)
    );
    promises.push(
      areaStationDataService.getSpacesByAreaId(area?.id, 1, 20, true, false)
    );
    let [areas] = await Promise.all(promises);
    this.setState(
      {
        editSpaceDialog: true,
        areaList: areas,
        editingOrder: order,
        editingProduct: product,
      },
      () => {
        var values = this.getInitialEditData();
        if (
          this.formRef &&
          this.formRef.current &&
          this.formRef.current.setFieldsValue
        ) {
          this.formRef.current.setFieldsValue(values);
        }

        if (
          this.formRef &&
          this.formRef.current &&
          this.formRef.current.setRemainingAmount
        ) {
          this.formRef.current.setRemainingAmount();
        }
      }
    );
  }

  cancelEditSpaces = () => {
    this.setState({
      editSpaceDialog: false,
      initialData: {
        ...this.state.initialData,
        areas: [],
        total: 0,
      },
    });
    
    this.formRef.current.clearCache();
  };

  onChangeRemainingAmount = (remainingAmount) => {
    var { editingProduct } = this.state;
    editingProduct.remainingAmount = remainingAmount;
    this.setState({
      editingProduct: editingProduct,
    });
  };
}

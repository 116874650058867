import React, { Component } from "react";
import { Popconfirm, Button } from "antd";

export default class DeletePopconfirmComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmLoading: false,
    };
  }

  render() {
    const { t, deleteConfirmLoading, visible, isButton, okText, cancelText } =
      this.props;
    let message = this.props?.message ?? t("message.deleteRecordConfirm");
    let buttonName = this.props?.buttonName ?? t("button.delete");

    return (
      <Popconfirm
        title={message}
        visible={visible}
        onConfirm={this.handleOk}
        okButtonProps={{ loading: deleteConfirmLoading }}
        onCancel={this.handleCancel}
        okText={okText}
        cancelText={cancelText}
      >
        {isButton ? (
          <Button type="danger" onClick={this.showPopconfirm}>
            {buttonName}
          </Button>
        ) : (
          <a className="mr-2 delete" onClick={this.showPopconfirm}>
            {" "}
            <i className="fa fa-trash" aria-hidden="true"></i>
          </a>
        )}
      </Popconfirm>
    );
  }

  showPopconfirm = () => {
    this.setState({ visible: true });
  };

  handleOk = () => {
    this.setState({ visible: false, deleteConfirmLoading: false });
    if (this.props?.callBack) this.props?.callBack();
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
}

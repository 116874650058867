const actionTypes = {
    SET_AUTH: 'SET_AUTH',
    SET_WORKSPACE: 'SET_WORKSPACE',
    SWITCH_WORKSPACE: 'SWITCH_WORKSPACE',
    START_SWITCHING_WORKSPACE: 'START_SWITCHING_WORKSPACE',
    FINISH_SWITCHING_WORKSPACE: 'FINISH_SWITCHING_WORKSPACE',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    RESET_SESSION: 'RESET_SESSION',
    UPDATE_USER_NAME: 'UPDATE_USER_NAME',
    SET_CHANGED_PASSWORD: 'SET_CHANGED_PASSWORD',
    SET_AUTH_TOKEN: 'SET_AUTH_TOKEN'

}
export default actionTypes;
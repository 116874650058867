import React, { Component } from "react";
import { Input, Form, DatePicker } from "antd";
import { Content } from "antd/lib/layout/layout";
import languageService from "../../../services/language.service";
import AddressComponent from "../../../components/address/address-component";

export default class WarehouseAdminInfoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: props?.initialData,
    };
    this.formRef = React.createRef();
    this.addressRef = React.createRef();
  }

  componentDidMount() {
    this.refresh();
  }

  render() {
    const { t } = this.props;
    const { initialData } = this.state;
    let address = initialData?.warehouseAdminInformation?.address;
    return (
      <Content>
        <Form ref={this.formRef} layout="vertical">
          <p style={{ marginBottom: "5%" }}>
            {t("stationRegistration.pleaseInputWarehouseAdminInformation")}
          </p>
          <Form.Item
            label={t("user.fullName")}
            name={["warehouseAdminInformation", "fullName"]}
            rules={[
              {
                required: true,
                message: `${t("notification.login.userName")}`,
              },
            ]}
          >
            <Input placeholder={t("placeholder.inputName")} />
          </Form.Item>
          <Form.Item
            label={t("user.phone")}
            name={["warehouseAdminInformation", "phoneNumber"]}
            rules={[
              { required: true, message: `${t("notification.login.phone")}` },
              {
                pattern:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                message: `${t("notification.login.validPhone")}`,
              },
            ]}
          >
            <Input type="tel" placeholder={t("placeholder.inputPhone")} />
          </Form.Item>
          <Form.Item
            name={["warehouseAdminInformation", "dateOfBirth"]}
            label={t("user.dateOfBirth")}
          >
            <DatePicker locale={languageService.getLocale()} />
          </Form.Item>
          <Form.Item
            name={["warehouseAdminInformation", "warehouseAdminEmail"]}
            label={t("user.userCreate.email")}
            rules={[
              {
                type: "email",
                message: `${t("notification.userProfile.emailValidate")}`,
              },
              {
                required: true,
                message: `${t("notification.userProfile.inputEmail")}`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("address.address")}
            className="col-12 pr-2"
            name={["address"]}
            rules={[{ required: true }]}
          >
            <AddressComponent
              t={t}
              ref={this.addressRef}
              initialData={address}
            />
          </Form.Item>
        </Form>
      </Content>
    );
  }

  getFieldsValue = () => {
    let formData = this.formRef.current.getFieldsValue();
    let newFormData = {
      ...this.state.initialData,
      warehouseAdminInformation: {
        ...formData?.warehouseAdminInformation,
        address: formData?.address,
      },
    };
    return newFormData;
  };

  validateFields = () => {
    return this.formRef.current.validateFields();
  };

  refresh = () => {
    const { initialData } = this.state;
    if (initialData?.warehouseAdminInformation) {
      let formData = {
        ...initialData,
        warehouseAdminInformation: {
          ...initialData.warehouseAdminInformation,
          ...initialData?.warehouseAdminInformation?.address,
        },
      };
      this.formRef.current.setFieldsValue(formData);
    }
  };
}

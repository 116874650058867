import { DataService } from "../base/base-data.service";

class StationTypeDataService extends DataService {
    apiUrl = '/StationType';

    async getAllStationType() {
        return await this.get(`${this.apiUrl}/loadData`);
    }
}

let stationTypeDataService = new StationTypeDataService();

export default stationTypeDataService;
import { connect } from "react-redux";
import { AddUserScreen } from "./add-user.screen";
import { getCities } from "../../../modules/address/address.actions";
import { bindActionCreators } from "redux";
import { getInitUser } from "../../../modules/users/users.actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = ({ address, users }) => {
  return {
    cities: address.cities || [],
    initUser: users.create?.initUser || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCities,
      getInitUser,
    },
    dispatch
  );
};
export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(AddUserScreen);

import actionTypes from "./app.types";

export function startDataServiceProcessing() {
    return { type: actionTypes.START_DATASERVICE_PROCESSING };
}

export function stopDataServiceProcessing() {
    return { type: actionTypes.STOP_DATASERVICE_PROCESSING };
}

export function setJustLoggedIn(payload) {
    return { type: actionTypes.SET_JUST_LOGGED_IN, payload };
}
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { admin, superAdmin, warehouseAdmin } from "../../../constants/user-constants";
import { initWorkspace, setAuthentication } from "../../../modules/session/session.actions";
import { ResetPassWordScreen } from "./reset-password.sceen";

const mapStateToProps = (state) => ({
  role: state.session?.auth?.user?.role,
  auth: state.session?.auth,
  changedPassWord: state.session?.auth?.user?.changedPassWord === "True" ? true : false,
  isAuthenticated:
    state.session?.auth?.token &&
    (state.session?.auth?.user?.role === superAdmin ||
      state.session?.auth?.user?.role === admin ||
      state.session?.auth?.user?.role === warehouseAdmin)
      ? true
      : false,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthentication: (data, callback) => dispatch(setAuthentication(data, callback)),
    initWorkspace: (data) => dispatch(initWorkspace(data)),
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ResetPassWordScreen);

import React, { Component } from "react";
import { Content } from "antd/lib/layout/layout";
import { Checkbox, Divider, Layout, Modal, Select } from "antd";
import Button from "antd-button-color";
import TopBar from "../../components/topbar";
import importDataService from "../../data-services/import/import-data.service";

import moment from "moment";
import "./view-import-list.scss";
import { Link } from "react-router-dom";
import _ from "lodash";
import areaStationDataService from "../../data-services/area-stations/area-stations-data.service";
import { FORMAT_DATETIME } from "../../common/common";
import Barcode from "react-barcode";

export class ViewImportListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: {},
      editData: {
        isProcessing: false,
        orderId: "",
        productId: "",
        areaId: "",
        areaNewId: "",
        spaceIds: [],
        spaceNewIds: false,
        areas: [],
        pagingSpaces: {
          page: 0,
          records: 0,
          total: 0,
          rows: [],
        },
        canLoadMore: false,
        isLoadingMore: false,
        showBarcode: false,
        onlyPrintBarcode: true,
      },
    };
  }

  componentDidMount() {
    this.refresh();
    window.onafterprint = () => {
      this.setState({ showBarcode: false, onlyPrintBarcode: false });
    };
  }

  render() {
    const { showBarcode, onlyPrintBarcode } = this.state;
    let { code, createdTime, orders } = this.state.initialData;
    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title="View Import List" />

          <div className="bg-white clearfix beecow-import-packages">
            <Content className="beecow-view-import-list">
              {showBarcode && (
                <div className="row">
                  <Barcode def value={code} format="code128" />
                </div>
              )}
              {!onlyPrintBarcode && (
                <>
                  <div className="row mt-5">
                    <div className="col-sm-4">
                      <p>
                        <b>Import ID: {code}</b>
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <p>
                        <b>Total Orders: {orders?.length}</b>
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <p>
                        <b>Import Date:</b>{" "}
                        {moment
                          .utc(createdTime)
                          .local()
                          .format(FORMAT_DATETIME)}
                      </p>
                    </div>
                  </div>

                  <Divider />
                  {orders?.map((order, index) =>
                    this.displayOrder(order, index)
                  )}

                  {this.renderAction()}
                </>
              )}
            </Content>
          </div>
        </div>
      </Layout>
    );
  }

  refresh() {
    let importListId = this.props.match?.params?.id;
    if (importListId) {
      importDataService.getById(importListId).then((data) => {
        this.setState({ initialData: data });
      });
    }
  }

  displayOrder(order, index) {
    return (
      <div className="beecow-products-info" key={index}>
        <div className="row">
          <div className="col-sm-4">
            <p>
              <b>
                Order ID:{" "}
                <Link
                  style={{ textDecoration: "underline" }}
                  to={`/view-order/${order?.id}`}
                >
                  {order?.code}
                </Link>
              </b>{" "}
            </p>
            <p>
              <b>Order Created Date:</b>{" "}
              {moment.utc(order?.createdTime).local().format(FORMAT_DATETIME)}
            </p>
            <p>
              <b>Total Items in Order:</b>{" "}
              {_.sumBy(order?.orderItems, "importedAmount")}
            </p>
            <p>
              <b>Total Weight in Order:</b>{" "}
              {_.sumBy(order?.orderItems, function (i) {
                return (i.importedAmount || 0) * i.product?.weight;
              })}
              kg
            </p>
          </div>
          <div className="col-sm-3">
            <p>{order.status?.statusName}</p>
            <p>{order.delivery?.name}</p>
          </div>
          <div className="col-sm-5">
            <p>
              <b>
                Picked List ID:{" "}
                <Link
                  style={{ textDecoration: "underline" }}
                  to={`/view-pickup-list/${order?.orderPickup?.id}`}
                >
                  {order?.orderPickup?.code}
                </Link>
              </b>
            </p>
            <p>
              <b>Picked Date:</b>{" "}
              {moment.utc(order?.pickedDate).local().format(FORMAT_DATETIME)}
            </p>
            <p>
              <b>Assigned Vehicle:</b> {order?.orderPickup?.vehicle?.code}
            </p>
            <p>
              <b>Assigned Driver:</b> {order?.orderPickup?.driver?.fullName}
            </p>
          </div>
        </div>
        <table className="table table-borderless table-border-none">
          <thead>
            <tr>
              <th>No.</th>
              <th>Product Code</th>
              <th>Product Name</th>
              <th>Product Category</th>
              <th>Size (m)</th>
              <th>Weight (kg)</th>
              <th>Amount</th>
              <th>Assigned Spaces</th>
              <th>Scanned</th>
            </tr>
          </thead>
          <tbody>
            {order?.orderItems?.map((orderItem, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{orderItem?.product?.code}</td>
                  <td>{orderItem?.product?.name}</td>
                  <td>{orderItem?.product?.packageType?.name}</td>
                  <td>
                    {orderItem?.product?.length}m - {orderItem?.product?.width}m
                    - {orderItem?.product?.height}m
                  </td>
                  <td>{orderItem?.product?.weight}kg</td>
                  <td>
                    {orderItem?.importedAmount || 0}/{orderItem?.amount}
                  </td>
                  <td>{this.displaySpaces(order, orderItem?.product)}</td>
                  <td>
                    {orderItem?.importedAmount === orderItem?.amount ? (
                      <Checkbox checked={orderItem?.imported} disabled />
                    ) : (
                      <span>{orderItem?.importFailedReason}</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Divider />
      </div>
    );
  }

  displaySpaces(order, product) {
    let spaces =
      product?.spaceAreas && product?.spaceAreas?.length > 0
        ? product?.spaceAreas
        : product?.productSpaceShippings?.map((ps) => ps.space);
    let areas = [];
    spaces?.forEach((space) => {
      let index = areas.findIndex((a) => a.id === space.area?.id);
      if (index === -1) {
        let area = space.area;

        area.spaces = [space];
        areas.push(area);
      } else {
        let area = areas[index];

        area.spaces.push(space);
        areas[index] = area;
      }
    });
    let spaceOptions =
      this.state.editData?.pagingSpaces?.rows?.map((space, index) => (
        <Select.Option key={index} value={space.code}>
          {space.code} ({space.used}/{space.max})
        </Select.Option>
      )) || [];
    spaceOptions = !this.state.editData?.isLoadingMore
      ? spaceOptions
      : [
          ...spaceOptions,
          <Select.Option key="loading">Loading...</Select.Option>,
        ];
    return areas.map((area, index) => {
      return (
        <div className="beecow-spaces" key={index}>
          <span>
            {area.code +
              ": " +
              _.join(
                area.spaces?.map((space) => space.code),
                ", "
              )}
          </span>
        </div>
      );
    });
  }

  async editSpaces(order, product, area) {
    let spaceIds = area?.spaces?.map((s) => s.code);
    this.setState({
      editData: {
        ...this.state.editData,
        isProcessing: true,
        orderId: order?.id,
        productId: product?.id,
        areaId: area?.id,
        areaNewId: "",
        spaceIds: spaceIds,
        spaceNewIds: false,
        areas: [],
        pagingSpaces: {},
      },
    });

    let promises = [];
    promises.push(
      areaStationDataService.getAreasForShippingByStationId(order?.stationId)
    );
    promises.push(areaStationDataService.getSpacesByAreaId(area?.id, 1, 20));
    let [areas, pagingSpaces] = await Promise.all(promises);

    let canLoadMore =
      pagingSpaces?.rows && pagingSpaces?.rows?.length > 0 ? true : false;
    this.setState({
      editData: {
        ...this.state.editData,
        areas,
        pagingSpaces,
        isProcessing: false,
        canLoadMore: canLoadMore,
      },
    });
  }

  cancelEditSpaces() {
    this.setState({
      editData: {},
    });
  }

  completeEditSpaces(order, product, area) {
    if (this.state.editData?.spaceNewIds) {
      if (
        this.state.editData?.spaceNewIds.length !==
        this.state.editData?.spaceIds?.length
      ) {
        Modal.info({
          content: (
            <div>
              <br />
              <br />
              <p>You must select:</p>
              <p>
                &emsp;<b>1</b> Area.
              </p>
              <p>
                &emsp;<b>{this.state.editData?.spaceIds?.length}</b> Spaces.
              </p>
            </div>
          ),
          onOk() {},
        });
      } else {
        let spacesNew = this.state.editData?.pagingSpaces?.rows?.filter(
          (space) =>
            this.state.editData?.spaceNewIds?.findIndex(
              (spaceCode) => spaceCode === space.code
            ) !== -1
        );
        let spaceShippings = product?.productSpaceShippings?.filter(
          (pss) =>
            this.state.editData?.spaceIds?.findIndex(
              (spaceCode) => spaceCode === pss.space?.code
            ) !== -1
        );
        let request = {
          productId: this.state.editData?.productId,
          spaceShippings: spaceShippings?.map((pss) => {
            return { id: pss.space?.id, total: pss.total };
          }),
          spaceNewIds: spacesNew?.map((s) => s.id),
        };
        importDataService
          .updateProductSpaceShipping(request)
          .then(() => {
            this.refresh();
          })
          .finally(() => {
            this.cancelEditSpaces();
          });
      }
    }
  }

  onChangeArea(areaId) {
    this.setState(
      {
        editData: {
          ...this.state.editData,
          isProcessing: true,
          areaNewId: areaId,
          pagingSpaces: {},
        },
      },
      async () => {
        let pagingSpaces = await areaStationDataService.getSpacesByAreaId(
          areaId,
          1,
          20
        );
        let canLoadMore =
          pagingSpaces?.rows && pagingSpaces?.rows?.length > 0 ? true : false;
        this.setState({
          editData: {
            ...this.state.editData,
            isProcessing: false,
            pagingSpaces,
            canLoadMore: canLoadMore,
          },
        });
      }
    );
  }

  onChangeSpaces(spaceIds) {
    this.setState(
      {
        editData: { ...this.state.editData, spaceNewIds: spaceIds },
      },
      () => {
        console.log(this.state.editData);
      }
    );
  }

  onScrollSpace(event) {
    let target = event.target;
    if (
      this.state.editData?.canLoadMore &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      this.setState(
        {
          editData: { ...this.state.editData, isLoadingMore: true },
        },
        async () => {
          target.scrollTo(0, target.scrollHeight);
          let pagingSpaces = await areaStationDataService.getSpacesByAreaId(
            this.state.editData?.areaId,
            this.state.editData?.pagingSpaces?.page + 1,
            20
          );
          let canLoadMore =
            pagingSpaces?.rows && pagingSpaces?.rows?.length > 0 ? true : false;
          this.setState({
            editData: {
              ...this.state.editData,
              canLoadMore: canLoadMore,
              isLoadingMore: false,
              pagingSpaces: {
                ...this.state.editData?.pagingSpaces,
                page: pagingSpaces.page,
                records: pagingSpaces.records,
                total: pagingSpaces.total,
                rows: [
                  ...this.state.editData?.pagingSpaces?.rows,
                  ...pagingSpaces.rows,
                ],
              },
            },
          });
        }
      );
    }
  }

  renderAction() {
    return (
      <div className="pull-right no-print">
        <a
          className="text-primary mR20"
          onClick={() => this.props.history?.goBack()}
        >
          Back
        </a>
        <Button
          type="primary"
          className="btn-primary text-white"
          onClick={this.handlePrintBarcode.bind()}
        >
          Print
        </Button>
      </div>
    );
  }
  handlePrintBarcode = () => {
    this.setState({ showBarcode: true, onlyPrintBarcode: false }, () => {
      window.print();
    });
  };
}

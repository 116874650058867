import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { AddAreaScreen } from "./add-area.screen";

const mapStateToProps = ({ warehouse, session }) => {
  return {
    id: warehouse.id,
    idArea: warehouse.idArea,
    idSpace: warehouse.idSpace,
    stationId: session?.auth?.user?.currentStationId,
    role: session?.auth?.user?.role,
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, null),
  withRouter
)(AddAreaScreen);

import { DataService } from "../base/base-data.service";

class ShippingFeeDataService extends DataService {
    apiUrl = '/shippingfee';

    async getListShippingFee(page, pageSize) {
        return await this.get(`${this.apiUrl}/getAllShippingFee`);
    }

    async getShippingFees() {
        return await this.get(`${this.apiUrl}/shippingFees`);
    }

    async getShippingFeeId(id) {
        return await this.get(`${this.apiUrl}/${id}`);
    }

    async addShippingFee(data) {
        return await this.post(`${this.apiUrl}/insert`, data);
    }

    async updateShippingFee(data) {
        return await this.post(`${this.apiUrl}/update`, data);
    }

    async deleteShippingFee(data) {
        return await this.post(`${this.apiUrl}/delete`, data);
    }

    async addListDetail(data) {
        return await this.post(`${this.apiUrl}/addShippingFee`, data);
    }

    async updateListDetail(data) {
        return await this.post(`${this.apiUrl}/updateShippingFee`, data);
    }

    async getInnerCityFees() {
        return await this.get(`${this.apiUrl}/get-inner-city-fees`);
    }

    async addInnerCityFee(data) {
        return await this.post(`${this.apiUrl}/add-inner-city-fees`, data);
    }

    async updateInnerCityFee(data) {
        return await this.post(`${this.apiUrl}/update-inner-city-fee`, data);
    }

    async deleteInnerCityFees(data) {
        return await this.post(`${this.apiUrl}/delete-inner-city-fee`, data);
    }
}

let shippingFeeDataService = new ShippingFeeDataService();

export default shippingFeeDataService;
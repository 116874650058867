import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  message,
  Card,
  Checkbox,
  Modal,
} from "antd";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import TopBar from "../../../components/topbar";
import companiesDataService from "../../../data-services/companies/companies-data.service";
import userDataService from "../../../data-services/user/user-data.service";

export class EditCompanyScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      fileList: null,
      ModalConfirmChangePassword: {
        visible: false,
        modalText: `${props.t(
          "notification.changePassword.confirmChangePassword"
        )}`,
        confirmLoading: false,
      },
    };
    this.formRef = React.createRef();
    this.back = this.back.bind(this);
  }

  render() {
    let selectedCompany = this.props.selectedCompany;
    let adminUser = (selectedCompany?.users || [])[0];

    let formInitialValues = {
      companyId: selectedCompany?.id,
      companyName: selectedCompany?.name,
      userId: adminUser?.id,
      userFullName: adminUser?.fullName,
      userEmail: adminUser?.email,
      userPhone: adminUser?.phone,
      isAvailable: adminUser?.isAvailable,
    };

    let fileList = this.state.fileList || [
      {
        url: selectedCompany?.logoPath,
      },
    ];
    const { t } = this.props;

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("providerEdit.title")} />
          <Content>
            <Card>
              <Form
                ref={this.formRef}
                layout="vertical"
                initialValues={formInitialValues}
                requiredMark={false}
                onFinish={this.create.bind(this)}
              >
                <Row>
                  <Col span={8} offset={2}>
                    <h1 style={{ textAlign: "center" }}>
                      <b>{t("providerCreate.providerInfo")}</b>
                    </h1>
                    <br />
                    <Form.Item name="companyId" hidden={true} />
                    <Form.Item
                      label={t("providerCreate.providerName")}
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            "notification.providerCreate.providerName"
                          )}`,
                        },
                      ]}
                    >
                      <Input placeholder={t("placeholder.inputProviderName")} />
                    </Form.Item>
                    <Form.Item
                      label={t("providerCreate.providerLogo")}
                      name="companyLogoFile"
                    >
                      <Upload
                        fileList={fileList}
                        maxCount={1}
                        listType="picture-card"
                        className="beecow-upload-list-inline"
                        beforeUpload={this.handleBeforeUpload}
                        onChange={this.handleChange.bind(this)}
                        onPreview={this.handlePreview}
                      >
                        {(!fileList || fileList.length === 0) && "+ Upload"}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={8} offset={2}>
                    <h1 style={{ textAlign: "center" }}>
                      <b>{t("providerCreate.firstAdminAccount")}</b>
                    </h1>
                    <br />
                    <Form.Item name="userId" hidden={true} />
                    <Form.Item
                      label={t("listProviders.nameAdmin")}
                      name="userFullName"
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            "notification.providerCreate.adminName"
                          )}`,
                        },
                      ]}
                    >
                      <Input placeholder={t("placeholder.inputAdminName")} />
                    </Form.Item>
                    <Form.Item
                      label={t("listProviders.emailAdmin")}
                      name="userEmail"
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            "notification.providerCreate.adminEmail"
                          )}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("placeholder.inputAdminEmail")}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      label={t("listProviders.phoneAdmin")}
                      name="userPhone"
                      rules={[
                        {
                          required: true,
                          message: `${t("notification.providerCreate.phone")}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("placeholder.inputAdminPhone")}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label={t("label.password")} name="userPassword">
                      <Input type="password" placeholder="******" disabled />
                    </Form.Item>
                    <Form.Item name="isAvailable" valuePropName="checked">
                      <Checkbox defaultChecked> Available</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} offset={12} style={{ textAlign: "right" }}>
                    <Form.Item className="actions">
                      <Button
                        className="btn-action btn-back"
                        onClick={this.back}
                      >
                        {t("button.back")}
                      </Button>
                      <Button
                        className="btn-action btn-reset"
                        onClick={this.showModal}
                      >
                        {t("button.resetPassword")}
                      </Button>
                      <Button
                        className="btn-action btn-insert"
                        htmlType="submit"
                      >
                        {t("button.update")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Modal
              visible={this.state.ModalConfirmChangePassword.visible}
              onOk={this.resetPassword}
              confirmLoading={
                this.state.ModalConfirmChangePassword.confirmLoading
              }
              onCancel={this.handleCancel}
            >
              <p>{this.state.ModalConfirmChangePassword.modalText}</p>
            </Modal>
          </Content>
        </div>
      </Layout>
    );
  }

  handleBeforeUpload(file) {
    let isImage = /^image\/.*$/i.test(file.type);
    const { t } = this.props;

    if (!isImage) {
      message.error(
        `${file.name} \`${t("notification.upload.uploadImgType")}\``
      );
    }
    let isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error(
        `${file.name} \`${t("notification.upload.uploadImgSize")}\``
      );
    }
    return isImage && isLt5M ? false : Upload.LIST_IGNORE;
  }

  handleChange(event) {
    this.setState({
      selectedFile: event.file,
      fileList: [...event.fileList],
    });
  }

  async handlePreview(file) {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  }

  back() {
    this.props.history?.goBack();
  }

  showModal = () => {
    const { userId } = this.props?.match?.params || {};
    const { t } = this.props;

    let modalText = `${t("notification.changePassword.confirmChangePassword")}`;

    if (userId === this.props?.auth?.user?.userId) {
      modalText = `${t("notification.changePassword.confirmAgain")}`;
    }
    this.setState({
      ModalConfirmChangePassword: {
        ...this.state.ModalConfirmChangePassword,
        visible: true,
        modalText: modalText,
      },
    });
  };

  handleCancel = () => {
    this.setState({
      ModalConfirmChangePassword: {
        ...this.state.ModalConfirmChangePassword,
        visible: false,
      },
    });
  };

  resetPassword = () => {
    const selectedCompany = this.props.selectedCompany;
    const adminUser = (selectedCompany?.users || [])[0];
    const userId = adminUser?.id;
    const { t } = this.props;

    userDataService
      .resetPassword({
        id: userId,
      })
      .then(() => {
        message.success(
          `${t("notification.changePassword.resetPasswordSuccess")}`
        );
        this.setState({
          ModalConfirmChangePassword: {
            ...this.state.ModalConfirmChangePassword,
            confirmLoading: false,
            visible: false,
          },
        });
      });
  };

  create(data) {
    const { t } = this.props;

    let formData = new FormData();
    for (let key in data) {
      if (!key) continue;
      if (key === "companyLogoFile") {
        formData.append(key, this.state.selectedFile);
      } else if (data[key]) {
        formData.append(key, data[key]);
      }
    }
    companiesDataService
      .update(formData)
      .then((res) => {
        this.props.history?.goBack();
      })
      .catch((ex) => {
        console.log(ex);
        message.error(`${t("notification.providerCreate.failCreate")}`);
      });
  }
}

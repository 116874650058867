import { DataService } from "../base/base-data.service";

class LoginDataService extends DataService {
    apiUrl = '/login';

    async authenticate(data) {
        return await this.post(`${this.apiUrl}/authenticate`, data);
    }
    async refreshToken(data) {
        return await this.post(`${this.apiUrl}/refreshToken`, data);
    }
}

let loginDataService = new LoginDataService();

export default loginDataService;
import React from "react";
import { logService } from "./services/base/log/log.service";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidCatch(error) {
     logService.trackException(null, error);
  }

  render() {
    return this.props.children;
  }
}
import actionTypes from "./users.types";
import Moment from "moment";

const usersInitialState = {
    current: 0,
    pageSize: 0,
    total: 0,
    list: [],
    selected: [],
    lastUpdated: 1439478405547,
    create: {
        initUser: []
    },
    userId:{}

};

const usersReducer = (state = usersInitialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LIST_USER:
            return { ...state, total: action.total, list: action.users, lastUpdated: Moment.utc().format('x') };
        case actionTypes.SET_SELECTED_USERS:
            return { ...state, selected: action.userIds, lastUpdated: Moment.utc().format('x') };
        case actionTypes.DELETE_USERS:
            let users = state.list.filter(u => action.userIds.indexOf(u.id) === -1);
            return { ...state, list: users, lastUpdated: Moment.utc().format('x') };
        case actionTypes.GET_INIT_USER:
            return { ...state, create: { ...state.create, initUser: action.payload } };
            case actionTypes.GET_USER_ID:
                return { ...state, userId: action.payload };
        default:
            return state;
    }
}

export default usersReducer;
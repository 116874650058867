import actionTypes from "./app.types";

const appInitialState = {
    isDataServiceProcessing: false,
};

const appReducer = (state = appInitialState, action) => {
    switch (action.type) {
        case actionTypes.START_DATASERVICE_PROCESSING:
            return { ...state, isDataServiceProcessing: true };
        case actionTypes.STOP_DATASERVICE_PROCESSING:
            return { ...state, isDataServiceProcessing: false };
        case actionTypes.SET_JUST_LOGGED_IN:
            return {
                ...state,
                justLoggedIn: action.payload,
            };
        default:
            return state;
    }
}

export default appReducer;
import { DataService } from "../base/base-data.service";

class UserTypesDataService extends DataService {
    apiUrl = '/userType'

    async getUserTypes() {
        return await this.get(`${this.apiUrl}/loadData`);
    }
    
    async deleteUserTypes(userTypeIds) {
        return await this.post(`${this.apiUrl}/delete`, userTypeIds);
    }
}

let userTypesDataService = new UserTypesDataService();

export default userTypesDataService;
import shippingFeeDataService from "../../data-services/shipping-fees/shipping-fees-data.service";
import actionTypes from "./shipping-fee.types";

export function getInnerCityFees() {
  return async (dispatch) => {
      let data = await shippingFeeDataService.getInnerCityFees()
      dispatch({
        type: actionTypes.GET_INNER_CITY_FEES,
        payload: data
      })
  }
}

import actionTypes from "./vehicles.types";
import Moment from "moment";

const vehiclesInitialState = {
    items: [],
    lastUpdated: 1439478405547,
    vehicleId: ''
};

const vehiclesReducer = (state = vehiclesInitialState, action) => {
    switch(action.type){
        case actionTypes.SET_LIST_VEHICLE:
            return {...state, items: action.vehicles, lastUpdated: Moment.utc().format('x')};
        default: 
            return state;
    }
};

export default vehiclesReducer;
import React, { Component } from "react";
import { Input, Select, Form } from "antd";
import { Content } from "antd/lib/layout/layout";
import { locationList, statusList } from "../../../constants/common";
import AddressComponent from "../../../components/address/address-component";

export default class StationInfoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: props?.initialData,
    };
    this.formRef = React.createRef();
    this.addressRef = React.createRef();
  }

  componentDidMount() {
    this.refresh();
  }

  render() {
    const { t } = this.props;
    const { initialData } = this.state;
    let address = initialData?.stationInformation?.address;

    return (
      <Content>
        <Form ref={this.formRef} layout="vertical">
          <p style={{ marginBottom: "5%" }}>
            {t("stationRegistration.pleaseInputStationInformation")}
          </p>
          <Form.Item
            label={t("station.name")}
            name={["stationInformation", "name"]}
            rules={[
              {
                required: true,
                message: t("stationRegistration.pleaseInputStationName"),
              },
            ]}
          >
            <Input
              className="rounded"
              placeholder={t("stationRegistration.stationName")}
            />
          </Form.Item>

          <Form.Item
            label={t("station.phone")}
            name={["stationInformation", "phoneNumber"]}
            rules={[
              {
                required: true,
                message: t("createOrEditOrderPage.form.pleaseInputValidPhone"),
              },
              {
                pattern:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                message: t("createOrEditOrderPage.form.pleaseInputValidPhone"),
              },
            ]}
          >
            <Input placeholder={t("warehouseRegistration.inputPhoneNumber")} />
          </Form.Item>

          <Form.Item
            label={t("station.address")}
            className="col-12 pr-2"
            name={["address"]}
            rules={[{ required: true }]}
          >
            <AddressComponent
              t={t}
              ref={this.addressRef}
              initialData={address}
            />
          </Form.Item>

          <Form.Item
            label={t("station.location")}
            name={["stationInformation", "location", "id"]}
            rules={[
              {
                required: true,
                message: t("warehouseRegistration.inputLocation"),
              },
            ]}
          >
            <Select
              optionFilterProp="children"
              placeholder={t("station.location")}
            >
              {locationList?.map(({ value, text }) => (
                <Select.Option
                  key={`location-dropdown-list-${value}`}
                  value={text}
                >
                  {t(text)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={["stationInformation", "status"]}
            label={t("status.status")}
            rules={[
              {
                required: true,
                message: t("stationRegistration.pleaseSelectStatus"),
              },
            ]}
          >
            <Select
              value={this.state.stStatus}
              optionFilterProp="children"
              style={{ width: "100%" }}
            >
              {statusList.map(({ value, text }) => (
                <Select.Option value={value}>{t(text)}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Content>
    );
  }

  getFieldsValue = () => {
    let formData = this.formRef.current.getFieldsValue();
    let newFormData = {
      ...this.state.initialData,
      stationInformation: {
        ...formData?.stationInformation,
        address: formData?.address,
      },
    };
    return newFormData;
  };

  validateFields = () => {
    return this.formRef.current.validateFields();
  };

  refresh = () => {
    const { initialData } = this.state;
    if (initialData?.stationInformation) {
      let formData = {
        ...initialData,
        stationInformation: {
          ...initialData.stationInformation,
          ...initialData?.stationInformation?.address,
        },
      };
      this.formRef.current.setFieldsValue(formData);
    }
  };
}

import { withRouter } from "react-router-dom";
import { BeecowMenu } from "./menu.component";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        signedInUser: state.session?.auth?.user,
    }
}

export default compose(
    withTranslation("translations"),
    connect(mapStateToProps, null),
    withRouter
)(BeecowMenu)
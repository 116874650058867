import { connect } from'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchCompanyDetail, fetchListCompanies } from '../../../modules/companies/companies.actions';
import { switchWorkspace } from '../../../modules/session/session.actions';
import { TableCompanies } from './table-companies.component';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        current: state.companies?.current || 1,
        pageSize: state.companies?.pageSize || 10,
        total: state.companies?.total,
        companies: state.companies?.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        listCompanies: (page, pageSize) => dispatch(fetchListCompanies(page, pageSize)),
        fetchCompanyDetail: (companyId) => dispatch(fetchCompanyDetail(companyId)),
        switchWorkspace: (workspaceId, workspace) => dispatch(switchWorkspace(workspaceId, workspace)),
    }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps), withRouter
)(TableCompanies);
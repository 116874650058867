import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Switch, message } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import TopBar from "../../../components/topbar";
import { claimTypes } from "../../../constants/claims";
import { admin, superAdmin, warehouseAdmin } from "../../../constants/user-constants";
import loginDataService from "../../../data-services/login/login-data.service";
import userDataService from "../../../data-services/user/user-data.service";
import "./login.screen.scss";
export class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      isSuperAdmin: false,
      loginByPhone: false,
      role: undefined,
      currentStationId: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.callBack = this.callBack.bind(this);
  }

  async componentDidMount() {
    const { userId } = this.props.match?.params;
    if (userId) {
      this.props?.signOut(userId);
      userDataService.getUserId(userId).then((res) => {
        this.setState({ userName: res?.email });
      });
    }
    this.setState({
      isLoggedIn: this.props.isAuthenticated,
      isSuperAdmin: this.props.isSuperAdmin,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="w-100">
        <TopBar />
        <div className="m-auto beecow-login-bg">
          <Form
            name="normal_login"
            className="m-auto centered shadow p-4 pt-5 mb-5 bg-white rounded login-form"
            initialValues={{ rememberMe: true }}
            onFinish={this.onFinish}
          >
            <div className="mb-5 text-center">
              <div className="logo">
                <div className="name d-flex justify-content-center">
                  <h1>BEECOW</h1>
                </div>
                <span className="mb-1">ADMIN</span>
              </div>
            </div>

            <Form.Item>
              <Switch onChange={this.onChange} />
              <span className="ml-3" id="loginByPhone">
                {t("sign-in.sign-in-by-phone")}
              </span>
            </Form.Item>

            {!this.state.loginByPhone ? (
              <div>
                <Form.Item
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: `${t("notification.login.userName")}`,
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t("placeholder.userName")}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: `${t("notification.login.password")}`,
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t("placeholder.passWord")}
                  />
                </Form.Item>
              </div>
            ) : (
              <Form.Item
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: `${t("notification.login.phone")}`,
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t("placeholder.phoneNumber")}
                />
              </Form.Item>
            )}
            <Button type="primary" htmlType="submit" className="login-form-button col-12" id="loginButton">
              {this.props.t("sign-in.sign-in-button")}
            </Button>
          </Form>
        </div>
      </div>
    );
  }

  onChange(checked) {
    this.setState({
      loginByPhone: !this.state.loginByPhone,
    });
  }

  onFinish(values) {
    let self = this;
    const { t, initWorkspace } = this.props;
    loginDataService
      .authenticate(values)
      .then((data) => {
        let token = data.token || "";
        let refreshToken = data.refreshToken || "";
        let exprire = data.exprire;
        let userInfo = self.getUserInfo(token);
        let workspaceId = self.getWorkspaceId(token);
        self.setupWorkspace(token, refreshToken, exprire, userInfo, workspaceId);
      })
      .catch((err) => {
        message.error(`${t("notification.login.fail")}`);
      });
  }

  getUserInfo(token) {
    let claims = jwt_decode(token);
    let user = {
      userId: claims[claimTypes.CLAIMS_SID],
      username: claims[claimTypes.CLAIMS_NAME],
      emailaddress: claims[claimTypes.CLAIMS_EMAIL],
      role: claims[claimTypes.CLAIMS_ROLE],
      currentStationId: claims[claimTypes.CLAIMS_CURRENT_STATION_ID],
      changedPassWord: claims[claimTypes.CLAIMS_CHANGED_PASSWORD],
    };
    this.setState({ ...this.state, currentStationId: user.currentStationId });
    return user;
  }

  getWorkspaceId(token) {
    let claims = jwt_decode(token);
    return claims[claimTypes.CLAIMS_COMPANY_IDENTITY];
  }

  setupWorkspace(token, refreshToken, exprire, userInfo, workspaceId) {
    const { t, setAuthentication, initWorkspace, setJustLoggedIn } = this.props;
    if (userInfo.role === superAdmin || userInfo.role === admin || userInfo.role === warehouseAdmin) {
      let auth = {
        token: token,
        refreshToken: refreshToken,
        exprire: exprire,
        user: userInfo,
      };
      message.success(`${t("notification.login.success")}`);
      this.setState({
        isLoggedIn: true,
        isSuperAdmin: userInfo.role === superAdmin ? true : false,
        role: userInfo.role,
      });
      setAuthentication({ auth, workspaceId }, this.callBack);
      initWorkspace(workspaceId);
      setJustLoggedIn(true);
    } else {
      message.error(`${t("notification.login.failAccept")}`);
    }
  }

  callBack() {
    const { history } = this.props;
    const { role } = this.state;
    if (this.state.isSuperAdmin) {
      history.push("/companies");
    } else {
      if (role === warehouseAdmin) {
        history.push(`/admin/warehouse`);
      } else {
        history.push("/station");
      }
    }
  }
}

import React, { Component } from "react";
import { t } from "i18next";
import { Steps, message } from "antd";
import Button from "antd-button-color";
import Layout from "antd/lib/layout/layout";
import TopBar from "../../components/topbar";
import "./warehouse-registration.screen.scss";
import Summarize from "./summarize/summarize.component";
import WarehouseInfo from "./warehouse-info/warehouse-info.component";
import DefineAreaSpace from "./define-area-space/define-area-space.component";
import stationDataService from "../../data-services/stations/stations-data.service";
import warehouseDataService from "../../data-services/warehouse/warehouse-data.service";

const { Step } = Steps;
const screens = {
  warehouseInfo: 0,
  defineAreaSpace: 1,
  summarize: 2,
};

export default class WarehouseRegistrationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: screens.warehouseInfo,
      stationWarehouse: null,
      warehouseCategories: [],
      warehouseLocations: [],
      stationId: props?.match?.params?.id,
      areaStation: [],
    };
    this.warehouseInfoRef = React.createRef();
    this.defineAreaSpaceRef = React.createRef();
    this.summarizeRef = React.createRef();
  }

  async componentDidMount() {
    let initialData = {};
    let warehouseLocations = await warehouseDataService.getWarehouseLocations();
    if (warehouseLocations) {
      initialData = {
        ...initialData,
        warehouseLocations: warehouseLocations ?? [],
      };
    }

    let station = await stationDataService.getStationId(this.state.stationId);
    if (station) {
      initialData = {
        ...initialData,
        stationWarehouse: {
          stationCode: station.code,
          name: station.name,
          phoneNumber: station.phone,
          locationId: station.locationId,
          address: station.address,
          location: { id: station.locationId },
        },
      };
    }

    let warehouseCategories =
      await warehouseDataService.getWarehouseCategories();
    if (warehouseCategories) {
      initialData = {
        ...initialData,
        warehouseCategories: warehouseCategories ?? [],
      };
    }

    this.setState(initialData);
  }

  render() {
    const {
      current,
      warehouseCategories,
      warehouseLocations,
      stationWarehouse,
      areaStation,
    } = this.state;

    if (!stationWarehouse) {
      return <></>;
    } else {
      const steps = [
        {
          title: t("warehouseRegistration.warehouseInfo"),
          content: (
            <>
              <div className="col-lg-5 col-md-6">
                <WarehouseInfo
                  t={t}
                  ref={this.warehouseInfoRef}
                  locations={warehouseLocations}
                  initialData={stationWarehouse}
                />
              </div>
            </>
          ),
        },
        {
          title: t("warehouseRegistration.defineAreaSpace"),
          content: (
            <DefineAreaSpace
              t={t}
              ref={this.defineAreaSpaceRef}
              categories={warehouseCategories}
              initialData={areaStation}
            />
          ),
        },
        {
          title: t("warehouseRegistration.summarize"),
          content: (
            <Summarize t={t} ref={this.summarizeRef} initialData={this.state} />
          ),
        },
      ];

      return (
        <Layout className="bg-content">
          <div className="bg-body">
            <TopBar title={t("title.warehouseRegistration")} />
            <div className="bg-white clearfix beecow-leasing-contract">
              <Steps
                type="navigation"
                current={current}
                className="site-navigation-steps"
              >
                {steps.map((item) => {
                  return <Step key={item.title} title={item.title} />;
                })}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
              <div className="steps-action clearfix">
                {current > 0 && (
                  <Button type="primary" onClick={this.prev}>
                    {t("button.back")}
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={this.next}>
                    {t("button.next")}
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button type="primary" onClick={this.finish}>
                    {t("button.done")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Layout>
      );
    }
  }

  next = () => {
    let { current } = this.state;
    let currentFormRef;
    switch (this.state.current) {
      case screens.warehouseInfo:
        currentFormRef = this.warehouseInfoRef;

        let warehouseInformation = currentFormRef?.current?.getFieldsValue();
        if (warehouseInformation) {
          this.setState({
            stationWarehouse: {
              ...this.state.stationWarehouse,
              ...warehouseInformation,
            },
          });
        }
        break;
      case screens.defineAreaSpace:
        currentFormRef = this.defineAreaSpaceRef;
        let defineAreaAndSpace = currentFormRef.current.getFieldsValue();

        this.setState({
          ...this.state,
          areaStation: defineAreaAndSpace,
        });
        break;
      default:
        break;
    }

    if (currentFormRef && currentFormRef.current) {
      currentFormRef.current
        .validateFields()
        .then(() => {
          let nextStep = current + 1;
          this.setState({ current: nextStep });
        })
        .catch((ex) => console.log(ex));
    }
  };

  prev = () => {
    let { current } = this.state;
    let preStep = current - 1 <= 0 ? 0 : current - 1;
    this.setState({ current: preStep });
  };

  finish = () => {
    const { t } = this.props;
    const { stationWarehouse, areaStation, stationId } = this.state;
    let request = {
      stationWarehouse: stationWarehouse,
      areaStation: areaStation,
      stationId: stationId,
    };

    stationDataService.warehouseRegistration(request).then((res) => {
      if (res.success === true) {
        message.success(t("warehouseRegistration.createSuccessMessage"));
        this.props.history.push("/station");
      } else {
        if (
          res.message !== null &&
          res.message !== "" &&
          res.message !== undefined
        )
          message.error(t(res.message));
        else message.error(t("warehouseRegistration.createErrorMessage"));
      }
      console.log(res);
    });
  };
}

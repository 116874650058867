import { connect } from 'react-redux';
import { EditUserScreen } from './edit-user.screen';
import { getCities } from '../../../modules/address/address.actions'
import { bindActionCreators } from 'redux';
import { getInitUser, getUserId } from '../../../modules/users/users.actions';
import { withRouter } from 'react-router-dom';
import { getDistrictByCityId, getWardsByDistrictId} from '../../../modules/address/address.actions'
import { updateUserName,resetSession } from '../../../modules/session/session.actions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ address, users,session }) => {
  return {
    cities: address.cities || [],
    initUser: users.create?.initUser || [],
    users: users.userId || {},
    auth:session?.auth
  };
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getCities,
    getInitUser,
    getUserId,
    getDistrictByCityId,
    getWardsByDistrictId,
    updateUserName: (userName) => dispatch(updateUserName(userName)),
    signOut: (userInfo) => dispatch(resetSession(userInfo))
  }, dispatch)
}
export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
  )(EditUserScreen)
import React, { Component } from "react";
import { Form, Button, Select, Input } from "antd";
import _ from "lodash";
import areaStationDataService from "../../data-services/area-stations/area-stations-data.service";
import "./edit-spaces.component.scss";

export default class EditSpacesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: props.areas ?? [],
      areasSelected: [],
      spaceListPaged: [],
      canLoadMore: false,
      isLoadingMore: false,
      initialData: props.initialData ?? {
        areas: [],
        total: 0,
      },
    };

    this.formRef = React.createRef();
  }

  render() {
    return (
      <>
        <hr />
        <Form ref={this.formRef} className="w-100">
          <table
            className="table table-borderless product-table ml-0 table-border-none"
            scroll={{ y: 240 }}
          >
            <thead>
              <tr>
                <th width="50"></th>
                <th>Area</th>
                <th>Space</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>{this.renderListArea()}</tbody>
          </table>
        </Form>
      </>
    );
  }

  renderListArea = () => {
    const { areas, spaceListPaged, isLoadingMore, initialData } = this.state;
    return (
      <>
        <Form.List name="areas" initialValue={initialData.areas}>
          {(fields, { add, remove }) => {
            return fields.length === 0 ? (
              <tr>
                <td>
                  <Button
                    title="Add new record"
                    onClick={() => this.onAddRow(add, [])}
                    type="text"
                  >
                    <i
                      className="fa fa-plus text-success"
                      aria-hidden="true"
                    ></i>
                  </Button>
                </td>
              </tr>
            ) : (
              fields.map((field, index) => {
                var spaceList = spaceListPaged[index]?.spacesExcluded ?? [];
                var spaceOptions =
                  spaceList?.map((space, index) => {
                    var detail =
                      space.used || space.max ? (
                        `${space.used}/${space.max}`
                      ) : (
                        <></>
                      );
                    return (
                      <>
                        <Select.Option key={index} value={space.id}>
                          <span className="float-left">{space.code}</span>
                          <span className="float-right">{detail}</span>
                        </Select.Option>
                      </>
                    );
                  }) || [];
                spaceOptions = !isLoadingMore
                  ? spaceOptions
                  : [
                      ...spaceOptions,
                      <Select.Option key="loading">Loading...</Select.Option>,
                    ];
                return (
                  <tr key={field.key} className="beecow-rows">
                    <td
                      style={{ display: "inline-flex" }}
                      className="pl-0 pr-0"
                    >
                      <Button
                        className="btn-row-action"
                        title="Add new record"
                        type="text"
                        onClick={() => this.onAddRow(add, index + 1)}
                      >
                        <i
                          className="fa fa-plus text-success"
                          aria-hidden="true"
                        ></i>
                      </Button>
                      <Button
                        className="btn-row-action"
                        title="Remove this record"
                        type="text"
                        onClick={() => this.onRemoveRow(remove, field.name)}
                      >
                        <i
                          className="fa fa-close text-danger"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    </td>

                    <td
                      className="pr-0 col-4"
                      style={{ width: "180px", minWidth: "180px" }}
                    >
                      <Form.Item name={[index, "areaId"]}>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return (
                              option.children[0].props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                          showArrow={true}
                          className="beecow-spaces-action-select"
                          placeholder="Please select an area"
                          disabled={this.state.isLoadingMore}
                          onChange={(value) => this.onChangeArea(value, index)}
                        >
                          {areas?.map((area, index) => (
                            <>
                              <Select.Option key={index} value={area.id}>
                                <span className="float-left">{area.code}</span>
                                <span className="float-right">{area.name}</span>
                              </Select.Option>
                            </>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>

                    <td
                      className="pr-0 col-4"
                      style={{
                        width: "180px",
                        minWidth: "180px",
                        maxWidth: "180px",
                      }}
                    >
                      <div className="w-100 d-flex">
                        <Form.Item
                          name={[index, "spaceId"]}
                          className="col-10 w-100"
                        >
                          <Select
                            showArrow={true}
                            className="beecow-spaces-action-select"
                            placeholder="Please select a space"
                            disabled={this.state.isLoadingMore}
                            onMouseEnter={(event) =>
                              this.loadSpaces(event, index)
                            }
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return (
                                option.children[0].props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                            onChange={(value) =>
                              this.onChangeSpace(value, index)
                            }
                          >
                            {spaceOptions}
                          </Select>
                        </Form.Item>
                        <span
                          title="Refresh spaces"
                          onClick={(event) =>
                            this.loadSpaces(event, index, true)
                          }
                          className="beecow-spaces-action-cancel pointer text-success col-2 m-0 pl-2 pt-2"
                        >
                          <i class="fa fa-refresh"></i>
                        </span>
                      </div>
                    </td>

                    <td
                      className="pr-0 col-3"
                      style={{
                        width: "100px",
                        minWidth: "100px",
                        maxWidth: "100px",
                      }}
                    >
                      <Form.Item name={[index, "amount"]}>
                        <Input
                          onChange={(value) =>
                            this.onChangeRemainingAmount(value, index)
                          }
                          placeholder="Product amount"
                        />
                      </Form.Item>
                    </td>
                  </tr>
                );
              })
            );
          }}
        </Form.List>
      </>
    );
  };

  onAddRow = (callBack, index) => {
    var remainingAmount = this.getRemainingAmount();
    if (remainingAmount <= 0) {
      return;
    }

    if (index && index >= 0) {
      callBack(null, index);
    } else {
      callBack();
    }
  };

  onRemoveRow = (callBack, index) => {
    callBack(index);
    var { areasSelected } = this.state;
    if (areasSelected[index]) {
      areasSelected.splice(index, 1);
      this.setRemainingAmount();
    }
  };

  onChangeArea = (areaId, index) => {
    var { areas, spaceListPaged, areasSelected } = this.state;
    var area = areas.find((a) => a.id === areaId);
    if (area) {
      areasSelected[index] = {
        area: area,
        space: [],
        amount: 0,
      };
      this.setState(
        {
          isLoadingMore: true,
          areasSelected: areasSelected,
        },
        async () => {
          areaStationDataService
            .getSpacesByAreaId(area.id, 1, 20, true, false)
            .then((res) => {
              spaceListPaged[index] = res;
              var currentSpaceList = spaceListPaged[index];
              let canLoadMore =
                currentSpaceList?.rows && currentSpaceList?.rows?.length > 0
                  ? true
                  : false;
              var spacesExcluded = this.getSpacesExcluded(
                spaceListPaged,
                areasSelected,
                index
              );
              spaceListPaged[index] = {
                ...spaceListPaged[index],
                spacesExcluded: spacesExcluded,
              };
              areasSelected[index] = {
                area: area,
                space: null,
                amount: 0,
              };
              this.setState({
                ...this.state,
                area: area,
                isLoadingMore: false,
                spaceListPaged: spaceListPaged,
                canLoadMore: canLoadMore,
              });
            });
        }
      );
    }
  };

  getSpacesExcluded(spaceListPaged, areasSelected, index) {
    var spaceList = spaceListPaged[index]?.rows;
    if (spaceList && areasSelected && areasSelected.length > 0) {
      var spacesSelected = [];
      areasSelected.forEach((value) => {
        if (value.space) {
          spacesSelected.push(value.space);
        }
      });

      if (spacesSelected.length > 0) {
        var excluded = spaceList.filter(
          (p) => !spacesSelected.some((selected) => selected.id === p.id)
        );
        if (excluded.length > 0) {
          return excluded;
        }
      }
    }

    return spaceList;
  }

  onScrollSpace(event, index) {
    var { spaceListPaged, areasSelected, isLoadingMore } = this.state;
    if (isLoadingMore === true) return;
    var target = event.target;
    var { area } = areasSelected[index];
    if (
      this.state.canLoadMore &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight - 20
    ) {
      this.setState(
        {
          ...this.state,
        },
        async () => {
          target.scrollTo(0, target.scrollHeight);
          var currentSpaceList = spaceListPaged[index];
          var additionalSpaceListPaged =
            await areaStationDataService.getSpacesByAreaId(
              area.id,
              currentSpaceList?.page + 1,
              20,
              true
            );
          var canLoadMore =
            additionalSpaceListPaged?.rows &&
            additionalSpaceListPaged?.rows?.length > 0
              ? true
              : false;
          var spacesExcluded = this.getSpacesExcluded(
            spaceListPaged,
            areasSelected,
            index
          );

          spaceListPaged[index] = {
            page: additionalSpaceListPaged.page,
            records: additionalSpaceListPaged.records,
            total: additionalSpaceListPaged.total,
            rows: [
              ...spaceListPaged[index]?.rows,
              ...additionalSpaceListPaged?.rows,
            ],
            spacesExcluded: spacesExcluded,
          };
          this.setState({
            ...this.state,
            canLoadMore: canLoadMore,
            isLoadingMore: false,
            spaceListPaged: spaceListPaged,
          });
        }
      );
    }
  }

  onChangeSpace = (spaceId, index) => {
    var { spaceListPaged, areasSelected } = this.state;
    if (areasSelected[index] && spaceListPaged[index]) {
      var space = spaceListPaged[index]?.rows?.find((s) => s.id === spaceId);
      areasSelected[index] = {
        ...areasSelected[index],
        space: space,
      };

      this.setState({
        areasSelected: areasSelected,
      });
    }
  };

  loadSpaces = (event, index, force) => {
    var { areasSelected, spaceListPaged } = this.state;
    var selected = areasSelected[index];
    if (areasSelected[index] && spaceListPaged[index]?.rows.length < 2) {
      this.onChangeArea(selected.area.id, index);
    }

    if (areasSelected[index] && force && force === true) {
      this.onChangeArea(selected.area.id, index);
    }
  };

  onChangeRemainingAmount = (event, index) => {
    var amount = event.target.value;
    var { areasSelected } = this.state;
    if (areasSelected[index]) {
      areasSelected[index] = {
        ...areasSelected[index],
        amount: amount,
      };

      this.setState(
        {
          areasSelected: areasSelected,
        },
        () => {
          this.setRemainingAmount();
        }
      );
    }
  };

  getRemainingAmount = () => {
    var { total } = this.props.initialData;
    var values = this.formRef.current.getFieldsValue();
    var addedAmount = _.sumBy(values.areas, (item) => {
      if (item.amount) {
        return Number(item.amount);
      } else {
        return 0;
      }
    });
    var remainingAmount = total - addedAmount;

    return remainingAmount;
  };

  setRemainingAmount = (value) => {
    var remainingAmount = value;
    if (isNaN(value)) {
      remainingAmount = this.getRemainingAmount();
    }

    if (this.props.onChangeRemainingAmount) {
      this.props.onChangeRemainingAmount(remainingAmount);
    }
  };

  clearCache = () => {
    this.setState({
      areasSelected: [],
      spaceListPaged: [],
      initialData: {},
      canLoadMore: false,
      isLoadingMore: false,
    });
  };

  getFieldsValue = () => {
    var values = this.formRef.current.getFieldsValue();
    return values;
  };

  setFieldsValue = (values) => {
    if (values && values.areas.length > 0) {
      this.formRef.current.setFieldsValue(values);
      var { spaceListPaged, areasSelected } = this.state;
      values.areas.forEach((i, index) => {
        areasSelected[index] = {
          area: i.area,
          space: i.space,
          amount: i.amount,
        };

        var space = {
          id: i.space?.id,
          areaId: i.space?.areaId,
          code: i.space?.code,
        };

        spaceListPaged[index] = {
          ...spaceListPaged[index],
          rows: [space],
          spacesExcluded: [space],
        };
      });

      this.setState({
        areasSelected: areasSelected,
        spaceListPaged: spaceListPaged,
      });
    }
  };

  validateFields = () => {};
}

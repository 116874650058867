import actionTypes from "./users.types";
import userDataService from "../../data-services/user/user-data.service";

export function setListUsers(data) {
    return {
        type: actionTypes.SET_LIST_USER,
        total: data.records,
        users: data.rows
    };
}

export function fetchListUsers() {
    return async (dispatch) => {
        let data = await userDataService.getUsers();
        dispatch(setListUsers(data));
    }
}

export function setSelectedUserIds(userIds) {
    return { type: actionTypes.SET_SELECTED_USERS, userIds };
}

export function deleteUsers(userIds) {
    return async (dispatch) => {
        let data = await userDataService.deleteUsers(userIds);
        if (data.success) {
            dispatch({ type: actionTypes.DELETE_USERS, userIds });
            dispatch(setSelectedUserIds([]));
        }
    }
}

export const getInitUser = () => {
    return async (dispatch) => {
        const res = await userDataService.getInitUser()
        dispatch({
            type: actionTypes.GET_INIT_USER,
            payload: res
        })
    }
}

export const getUserId = (id) => {
    return async (dispatch) => {
        const res = await userDataService.getUserId(id)
        dispatch({
            type: actionTypes.GET_USER_ID,
            payload: res.users || {}
        })
    }
}
import React, { Component } from "react";
import { t } from "i18next";
import { Steps, message } from "antd";
import Button from "antd-button-color";
import Layout from "antd/lib/layout/layout";
import "./station-registration.screen.scss";
import TopBar from "../../components/topbar";
import SummarizeComponent from "./summarize/summarize.component";
import stationDataService from "../../data-services/stations/stations-data.service";
import StationInformationComponent from "./station-information/station-information.component";
import WarehouseAdminInformationComponent from "./warehouse-admin-information/warehouse-admin-information.component";

const { Step } = Steps;
const screenSteps = {
  stationInformation: 0,
  warehouseAdminInformation: 1,
  summarize: 2,
};

export default class StationRegistrationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDataInitialization();
    this.stationInformationRef = React.createRef();
    this.warehouseAdminInformationRef = React.createRef();
    this.summarizeInformation = React.createRef();
  }

  render() {
    const { currentStep } = this.state;
    const steps = [
      {
        title: t("stationRegistration.stationInformation"),
        content: (
          <StationInformationComponent
            t={t}
            ref={this.stationInformationRef}
            initialData={this.state}
          />
        ),
      },
      {
        title: t("stationRegistration.warehouseAdminInformation"),
        content: (
          <WarehouseAdminInformationComponent
            t={t}
            ref={this.warehouseAdminInformationRef}
            initialData={this.state}
          />
        ),
      },
      {
        title: t("stationRegistration.summarize"),
        content: (
          <SummarizeComponent
            t={t}
            ref={this.summarizeInformation}
            initialData={this.state}
          />
        ),
      },
    ];
    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("title.stationRegistration")} />
          <div className="bg-white clearfix beecow-leasing-contract">
            <Steps
              type="navigation"
              current={currentStep}
              className="site-navigation-steps"
            >
              {steps.map((item) => {
                return <Step key={item.title} title={item.title} />;
              })}
            </Steps>
            <div className="steps-content">{steps[currentStep].content}</div>
            <div className="steps-action clearfix">
              {currentStep > 0 && (
                <Button type="primary" onClick={this.prev}>
                  {t("button.back")}
                </Button>
              )}
              {currentStep < steps.length - 1 && (
                <Button type="primary" onClick={this.next}>
                  {t("button.next")}
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button type="primary" onClick={this.finish}>
                  {t("button.done")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  getDataInitialization() {
    return {
      currentStep: screenSteps.stationInformation,
      stationInformation: {
        name: "",
        phoneNumber: "",
        address: {
          street: "",
          city: { id: null, name: "" },
          district: { id: null, name: "" },
          ward: { id: null, name: "" },
        },
        location: { id: null, name: "" },
        status: null,
      },
      warehouseAdminInformation: {
        fullName: "",
        phoneNumber: "",
        dateOfBirth: "",
        warehouseAdminEmail: "",
        address: {
          street: "",
          city: { id: null, name: "" },
          district: { id: null, name: "" },
          ward: { id: null, name: "" },
        },
      },
    };
  }

  prev = () => {
    let { currentStep } = this.state;
    let preStep = currentStep - 1 <= 0 ? 0 : currentStep - 1;
    this.setState({ currentStep: preStep });
  };

  next = () => {
    let { currentStep } = this.state;
    let currentFormRef;
    switch (this.state.currentStep) {
      case screenSteps.stationInformation: {
        currentFormRef = this.stationInformationRef;
        let dataToSetToStateManagement =
          currentFormRef?.current?.getFieldsValue();

        if (dataToSetToStateManagement) {
          this.setState(dataToSetToStateManagement);
        }
        break;
      }
      case screenSteps.warehouseAdminInformation: {
        currentFormRef = this.warehouseAdminInformationRef;
        let dataToSetToStateManagement =
          currentFormRef.current.getFieldsValue();

        this.setState(dataToSetToStateManagement);
        break;
      }
      default:
        break;
    }

    if (currentFormRef && currentFormRef.current) {
      currentFormRef.current
        .validateFields()
        .then(() => {
          let nextStep = currentStep + 1;
          this.setState({ currentStep: nextStep });
        })
        .catch((ex) => console.log(ex));
    }
  };

  finish = async () => {
    const { t, history } = this.props;
    const { stationInformation, warehouseAdminInformation } = this.state;

    let formDataToAddNewStation = {
      name: stationInformation?.name,
      phone: stationInformation?.phoneNumber,
      status: stationInformation?.status,
      location: stationInformation?.location?.id,
      stationAddress: {
        street: stationInformation?.address?.street,
        cityId: stationInformation?.address?.city?.id,
        districtId: stationInformation?.address?.district?.id,
        wardId: stationInformation?.address?.ward?.id,
      },
      addNewWarehouseAdminWhileCreatingStation: true,
      user: {
        fullName: warehouseAdminInformation?.fullName,
        phone: warehouseAdminInformation?.phoneNumber,
        email: warehouseAdminInformation?.warehouseAdminEmail,
        dateOfBirth: warehouseAdminInformation?.dateOfBirth,
        userAddress: {
          street: warehouseAdminInformation?.address?.street,
          cityId: warehouseAdminInformation?.address?.city?.id,
          districtId: warehouseAdminInformation?.address?.district?.id,
          wardId: warehouseAdminInformation?.address?.ward?.id,
        },
      },
    };

    let result = await stationDataService.addStation(formDataToAddNewStation);
    if (result.success) {
      message.info(t(result.message));
      history.push("/station");
    } else {
      message.error(t(result.message));
    }
  };
}

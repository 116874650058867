import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  message,
  Checkbox,
  Modal,
  Card,
  notification,
  InputNumber,
} from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Option } from "antd/lib/mentions";
import TopBar from "../../../components/topbar";
import Layout, { Content } from "antd/lib/layout/layout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import unitDataService from "../../../data-services/units/units-data.service";
import shippingFeeDataService from "../../../data-services/shipping-fees/shipping-fees-data.service";
import shippingFeeDetailDataService from "../../../data-services/shipping-fee-details/shipping-fee-details-data.service";

const key = "updatable";
const { confirm } = Modal;
const alertType = {
  success: "success",
  warning: "warning",
  error: "error",
};

export class AddShippingFeeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideModal: false,
      itemDelete: "",
      DetailList: [],
      editingKey: "",
      isNew: true,
      listUnit: [],
      checkUnit: false,
      listUnitSize: [],
      listUnitWeight: [],
      listSpecial: [],
      textNameUnit: "",

      feeID: "",
      feeCode: "",
      feeName: "",
      feeSize: "",
      feeLength: "",
      feeWidth: "",
      feeHeight: "",
      feeWeight: "",
      feeSpecial: "",
      feePrice: 0,
      feeisActive: true,
      dataAddNew: [],
      isCheckHandleAdd: 0,
      count: 0,
      file: null,
      isProcessing: false,
      isSavedItem: true,
      isNewRow: false,
      dataSource: [],
    };

    this.handleCancelModal = this.handleCancelModal.bind(this);
    this.updateShippingFee = this.updateShippingFee.bind(this);
    this.editDetail = this.editDetail.bind(this);
    this.isEditing = this.isEditing.bind(this);
    this.cancel = this.cancel.bind(this);

    this.formRef = React.createRef();
  }

  getTableColumns() {
    const { t } = this.props;

    let columns = [
      {
        title: t("shippingFee.interCityDetail.no"),
        dataIndex: "NO",
        width: "5%",
      },
      {
        title: t("shippingFee.interCityDetail.length"),
        dataIndex: "feeLength",
        sorter: (a, b) => a.feeLength - b.feeLength,
        width: "14%",
        editable: true,
        render: (text, { isNew }) => {
          if (isNew)
            return (
              <Select
                value={this.state.feeLength}
                onChange={(e) => this.setState({ feeLength: e })}
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.listUnitSize.map((type) => (
                  <Option value={type.descriptions}>{type.descriptions}</Option>
                ))}
              </Select>
            );
          return <span>{text}</span>;
        },
      },
      {
        title: t("shippingFee.interCityDetail.width"),
        dataIndex: "feeWidth",
        sorter: (a, b) => a.feeWidth - b.feeWidth,
        width: "14%",
        editable: true,
        render: (text, { isNew }) => {
          if (isNew)
            return (
              <Select
                value={this.state.feeWidth}
                onChange={(e) => this.setState({ feeWidth: e })}
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.listUnitSize.map((type) => (
                  <Option value={type.descriptions}>{type.descriptions}</Option>
                ))}
              </Select>
            );
          return <span>{text}</span>;
        },
      },
      {
        title: t("shippingFee.interCityDetail.height"),
        dataIndex: "feeHeight",
        sorter: (a, b) => a.feeHeight - b.feeHeight,
        width: "14%",
        editable: true,
        render: (text, { isNew }) => {
          if (isNew)
            return (
              <Select
                value={this.state.feeHeight}
                onChange={(e) => this.setState({ feeHeight: e })}
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.listUnitSize.map((type) => (
                  <Option value={type.descriptions}>{type.descriptions}</Option>
                ))}
              </Select>
            );
          return <span>{text}</span>;
        },
      },
      {
        title: t("shippingFee.interCityDetail.weight"),
        dataIndex: "feeWeight",
        sorter: (a, b) => a.feeWeight - b.feeWeight,
        width: "14%",
        editable: true,
        ellipsis: true,
        render: (text, { isNew }) => {
          if (isNew)
            return (
              <Select
                value={this.state.feeWeight}
                onChange={(e) => this.setState({ feeWeight: e })}
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.listUnitWeight.map((type) => (
                  <Option value={type.descriptions}>{type.descriptions}</Option>
                ))}
              </Select>
            );
          return <span>{text}</span>;
        },
      },
      {
        title: t("shippingFee.interCityDetail.specialService"),
        dataIndex: "feeSpecial",
        sorter: (a, b) => a.feeSpecial - b.feeSpecial,
        width: "15%",
        editable: true,
        ellipsis: true,
        render: (text, { isNew }) => {
          if (isNew)
            return (
              <Select
                value={this.state.feeSpecial}
                onChange={(e) => this.setState({ feeSpecial: e })}
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.listSpecial.map((type) => (
                  <Option value={type.name}>{type.name}</Option>
                ))}
              </Select>
            );
          return <span>{text}</span>;
        },
      },
      {
        title: t("shippingFee.interCity.price"),
        dataIndex: "feePrice",
        sorter: (a, b) => a.feePrice - b.feePrice,
        width: "13%",
        editable: true,
        ellipsis: true,
        render: (text, { isNew }) => {
          if (isNew)
            return (
              <InputNumber
                min={0}
                required="true"
                value={this.state.feePrice}
                onChange={(e) => this.setState({ feePrice: e })}
                id="feePrice"
                name="feePrice"
              />
            );
          else return <span>{text}</span>;
        },
      },
      {
        title: t("status.action"),
        width: "9%",
        render: (_, record) => {
          const editable = this.isEditing(record);
          return !editable ? (
            <span className="action">
              <a
                disabled={this.state.editingKey !== ""}
                onClick={() => this.editDetail(record)}
              >
                <i
                  className="fa fa-pencil-square-o edit"
                  aria-hidden="true"
                ></i>
              </a>
              <a
                disabled={this.state.editingKey !== ""}
                onClick={() =>
                  this.confirmDelete(
                    record,
                    `${t("notification.confirm.confirmDeleteThisItem")}`
                  )
                }
              >
                <i className="fa fa-trash delete" aria-hidden="true"></i>
              </a>
            </span>
          ) : (
            <span className="action">
              <a onClick={() => this.save(record)}>
                <i className="fa fa-floppy-o save" aria-hidden="true" />
              </a>
              <Popconfirm
                title={t("notification.confirm.confirmCancel")}
                onConfirm={this.cancel}
                okText={t("button.ok")}
                cancelText={t("button.cancel")}
              >
                <a>
                  <i
                    className="fa fa-times-circle-o delete"
                    aria-hidden="true"
                  ></i>
                </a>
              </Popconfirm>
            </span>
          );
        },
      },
    ];

    let mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType:
            col.dataIndex === "feeLength" ||
            col.dataIndex === "feeWidth" ||
            col.dataIndex === "feeHeight" ||
            col.dataIndex === "feeWeight" ||
            col.dataIndex === "feeSpecial"
              ? "select"
              : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return mergedColumns;
  }

  confirmDelete = (record, title) => {
    const currentComponent = this;
    const { t } = this.props;

    var ids = [];
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      okText: `${t("button.ok")}`,
      cancelText: `${t("button.cancel")}`,
      onOk() {
        return new Promise((resolve, reject) => {
          ids = [record.id];
          if (record.shippingFeeId) {
            shippingFeeDetailDataService.deleteDetailShippingFee(ids).then(
              (res) => {
                if (res.success) {
                  currentComponent.removeRow(currentComponent, record, resolve);
                } else {
                  this.alertCanNotRemove();
                  return false;
                }
              },
              (error) => {
                setTimeout(reject(error), 500);
              }
            );
          } else {
            currentComponent.removeRow(currentComponent, record, resolve);
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      onCancel() {},
    });
  };

  removeRow = (currentComponent, record, resolve) => {
    let updateListShippingFee = currentComponent.state.dataSource;
    let getData = updateListShippingFee.filter(
      (item) => item.key !== record.key
    );
    if (getData.length > 0) {
      for (let i = 0; i < getData.length; i++) {
        getData[i].NO = i + 1;
        getData[i].key = i + 1;
      }
    }
    currentComponent.setState({ dataSource: getData });
    setTimeout(resolve, 500);
  };

  clickBtnAdd = () => {
    const {
      count,
      dataSource,
      feeLength,
      feeWidth,
      feeHeight,
      feeWeight,
      feeSpecial,
      feePrice,
      checkUnit,
      isSavedItem,
    } = this.state;
    if (!isSavedItem) {
      return false;
    }
    if (checkUnit) {
      const newData = {
        key: count,
        NO: dataSource.length + 1,
        isNew: false,
        feeLength: feeLength,
        feeWidth: feeWidth,
        feeHeight: feeHeight,
        feeWeight: feeWeight,
        feeSpecial: feeSpecial,
        feePrice: feePrice,
      };
      this.setState({
        dataSource: [newData, ...dataSource],
        dataAddNew: newData,
        count: count + 1,
        isCheckHandleAdd: count,
        editingKey: null,
        isProcessing: true,
        isSavedItem: false,
        isNewRow: true,
      });
    } else {
      message.error(`${this.props.t("notification.interCityFee.nonUnit")}`);
    }
  };

  save = (record) => {
    const {
      dataSource,
      feeLength,
      feeWidth,
      feeHeight,
      feeWeight,
      feeSpecial,
      feePrice,
      count,
    } = this.state;
    const values = this.formRef.current.getFieldsValue();
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => record.key === item.key);
    this.setState({ isProcessing: false, isSavedItem: true, isNewRow: false });

    const data = {
      no: record.NO,
      ShippingFeeId: null,
      feeLength: feeLength,
      feeWidth: feeWidth,
      feeHeight: feeHeight,
      feeWeight: feeWeight,
      feeSpecial: feeSpecial,
      feePrice:
        feePrice === 0 || feePrice.toString().includes("0.")
          ? feePrice
          : feePrice.toString().replace(/^0+/, ""),
    };
    if (data.feePrice <= 0) {
      this.alertAddErrorPrice();
      this.setState({ isProcessing: true, isSavedItem: false, isNewRow: true });
      return false;
    }
    if (newData.length > 1) {
      for (let i = 0; i < newData.length; i++) {
        if (
          newData[i].feeHeight === data.feeHeight &&
          newData[i].feeLength === data.feeLength &&
          newData[i].feeSpecial === data.feeSpecial &&
          newData[i].feeWeight === data.feeWeight &&
          newData[i].feeWidth === data.feeWidth &&
          newData[i].NO != data.no
        ) {
          this.alertAddDuplicate();
          this.setState({
            isProcessing: true,
            isSavedItem: false,
            isNewRow: true,
          });
          return false;
        }
      }
    }

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...data });
    } else {
      newData.push(data);
    }
    for (let i = 0; i < newData.length; i++) {
      newData[i].NO = i + 1;
    }
    this.setState({
      dataSource: newData,
      editingKey: "",
      isCheckHandleAdd: count,
    });
  };

  alert = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  alertAddErrorPrice = () => {
    const { t } = this.props;
    this.alert(
      alertType.warning,
      `${t("notification.warning")}`,
      `${t("notification.interCityFee.price")}`
    );
    return;
  };

  alertAddDuplicate = () => {
    const { t } = this.props;
    this.alert(
      alertType.warning,
      `${t("notification.warning")}`,
      `${t("notification.interCityFee.duplicate")}`
    );
    return;
  };

  alertNotFeename = () => {
    const { t } = this.props;
    this.alert(
      alertType.warning,
      `${t("notification.warning")}`,
      `${t("notification.interCityFee.feeName")}`
    );
    return;
  };

  alertNotHaveDetailRow = () => {
    const { t } = this.props;
    this.alert(
      alertType.warning,
      `${t("notification.warning")}`,
      `${t("notification.interCityFee.feeDetail")}`
    );
    return;
  };

  alertCanNotRemove = () => {
    const { t } = this.props;
    this.alert(
      alertType.warning,
      `${t("notification.warning")}`,
      `${t("notification.interCityFee.notRemove")}`
    );
    return;
  };

  editDetail = (record) => {
    this.formRef?.current?.setFieldsValue({
      ...record,
    });
    this.setState({
      editingKey: record.key,
      ...record,
      isProcessing: true,
      isSavedItem: false,
    });
  };

  handleChange = (e) => {
    const isCheckbox = e.target.name === "radioGroup";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
    });
  };

  componentWillMount() {
    this.InitDataTable();
  }

  InitDataTable() {
    const id = this.props.match?.params?.id;
    this.getUnitAndSpecial();
    if (id) {
      this.state.feeID = id;
      shippingFeeDataService
        .getShippingFeeId(this.state.feeID)
        .then((data) => {
          this.setState({
            feeCode: data.code,
            feeName: data.name,
            feeisActive: data.status,
          });
        })
        .then(() => this.getDetailById());
    }
  }

  getUnitAndSpecial() {
    shippingFeeDetailDataService.getUnitAndSpecial().then((data) => {
      this.setState({
        listUnitSize: data.packageSize,
        listUnitWeight: data.packageWeight,
        listSpecial: data.specialServices,
      });

      if (
        this.state.listUnitSize.length > 0 &&
        this.state.listUnitWeight.length > 0
      ) {
        this.setState({
          checkUnit: true,
          feeLength: this.state.listUnitSize[0].descriptions,
          feeWidth: this.state.listUnitSize[0].descriptions,
          feeHeight: this.state.listUnitSize[0].descriptions,
          feeWeight: this.state.listUnitWeight[0].descriptions,
          feeSpecial: this.state.listSpecial[0].name,
        });
      }
    });
  }

  getDetailById() {
    shippingFeeDetailDataService
      .getDetailShippingFeeById(this.state.feeID)
      .then((data) => {
        const details = this.getData(data.rows);
        this.setState({ count: details.length + 1 });
        this.currentData = details;
        this.setState({ dataSource: details });
      });
  }

  getData = (DetailList) => {
    return DetailList.map((x, i) => ({
      key: i + 1,
      shippingFeeId: x.shippingFeeId,
      NO: i + 1,
      feeLength: this.getNameUnitSize(x.length),
      feeWidth: this.getNameUnitSize(x.width),
      feeHeight: this.getNameUnitSize(x.height),
      feeWeight: this.getNameUnitWeight(x.weight),
      feeSpecial: x.additional,
      feePrice: x.price,
      checked: false,
      id: x.id,
    }));
  };

  getNameUnitSize = (Key) => {
    this.state.listUnitSize.forEach((value) => {
      if (value.id === Key) {
        this.setState({ textNameUnit: value.descriptions });
        return this.state.textNameUnit;
      }
    });
    return this.state.textNameUnit;
  };

  getNameUnitWeight = (Key) => {
    this.state.listUnitWeight.forEach((value) => {
      if (value.id === Key) {
        this.setState({ textNameUnit: value.descriptions });
        return this.state.textNameUnit;
      }
    });
    return this.state.textNameUnit;
  };

  openMessage = () => {
    const { t } = this.props;

    message.loading({ content: `${t("status.saving")}`, key });
    setTimeout(() => {
      message.success({ content: `${t("status.loaded")}`, key, duration: 2 });
    }, 3000);
  };

  isEditing = (record) => {
    if (
      record.key === this.state.editingKey ||
      record.key === this.state.isCheckHandleAdd
    ) {
      return true;
    }
  };

  EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const renderContent = () => {
      const {
        feePrice,
        listUnitSize,
        listUnitWeight,
        feeLength,
        feeWidth,
        feeHeight,
        feeSpecial,
        feeWeight,
      } = this.state;
      if (dataIndex === "feeLength") {
        return (
          <Select
            value={feeLength}
            onChange={(e) => this.setState({ feeLength: e })}
            defaultValue={record[dataIndex]}
            style={{ width: "100%" }}
          >
            {listUnitSize.map((type) => (
              <Option value={type.descriptions}>{type.descriptions}</Option>
            ))}
          </Select>
        );
      } else if (dataIndex === "feeWidth") {
        return (
          <Select
            value={feeWidth}
            onChange={(e) => this.setState({ feeWidth: e })}
            defaultValue={record[dataIndex]}
            style={{ width: "100%" }}
          >
            {listUnitSize.map((type) => (
              <Option value={type.descriptions}>{type.descriptions}</Option>
            ))}
          </Select>
        );
      } else if (dataIndex === "feeHeight") {
        return (
          <Select
            value={feeHeight}
            onChange={(e) => this.setState({ feeHeight: e })}
            defaultValue={record[dataIndex]}
            style={{ width: "100%" }}
          >
            {listUnitSize.map((type) => (
              <Option value={type.descriptions}>{type.descriptions}</Option>
            ))}
          </Select>
        );
      } else if (dataIndex === "feeSpecial") {
        return (
          <Select
            value={feeSpecial}
            onChange={(e) => this.setState({ feeSpecial: e })}
            defaultValue={record[dataIndex]}
            style={{ width: "100%" }}
          >
            {this.state.listSpecial.map((type) => (
              <Option value={type.name}>{type.name}</Option>
            ))}
          </Select>
        );
      } else if (dataIndex === "feeWeight") {
        return (
          <Select
            value={feeWeight}
            onChange={(e) => this.setState({ feeWeight: e })}
            defaultValue={record[dataIndex]}
            style={{ width: "100%" }}
          >
            {listUnitWeight.map((type) => (
              <Option value={type.descriptions}>{type.descriptions}</Option>
            ))}
          </Select>
        );
      } else if (dataIndex === "feePrice") {
        return (
          <InputNumber
            min={0}
            defaultValue={feePrice}
            onChange={(e) => this.setState({ feePrice: e })}
            id="feePrice"
            name="feePrice"
          />
        );
      }
    };
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `\`${this.props.t("notification.input")}\` ${title}!`,
              },
            ]}
          >
            {renderContent()}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  cancel = () => {
    const { itemDelete, dataAddNew, isNewRow } = this.state;
    const dataSource = [...this.state.dataSource];
    if (isNewRow) {
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== dataAddNew.key),
        isNewRow: false,
      });
    }

    this.setState({ editingKey: "", isProcessing: false, isSavedItem: true });
  };

  onChangeCheckActive = (e) => {
    this.setState({
      feeisActive: e.target.checked,
    });
  };

  updateShippingFee = (history) => {
    var dataDetail = [];
    const {
      feeID,
      feeCode,
      feeName,
      feeisActive,
      dataSource,
      listUnitSize,
      isProcessing,
    } = this.state;
    if (!feeName) {
      this.alertNotFeename();
      return false;
    }
    if (dataSource.length == 0) {
      this.alertNotHaveDetailRow();
      return false;
    }
    if (isProcessing) {
      return false;
    }
    if (feeID != "") {
      const dataUpdate = {
        id: feeID,
        code: feeCode,
        name: feeName,
        status: feeisActive ? 1 : 0,
      };
      dataSource.map((data) => {
        const dataShippingDetail = {
          id: data.id,
          ShippingFeeId: data.shippingFeeId,
          length: this.getKeyUnitSize(data.feeLength),
          width: this.getKeyUnitSize(data.feeWidth),
          height: this.getKeyUnitSize(data.feeHeight),
          weight: this.getKeyUnitWeight(data.feeWeight),
          additional: data.feeSpecial,
          unitPrice: "VND",
          price: data.feePrice,
        };
        dataDetail.push(dataShippingDetail);
      });
      const dataShippingfee = {
        ShippingFee: dataUpdate,
        ShippingFeeDetails: dataDetail,
      };
      shippingFeeDataService.updateListDetail(dataShippingfee).then((data) => {
        history.push("/shippingfee");
      });
    } else {
      const dataAdd = {
        Name: feeName,
        Status: feeisActive ? 1 : 0,
      };
      dataSource.map((data) => {
        const dataShippingDetail = {
          length: this.getKeyUnitSize(data.feeLength),
          width: this.getKeyUnitSize(data.feeWidth),
          height: this.getKeyUnitSize(data.feeHeight),
          weight: this.getKeyUnitWeight(data.feeWeight),
          additional: data.feeSpecial,
          price: data.feePrice,
          unitPrice: "VND",
        };
        dataDetail.push(dataShippingDetail);
      });
      const dataShippingfee = {
        ShippingFee: dataAdd,
        ShippingFeeDetails: dataDetail,
      };
      shippingFeeDataService.addListDetail(dataShippingfee).then((res) => {
        history.push("/shippingfee");
      });
    }
  };

  getKeyUnitSize = (data) => {
    let unit = this.state.listUnitSize?.find((x) => x.descriptions === data);
    if (unit) {
      this.setState({ feeSize: unit.id });
      return `${unit.id}`;
    } else {
      return this.state.feeSize;
    }
  };

  getKeyUnitWeight = (data) => {
    let unit = this.state.listUnitWeight?.find((x) => x.descriptions === data);
    if (unit) {
      this.setState({ feeSize: unit.id });
      return `${unit.id}`;
    } else {
      return this.state.feeSize;
    }
  };

  handleCancelModal = () => {
    this.setState({ hideModal: false });
  };

  clickBtnAddUnit = () => {
    this.setState({ hideModal: true });
  };

  handleAddUnit() {
    unitDataService.addUnit().then((data) => {
      this.setState({ hideModal: false });
    });
  }

  render() {
    const { dataSource, feeName, feeID, feeCode } = this.state;
    var { t } = this.props;
    let columns = this.getTableColumns();
    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar
            title={
              feeID
                ? `${t("shippingFee.interCityDetail.titleEdit")}`
                : `${t("shippingFee.interCityDetail.titleCreate")}`
            }
          />
          <Content className="content">
            <Card>
              <div className="header">
                <div className="lbl-header"></div>
                <div className="lbl-name">
                  {feeID ? <a>{feeCode}</a> : null}
                </div>
                <div className="btn-header">
                  <Route
                    render={({ history }) => (
                      <a
                        onClick={() => history.push("/shippingfee")}
                        className="lbl-back"
                      >
                        {t("button.back")}
                      </a>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <Button
                        className="bg-save"
                        onClick={() => this.updateShippingFee(history)}
                      >
                        {t("button.save")}
                      </Button>
                    )}
                  />
                </div>
              </div>
              <div className="form-info">
                <Row>
                  <a
                    style={{
                      fontSize: "20px",
                      marginLeft: "20px",
                      color: "#fbb787",
                    }}
                  >
                    {t("shippingFee.interCityDetail.feeInformation")}
                  </a>
                </Row>
                <Row>
                  <Col>
                    <div className="style-feeName">
                      <a>{t("shippingFee.interCityDetail.feeName")}</a>
                      <Input
                        value={feeName}
                        onChange={this.handleChange}
                        type="text"
                        id="feeName"
                        name="feeName"
                        className="feeName"
                        placeholder={t("placeholder.feeName")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="checkbox">
                    <Checkbox
                      checked={this.state.feeisActive}
                      onChange={this.onChangeCheckActive}
                    />
                    <a style={{ marginLeft: "10px" }}>{t("status.active")}</a>
                  </div>
                </Row>
              </div>
              <div className="form-detail">
                <div className="lbl-detail">
                  <a>{t("status.detail")}</a>
                </div>
                <div className="btn-detail">
                  <Modal
                    visible={this.state.hideModal}
                    onCancel={this.handleCancelModal}
                    footer={[
                      <Button key="back" onClick={this.handleCancelModal}>
                        {t("button.cancel")}
                      </Button>,
                    ]}
                  >
                    <div className="form-modal">
                      <Form>
                        <div className="Unit">
                          <Form.Item>
                            <div>
                              <label className="lblUnit">Size(m): </label>
                            </div>
                            <div className="iblSize">
                              <a>0 - 0,5 m</a>
                              <a>0,5 - 1 m</a>
                              <a>Over 1 m</a>
                            </div>
                            <div>
                              <label className="lblUnit">Weight(kg): </label>
                            </div>
                            <div className="iblSize">
                              <a>0 - 0,5 kg</a>
                              <a>0,5 - 1 kg</a>
                              <a>Over 1 kg</a>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="btnAddUnit">
                          <Button onClick={this.handleAddUnit}>
                            <i
                              className="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Modal>
                  <Button className="add-detail" onClick={this.clickBtnAdd}>
                    {t("button.addDetail")}
                  </Button>
                </div>
              </div>

              <Form ref={this.formRef}>
                <Table
                  components={{
                    body: {
                      cell: this.EditableCell,
                    },
                  }}
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{
                    onChange: this.cancel,
                  }}
                />
              </Form>
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }
}

import { DataService } from "../base/base-data.service";

class ContractDataService extends DataService {
    apiUrl = '/contract';

    async loadContracts() {
        return await this.get(`${this.apiUrl}/loadData`);
    }

    async getContractsByStationId(stationId, page, rows, keySearch) {
        return await this.get(`${this.apiUrl}/get-contracts-by-station-id?stationId=${stationId}&page=${page}&rows=${rows}&keySearch=${keySearch}`);
    }

    async getContractById(id) {
        return await this.get(`${this.apiUrl}/${id}`);
    }

    async getContractDetailById(id) {
        return await this.get(`${this.apiUrl}/detail/${id}`);
    }

    async deleteContract() {
        return await this.post(`${this.apiUrl}/delete`);
    }

    async verifyContract(contractId) {
        return await this.put(`${this.apiUrl}/verify/${contractId}`);
    }

    async updateStatusContract(id) {
        return await this.put(`${this.apiUrl}/StatusContract/${id}`);
    }

    async cleanUpWarehouseByContractId(contractId) {
        return await this.delete(`${this.apiUrl}/clean-up-warehouse-by-contract-id/${contractId}`);
    }
}

let contractDataService = new ContractDataService();

export default contractDataService;
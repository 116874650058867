import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { AddShippingFeeScreen } from "./add-shipping-fee.screen";

const mapStateToProps = ({ shippingFee }) => {
  return {
    id: shippingFee.id,
  };
};
export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, null),
  withRouter
)(AddShippingFeeScreen);

import actionTypes from "./roles.types";
import Moment from "moment";

const rolesInitialState = {
    list: [],
    selected: [],
    lastUpdated: 1439478405547,
};

const rolesReducer = (state = rolesInitialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LIST_ROLE:
            return { ...state, list: action.roles, lastUpdated: Moment.utc().format('x') };
        case actionTypes.SET_SELECTED_ROLES:
            return { ...state, selected: action.roleIds, lastUpdated: Moment.utc().format('x') };
        case actionTypes.DELETE_ROLES:
            let roles = state.list.filter(u => action.roleIds.indexOf(u.id) === -1);
            return { ...state, list: roles, lastUpdated: Moment.utc().format('x') };
        default:
            return state;
    }
}

export default rolesReducer;
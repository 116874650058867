import React, { Component } from "react";
import { Button, Card } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import TopBar from "../../components/topbar/index";
import TableCompanies from "./table-company";
import "./company-management.scss";
export class CompanyManagementScreen extends Component {
  constructor(props) {
    super(props);
    this.handleAddCompany = this.handleAddCompany.bind(this);
  }

  handleAddCompany() {
    this.props.history.push("/companies/new");
  }

  render() {
    return (
      <Layout className="bg-content beecow-company-management">
        <div className="bg-body">
          <TopBar title={this.props.t("listProviders.title")} />
          <Content className="content">
            <Card>
              <div className="beecow-right">
                &nbsp;&nbsp;
                <Button
                  size="medium"
                  type="primary"
                  className="beecow-btn"
                  onClick={this.handleAddCompany}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;{" "}
                  {this.props.t("listProviders.addNewProviders")}
                </Button>
              </div>
              <br />
              <TableCompanies />
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }
}

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectAdress } from "./select-adress-component";
import {
  getDistrictByCityId,
  getWardsByDistrictId,
} from "../../../../modules/address/address.actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = ({ address }) => {
  return {
    cities: address.cities || [],
    wards: address.wards || [],
    districts: address.districts || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDistrictByCityId,
      getWardsByDistrictId,
    },
    dispatch
  );
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps)
)(SelectAdress);

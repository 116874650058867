import { DataService } from "../base/base-data.service";

class UserDataService extends DataService {
    apiUrl = '/user'

    async getUsers() {
        return await this.get(`${this.apiUrl}/list`);
    }

    async getUserByPhone(phone) {
        return await this.get(`${this.apiUrl}/getByPhone?phone=${phone}`);
    }

    async deleteUsers(userIds) {
        return await this.post(`${this.apiUrl}/delete`, userIds);
    }

    async getInitUser() {
        return await this.get(`${this.apiUrl}/get-init-user`);
    }

    async insert(data) {
        return await this.post(`${this.apiUrl}/insert`, data);
    }

    async getUserId(id) {
        return await this.get(`${this.apiUrl}/get-user-by-Id?id=${id}`);
    }

    async resetPassword(req) {
        return await this.put(`${this.apiUrl}/reset-password`, req);
    }

    async resetPasswordByUser(req) {
        return await this.put(`${this.apiUrl}/reset-password-by-user`, req);
    }
    async update(req) {
        return await this.put(`${this.apiUrl}/update`, req);
    }
    async logout(request) {
        return await this.post(`${this.apiUrl}/logout`, request);
    }

}

let userDataService = new UserDataService();

export default userDataService;
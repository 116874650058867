import { DataService } from "../base/base-data.service";

class ShippingFeeDetailDataService extends DataService {
    apiUrl = '/ShippingFeeDetail';

    async getDetailShippingFee() {
        return this.get(`${this.apiUrl}/loadData`);
    }

    async getDetailShippingFeeById(id) {
        return this.get(`${this.apiUrl}/${id}`);
    }

    async addShippingFeeDetail(data) {
        return this.post(`${this.apiUrl}/insert`, data);
    }

    async deleteDetailShippingFee(data) {
        return this.post(`${this.apiUrl}/delete`, data);
    }

    async getUnitAndSpecial() {
        return this.get(`${this.apiUrl}/getDetailUnitAndSpecial`);
    }
}

let shippingFeeDetailDataService = new ShippingFeeDetailDataService();

export default shippingFeeDetailDataService;
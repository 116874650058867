import React from "react";
import _ from "lodash";
import {
  Table,
  Input,
  Button,
  Form,
  Modal,
  Checkbox,
  Select,
  notification,
  TimePicker,
  Card,
} from "antd";
import { t } from "i18next";
import { Option } from "antd/lib/mentions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout, { Content } from "antd/lib/layout/layout";
import moment from "moment";
import TopBar from "../../components/topbar";
import routeDataService from "../../data-services/routes/routes-data.service";
import shippingFeeDataService from "../../data-services/shipping-fees/shipping-fees-data.service";
import vehicleDataService from "../../data-services/vehicles/vehicles-data.service";
import stationDataService from "../../data-services/stations/stations-data.service";
import { page, splitToGetCode } from "../../constants/constants";

const { confirm } = Modal;
const alertType = {
  success: "success",
  warning: "warning",
  error: "error",
};
const FIRST_DESTINATION = 0;

export class RouteManagementScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childModalVisible: false,
      visible: false,
      selectedRowKeys: [],
      editingKey: "",
      titleModal: "",
      isAddModel: true,
      positionIndex: FIRST_DESTINATION,
      model: {
        id: props.id,
        code: props.lastName,
        routeName: props.routeName,
        fromStation: props.fromStation,
        toStation: props.toStation,
        subRouteCodes: props.subRouteCodes,
        shippingFeeId: props.shippingFeeId,
        shippingFee: props.shippingFee,
        vehicleId: props.vehicleId,
        timeStart: props.timeStart,
        timeStartString: props.timeStartString,
        timeEnd: props.timeEnd,
        timeEndString: props.timeEndString,
        status: props.status,
        toStationId: props.toStationId,
        fromStationId: props.fromStationId,
        vehicleCode: "",
        fromStationCode: props.fromStationCode,
        fromStationName: props.fromStationName,
        toStationCode: props.toStationCode,
        toStationName: props.toStationName,
      },
      destination: {
        stationId: props.stationId,
        name: props.name,
        time: props.time,
        timeString: props.timeString,
        position: 0,
      },
      destinations: [],
      dataSource: [],
      currentData: [],
      shippingFees: [],
      vehicles: [],
      transportTypes: [],
      stations: [],
      paging: {
        pageSize: page.pageSizeDefault,
      },
      total: 0,
    };
    this.formRef = React.createRef();
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.isCheckAll = this.isCheckAll.bind(this);
    this.onChangeShippingFeeId = this.onChangeShippingFeeId.bind(this);
    this.onChangeVehicleId = this.onChangeVehicleId.bind(this);
    this.onChangeRouteName = this.onChangeRouteName.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeFromStationId = this.onChangeFromStationId.bind(this);
    this.onChangeToStationId = this.onChangeToStationId.bind(this);
    this.onChangeTimeStart = this.onChangeTimeStart.bind(this);
    this.onChangeTimeEnd = this.onChangeTimeEnd.bind(this);
    this.onChangeDestinationStationId =
      this.onChangeDestinationStationId.bind(this);
    this.onChangeDestinationTime = this.onChangeDestinationTime.bind(this);
    this.onRemoveDestination = this.onRemoveDestination.bind(this);
    this.onInsertDestination = this.onInsertDestination.bind(this);
  }

  onChangeShippingFeeId(value) {
    var model = this.state.model;
    model.shippingFeeId = value;
    this.setState({ model: model });
  }

  onChangeVehicleId(value) {
    const { model, vehicles, transportTypes } = this.state;
    var modelVehicle = model;
    var vehicleId = vehicles.filter((x) => value.includes(x.id));
    var transportType = transportTypes?.filter((x) =>
      vehicleId.some((v) => v.transportType === x.id)
    );
    modelVehicle.vehicleId = value;
    modelVehicle.transportType = transportType?.map((x) => x.name).join(",");

    vehicleDataService.getVehicleTypes().then((data) => {
      var type = data.filter((x) => vehicleId.some((v) => v.type.id === x.id));
      modelVehicle.vehicleType = type?.map((x) => x.name).join(",");
      modelVehicle.vehicleCode = vehicles.code;
      this.setState({ model: modelVehicle });
    });
    this.setState({ selectedSVehicles: value });
  }

  onChangeRouteName(value) {
    var model = this.state.model;
    model.routeName = value.target.value;
    this.setState({ model: model });
  }

  onChangeStatus(value) {
    var model = this.state.model;
    model.status = value.target.checked;
    this.setState({ model: model });
  }

  onChangeToStationId(value) {
    var model = this.state.model;
    model.toStationId = value;
    this.setState({ model: model });
  }

  onChangeFromStationId(value) {
    var model = this.state.model;
    model.fromStationId = value;

    this.setState({ model: model });
  }

  onChangeTimeStart(time, timeString) {
    var model = this.state.model;
    model.timeStart = time;
    model.timeStartString = timeString;
    this.setState({ model: model });
  }

  onChangeTimeEnd(time, timeString) {
    var model = this.state.model;
    model.timeEnd = time;
    model.timeEndString = timeString;
    this.setState({ model: model });
  }

  onChangeDestinationStationId(value) {
    const { destination, stations } = this.state;
    destination.stationId = value;
    destination.code = stations?.find((x) => x.id === value)?.code;
    destination.name = stations?.find((x) => x.id === value)?.name;
    this.setState({ destination: destination });
  }

  onChangeDestinationTime(time, timeString) {
    var destination = this.state.destination;
    destination.time = time;
    destination.timeString = timeString;
    this.setState({ destination: destination });
  }

  onRemoveDestination(position, isRemove) {
    const currentComponent = this;
    var destinations = this.state.destinations;
    const { t } = this.props;

    if (isRemove) {
      var newDestinations = destinations?.filter((x) => x.position != position);
      var sorted = this.initPosition(newDestinations);

      this.setState({
        destinations: sorted,
      });

      return;
    }

    var destination = destinations?.find((x) => x.position === position);
    confirm({
      title:
        `${t("notification.route.deleteDestination")}` +
        destination?.name +
        "(" +
        destination?.timeString +
        ") ?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        currentComponent.onRemoveDestination(position, true);
      },
      onCancel() { },
    });
  }

  initPosition(destinations) {
    var result = [];
    destinations.forEach((item, index) => {
      item.position = index + 1;
      result.push(item);
    });

    return result;
  }

  onShowAddModal = () => {
    this.initData();
    this.setState({
      visible: true,
      isAddModel: true,
      titleModal: `${this.props.t("route.routeCreate.title")}`,
      model: {
        id: "",
        code: "",
        routeName: "",
        fromStation: "",
        fromStationId: "",
        toStation: "",
        toStationId: "",
        subRouteCodes: "",
        shippingFeeId: "",
        transportType: "",
        shippingFee: "",
        vehicleId: "",
        timeStart: "",
        timeStartString: "",
        timeEnd: "",
        timeEndString: "",
        status: false,
        fromStationName: "",
        fromStationCode: "",
        toStationName: "",
        toStationCode: "",
      },
      destinations: [],
    });
  };

  onEdit = (record) => {
    const currentComponent = this;
    currentComponent.initData();
    routeDataService.getById(record.id).then((data) => {
      var model = {
        id: data.id,
        code: data.code,
        vehicleId: data.vehicles?.map((x) => x.id),
        vehicleCode: data.vehicles.map((x) => x.code),
        vehicleIds: data.vehicles?.map((x) => x.id),
        routeName: data.routeName,
        fromStation: data.fromStation,
        fromStationId: data.fromStation?.id,
        toStation: data.toStation,
        toStationId: data.toStation?.id,
        shippingFee: data.shippingFee,
        shippingFeeId: data.shippingFee?.id,
        timeStart: moment(data.timeStart, "hh:mm"),
        timeStartString: data.timeStart,
        timeEnd: moment(data.timeEnd, "hh:mm"),
        timeEndString: data.timeEnd,
        status: data.status.id === 0 ? false : true,
      };

      var destinations = data.destinations?.map((item) => {
        return {
          stationId: item.station?.id,
          code: item.station?.code,
          name: item.station?.name,
          position: item.position,
          timeString: item.time,
        };
      });

      currentComponent.setState({
        titleModal: `${this.props.t("route.routeEdit.title")}`,
        isAddModel: false,
        model: model,
        visible: true,
        destinations: destinations === undefined ? [] : destinations,
      });
    });
  };

  onOk = (values) => {
    const { isAddModel } = this.state;

    if (isAddModel) {
      this.handleAdd();
    } else {
      this.handleUpdate();
    }
  };

  addDestination = (position) => {
    var subRouteModel = {
      stationId: "",
      time: "",
    };

    this.setState({
      childModalVisible: true,
      subRoute: subRouteModel,
      positionIndex: position,
    });
  };

  onCloseModal = () => {
    this.setState({
      visible: false,
    });
  };

  async onInsertDestination(model, position) {
    const currentComponent = this;
    var destinations = this.state.destinations;

    if (destinations !== undefined && destinations !== "") {
      destinations.forEach((item, index) => {
        var objIndex = destinations.findIndex(
          (obj) => obj.stationId === item.stationId
        );
        if (position > 0 && item.position >= position) {
          destinations[objIndex].position += 1;
          currentComponent.setState({ destinations: destinations });
        }

        if (index === destinations.length - 1) {
          model.position = position;
          destinations.splice(objIndex, 0, model);

          const ordered = destinations.sort((a, b) => {
            return a.position - b.position;
          });

          currentComponent.setState({ destinations: ordered });
        }
      });
    }
  }

  onChildOk = () => {
    var index = this.state.positionIndex;
    var isSubRoute = index === FIRST_DESTINATION ? false : true;
    var position =
      this.state.destinations?.length === undefined
        ? 0
        : this.state.destinations?.length + 1;

    var destinationModel = {
      stationId: this.state.destination?.stationId,
      name: this.state.destination?.name,
      code: this.state.destination?.code,
      time: this.state.destination?.time,
      timeString: this.state.destination?.timeString,
      position: isSubRoute ? index : position,
    };

    var destinations = this.state.destinations;
    if (
      destinationModel.stationId !== "" &&
      destinationModel.stationId !== undefined &&
      destinationModel.time !== undefined
    ) {
      if (isSubRoute) {
        this.onInsertDestination(destinationModel, index);
      } else {
        destinations.push(destinationModel);
        this.setState({ destinations: destinations });
      }
    }

    this.onChildCloseModal();
  };

  onChildCloseModal = () => {
    this.setState({
      childModalVisible: false,
    });
  };

  confirmDelete = (record, isSingleDelete, title) => {
    const currentComponent = this;
    var ids = [];
    const { t } = this.props;

    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      okText: `${t("button.ok")}`,
      cancelText: `${t("button.cancel")}`,
      onOk() {
        return new Promise((resolve, reject) => {
          if (isSingleDelete) {
            ids = [record.id];
          } else {
            ids = currentComponent.state.selectedRowKeys;
          }

          routeDataService.deleteData(ids).then(
            (data) => {
              currentComponent.loadTable();
              setTimeout(resolve, 1000);
            },
            (error) => {
              setTimeout(reject(error), 500);
            }
          );
        }).catch((errors) => {
          console.log(errors);
        });
      },
      onCancel() { },
    });
  };

  alert = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  showDeleteButton() {
    const { dataSource } = this.state;
    return dataSource?.some((p) => p.checked);
  }

  isCheckAll() {
    const elm = document.getElementById("check-all");
    const { dataSource } = this.state || {};
    if (elm) {
      elm.checked = dataSource?.length && dataSource?.every((p) => p.checked);
    }
  }

  onSelectChange = (selectedRowKeys) => {
    const { dataSource } = this.state;
    dataSource?.forEach((p) => {
      p.checked = selectedRowKeys.includes(p.key);
    });

    this.setState({
      dataSource,
      selectedRowKeys: selectedRowKeys,
    });
  };

  handleValidate(model) {
    if (
      model.routeName === "" ||
      model.shippingFee === "" ||
      model.vehicleIds === undefined ||
      model.vehicleIds === "" ||
      model.timeStart === "" ||
      model.timeEnd === ""
    ) {
      return false;
    }

    return true;
  }

  handleAdd = () => {
    const { model, destinations } = this.state;
    const { t } = this.props;

    if (destinations === undefined || destinations.length < 2) {
      this.alert(
        alertType.warning,
        `${t("notification.warning")}`,
        `${t("notification.route.warningDestination")}`
      );
      return;
    }

    var destinationRequestModels = [];
    destinations.forEach((item) => {
      var _detination = {
        stationId: item.stationId,
        position: item.position,
        time: item.timeString,
      };

      destinationRequestModels.push(_detination);
    });

    var fromDestination = destinationRequestModels[0];
    var toDestination =
      destinationRequestModels[destinationRequestModels.length - 1];

    var addRouteRequestModel = {
      routeName: model.routeName,
      shippingFeeId: model.shippingFeeId,
      vehicleIds: model.vehicleId,
      fromStationId: fromDestination.stationId,
      toStationId: toDestination.stationId,
      status: model.status,
      timeStart: fromDestination.time,
      timeEnd: toDestination.time,
      destinations: destinationRequestModels,
    };

    var isValid = this.handleValidate(addRouteRequestModel);
    if (isValid === false) {
      this.alert(
        alertType.warning,
        `${t("notification.warning")}`,
        `${t("notification.route.warningValidate")}`
      );
      return;
    }

    routeDataService.insertData(addRouteRequestModel).then(
      (data) => {
        this.loadTable();
        this.alert(
          alertType.success,
          `${t("notification.success")}`,
          `${t("notification.route.addSuccess")}`
        );
        setTimeout(() => {
          this.onCloseModal();
        }, 1000);
      },
      (error) => {
        this.alert(alertType.error, `${t("notification.error")}`, error);
      }
    );
  };

  handleUpdate = () => {
    const { model, destinations } = this.state;
    const { t } = this.props;

    if (destinations == undefined || destinations.length < 2) {
      this.alert(
        alertType.warning,
        `${t("notification.warning")}`,
        `${t("notification.route.warningDestination")}`
      );
      return;
    }

    var destinationRequestModels = [];
    destinations?.forEach((item) => {
      var _detination = {
        stationId: item.stationId,
        position: item.position,
        time: item.timeString,
      };

      destinationRequestModels.push(_detination);
    });

    var fromDestination = destinationRequestModels[0];
    var toDestination =
      destinationRequestModels[destinationRequestModels.length - 1];

    var updateRouteRequestModel = {
      id: model.id,
      routeName: model.routeName,
      shippingFeeId: model.shippingFeeId,
      status: model.status,
      vehicleIds: model.vehicleId,
      fromStationId: fromDestination.stationId,
      toStationId: toDestination.stationId,
      timeStart: fromDestination.time,
      timeEnd: toDestination.time,
      destinations: destinationRequestModels,
    };

    var isValid = this.handleValidate(updateRouteRequestModel);
    if (isValid === false) {
      this.alert(
        alertType.warning,
        `${t("notification.warning")}`,
        `${t("notification.route.warningValidate")}`
      );
      return;
    }

    routeDataService.updateData(updateRouteRequestModel).then((data) => {
      this.loadTable();
      this.alert(
        alertType.success,
        `${t("notification.success")}`,
        `${t("notification.route.updateSuccess")}`
      );
      setTimeout(() => {
        this.onCloseModal();
      }, 1000);
    });
  };

  handleCheckAll(e, data) {
    const { dataSource } = this.state;
    dataSource?.forEach((p) => {
      p.checked = data.checked;
    });
    this.setState({ dataSource });
  }

  handleSearch(e) {
    const keySearch = e.currentTarget?.value?.toLowerCase();
    let data = this.currentData;
    let dataListFoundInDataSource = [];
    if (keySearch) {
      dataListFoundInDataSource = data?.filter(
        (p) =>
          p?.code?.toLowerCase().includes(keySearch) ||
          p?.fromStation?.toLowerCase().includes(keySearch) ||
          p?.routeName?.toLowerCase().includes(keySearch) ||
          p?.shippingFeeId?.toLowerCase().includes(keySearch) ||
          p?.shippingFeeName?.toLowerCase().includes(keySearch) ||
          t(p?.status.name)?.toLowerCase().includes(keySearch) ||
          p?.timeEnd?.toLowerCase().includes(keySearch) ||
          p?.timeStart?.toLowerCase().includes(keySearch) ||
          p?.toStation?.toLowerCase().includes(keySearch) ||
          p?.transportType?.toLowerCase().includes(keySearch) ||
          p?.vehicleId?.toLowerCase().includes(keySearch) ||
          p?.vehicleType?.toLowerCase().includes(keySearch)
      );
      this.setState({ dataSource: dataListFoundInDataSource });
    } else {
      this.setState({ dataSource: data });
    }
  }

  dataMapping = (rows) => {
    return rows?.map((item) => ({
      key: item.id,
      id: item.id,
      code: item.code,
      routeName: item.routeName,
      vehicleId: item.vehicles?.map((item) => item.code).join(", "),
      vehicleType: item.vehicles?.map((item) => item.type?.name).join(", "),
      fromStation: item.fromStation?.name,
      fromStationId: item.fromStation?.id,
      toStation: item.toStation?.name,
      toStationId: item.toStation?.id,
      subRouteCodes: item.subRouteCodes,
      shippingFeeId: item.shippingFee?.code,
      transportType: item.transportType.join(", "),
      timeStart: item.timeStart,
      timeEnd: item.timeEnd,
      status: item.status,
      checked: false,
      shippingFeeName: item.shippingFee?.name,
    }));
  };

  loadTable() {
    routeDataService.loadData().then((response) => {
      const routes = this.dataMapping(response.rows);
      this.currentData = routes;
      this.setState({
        total: response.records,
        dataSource: routes,
        destinations: [],
      });
    });
  }

  initData() {
    shippingFeeDataService.getShippingFees().then((data) => {
      this.setState({
        shippingFees: data,
      });
    });

    vehicleDataService.getVehicles().then((data) => {
      this.setState({
        vehicles: data,
      });
    });

    vehicleDataService.getTransportTypes().then((data) => {
      this.setState({
        transportTypes: data,
      });
    });

    stationDataService.getStations().then((data) => {
      this.setState({
        stations: data,
      });
    });
  }

  componentWillMount() {
    this.loadTable();
  }

  openShippingFee() {
    this.props.history.push(
      `/admin/edit-shippingfee/${this.state.model.shippingFeeId}`
    );
  }

  openVehicle(vehicleCode) {
    this.props.history.push(`/vehicles?${vehicleCode}`);
  }

  getTableColumns(routeList) {
    const { t } = this.props;

    let fromStations = routeList?.map((aRoute) => {
      return {
        text: aRoute?.fromStation,
        value: aRoute?.fromStationId,
      };
    });

    let fromStationOptions = fromStations?.filter(
      (d, i) => fromStations?.findIndex((o) => o.value === d.value) === i
    );

    let toStations = routeList?.map((aStation) => {
      return {
        text: aStation?.toStation,
        value: aStation?.toStationId,
      };
    });

    let toStationOptions = toStations?.filter(
      (d, i) => toStations?.findIndex((o) => o.value === d.value) === i
    );

    let columns = [
      {
        title: `${t("route.routeId")}`,
        dataIndex: "code",
        width: "8%",
        fixed: "left",
        sorter: (a, b) => splitToGetCode(a.code) - splitToGetCode(b.code),
      },
      {
        title: `${t("shippingFee.interCity.code")}`,
        dataIndex: "shippingFeeId",
        width: "25%",
        fixed: "left",
        sorter: (a, b) => a?.shippingFeeId?.localeCompare(b.shippingFeeId),
        render: (data, shippingFee, { isNew }) => {
          const text = `${data} (${shippingFee.shippingFeeName})`;
          return <span>{text}</span>;
        },
      },
      {
        title: `${t("vehicle.id")}`,
        dataIndex: "vehicleId",
        width: "20%",
        render: (data, { isNew }) => {
          return <span>{data}</span>;
        },
      },
      {
        title: `${t("route.routeName")}`,
        dataIndex: "routeName",
        editable: true,
        width: "20%",
        ellipsis: true,
        render: (data, { isNew }) => {
          return <span>{data}</span>;
        },
      },
      {
        title: `${t("route.from")}`,
        dataIndex: "fromStation",
        editable: true,
        width: "20%",
        ellipsis: true,
        filters: fromStationOptions,
        onFilter: (value, record) => record.fromStationId.indexOf(value) === 0,
        sorter: (a, b) => a?.fromStation?.localeCompare(b.fromStation),
        render: (data, { isNew }) => {
          return <span>{data}</span>;
        },
      },
      {
        title: `${t("route.to")}`,
        dataIndex: "toStation",
        editable: true,
        width: "20%",
        ellipsis: true,
        filters: toStationOptions,
        onFilter: (value, record) => record?.toStationId.indexOf(value) === 0,
        sorter: (a, b) => a?.toStation?.localeCompare(b.toStation),
        render: (data, { isNew }) => {
          return <span>{data}</span>;
        },
      },
      {
        title: `${t("route.startTime")}`,
        dataIndex: "timeStart",
        editable: true,
        width: "10%",
        ellipsis: true,
        render: (text, { isNew }) => {
          return <span>{text}</span>;
        },
      },
      {
        title: `${t("route.endTime")}`,
        dataIndex: "timeEnd",
        editable: true,
        width: "10%",
        ellipsis: true,
        render: (text, { isNew }) => {
          return <span>{text}</span>;
        },
      },
      {
        title: `${t("status.status")}`,
        dataIndex: "status",
        editable: true,
        width: "12%",
        sorter: (a, b) => a?.status?.name?.localeCompare(b?.status?.name),
        render: (status, { isNew }) => {
          return <div className="status available">{t(status.name)}</div>;
        },
      },
      {
        title: `${t("status.action")}`,
        width: "10%",
        render: (_, record) => {
          return (
            <>
              <span className="action">
                <a
                  onClick={() => this.onEdit(record)}
                  disabled={this.state.editingKey !== ""}
                  style={{ marginRight: 8 }}
                >
                  <i
                    className="fa fa-pencil-square-o edit"
                    aria-hidden="true"
                  ></i>
                </a>
                <a
                  onClick={() =>
                    this.confirmDelete(
                      record,
                      true,
                      `${t("notification.confirm.confirmDeleteThisItem")}`
                    )
                  }
                  disabled={this.state.editingKey !== ""}
                >
                  <i className="fa fa-trash delete" aria-hidden="true"></i>
                </a>
              </span>
            </>
          );
        },
      },
    ];

    let mergedColumns = columns.map((col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    return mergedColumns;
  }

  render() {
    const {
      dataSource,
      editingKey,
      shippingFees,
      vehicles,
      stations,
    } = this.state;
    const totalRowSelected = dataSource?.filter((p) => p.checked)?.length || 0;
    const selectedRowKeys = dataSource
      ?.filter((p) => p.checked)
      .map((x) => x.key);
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const isDisable = editingKey.length > 0;
    const oStations = [];
    stations.forEach(function (item, index) {
      oStations.push(
        <Option disabled={item.status === 0} key={item.id} value={item.id}>
          {item.name}
        </Option>
      );
    });

    let columns = this.getTableColumns(dataSource);
    const { t } = this.props;

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("route.title")} />
          <Content className="route-page content">
            <Card>
              <div className="action-route">
                <div className="select-items clearfix">
                  <div className="row">
                    <div className="col-sm-4 select-number gutter-row">
                      <p>
                        {t("label.selected")} {totalRowSelected}{" "}
                        {t("route.route")}
                      </p>
                    </div>
                    <div className="col-sm-8">
                      <div className="col-sm-8 action gutter-row">
                        <div className="group-btn btn-item">
                          <Button
                            disabled={isDisable}
                            className="btn-labeled btn-add"
                            onClick={this.onShowAddModal}
                            style={{ marginBottom: 16 }}
                          >
                            {" "}
                            {t("button.add")}
                            <span className="btn-label">
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Button>
                          {this.showDeleteButton() && (
                            <Button
                              disabled={isDisable}
                              onClick={() =>
                                this.confirmDelete(
                                  null,
                                  false,
                                  `${this.props.t(
                                    "notification.confirm.confirmDeleteTheseItems"
                                  )}`
                                )
                              }
                              className="btn-labeled remove btn-remove"
                            >
                              {" "}
                              {t("button.remove")}
                              <span className="btn-label">
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Button>
                          )}
                          <Button
                            disabled={isDisable}
                            className="btn-labeled import btn-import"
                          >
                            {t("button.import")}
                            <span className="btn-label">
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Button>
                          <Button
                            onClick={this.getDownloadFile}
                            disabled={isDisable}
                            className="btn-labeled import btn-import"
                          >
                            {" "}
                            {t("button.export")}
                            <span className="btn-label">
                              <i
                                className="fa fa-file-excel-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Button>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <input
                          className="search form-control mr-sm-2"
                          onChange={(e) => this.handleSearch(e)}
                          type="search"
                          placeholder={t("placeholder.search")}
                          aria-label="Search"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                title={t("route.routeCreate.addDestination")}
                centered
                visible={this.state.childModalVisible}
                maskClosable={false}
                onOk={this.onChildOk}
                onCancel={this.onChildCloseModal}
                okText={t("button.add")}
                cancelText={t("button.cancel")}
                width={500}
              >
                <div className="row col-12 m-0">
                  <Select
                    placeholder={t("placeholder.selectStation")}
                    className="col-4 mr-3 mt-2"
                    optionFilterProp="children"
                    value={this.state.destination?.stationId}
                    onChange={this.onChangeDestinationStationId}
                  >
                    {oStations}
                  </Select>

                  <TimePicker
                    className="mt-2"
                    value={this.state.destination.time}
                    onChange={this.onChangeDestinationTime}
                    placeholder={t("route.startTime")}
                    format={"HH:mm"}
                    defaultOpenValue={moment("00:00:00", "HH:mm")}
                  />
                </div>
              </Modal>

              <Modal
                title={this.state.titleModal}
                top
                visible={this.state.visible}
                maskClosable={false}
                onOk={this.onOk}
                onCancel={this.onCloseModal}
                okText={t("button.save")}
                cancelText={t("button.cancel")}
                width={1000}
              >
                <div className="row m-0">
                  <Form layout="vertical" className="col-sm-10  m-auto">
                    {this.state.isAddModel == false ? (
                      <div className="row">
                        <Form.Item className="col-sm-12 pr-5 mt-0">
                          <h3
                            className="float-right"
                            style={{ "font-style": "italic", color: "#fcaf02" }}
                          >
                            {this.state.model.code}
                          </h3>
                        </Form.Item>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="row m-0 offset-md-2">
                      <Form.Item
                        label={t("shippingFee.interCity.code")}
                        name="shippingFeeId"
                        className="col"
                      >
                        <Select
                          optionFilterProp="children"
                          className="col-sm-8"
                          value={this.state.model.shippingFeeId}
                          onChange={this.onChangeShippingFeeId}
                        >
                          {shippingFees?.map(({ id, code, name, status }) => (
                            <Option
                              disabled={status == 0}
                              key={id}
                              value={id}
                            >{`${code} (${name})`}</Option>
                          ))}
                        </Select>
                        <a
                          className="p-auto pl-3 view-detail-page"
                          target="_blank"
                          onClick={this.openShippingFee.bind(this)}
                        >
                          {" "}
                          <span className="btn-label">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </span>{" "}
                          {t("label.viewDetail")}
                        </a>
                      </Form.Item>

                      <Form.Item label={t("vehicle.id")} className="col">
                        <Select
                          mode="multiple"
                          allowClear
                          className="col-sm-8"
                          value={this.state.model.vehicleId || []}
                          onChange={this.onChangeVehicleId}
                        >
                          {vehicles?.map(({ id, code, statusId, type }) => (
                            <Option
                              disabled={statusId === 35}
                              key={id}
                              value={id}
                            >{`${code} (${t(type?.name)})`}</Option>
                          ))}
                        </Select>
                        <a
                          className="p-auto pl-3"
                          target="_blank"
                          onClick={() =>
                            this.openVehicle(this.state.model.vehicleCode)
                          }
                        >
                          {" "}
                          <span className="btn-label">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </span>{" "}
                          {t("label.viewDetail")}
                        </a>
                      </Form.Item>
                    </div>
                    <div className="row m-0 pt-3">
                      <Form.Item
                        label={t("route.routeName")}
                        className="col-sm-6"
                      >
                        <Input
                          className="col-sm-8"
                          value={this.state.model.routeName}
                          onChange={this.onChangeRouteName}
                        />
                        <div className="col-sm-4"></div>
                      </Form.Item>

                      <Form.Item label=" " className="col-sm-2 mb-0">
                        <Checkbox
                          style={{ lineHeight: "32px" }}
                          value={this.state.model.status}
                          checked={this.state.model.status}
                          onChange={this.onChangeStatus}
                        >
                          {t("status.available")}
                        </Checkbox>
                      </Form.Item>
                    </div>

                    <div className="row m-0 mt-5 mb-5">
                      <Form.Item
                        label={t("route.routeCreate.currentRouteDetail")}
                        className="col-sm-12 mb-0"
                      >
                        <div className="text-center mt-5">
                          {this.state.destinations.length < 2 ? (
                            <span
                              onClick={() =>
                                this.addDestination(FIRST_DESTINATION)
                              }
                              className="btn btn-success"
                              style={{ "border-radius": "40px" }}
                            >
                              <i
                                className="fa fa-plus-circle"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </span>
                          ) : (
                            <></>
                          )}
                          <div className="row mt-5">
                            <div className="col-12 row m-auto">
                              {this.state.destinations?.map((item) => {
                                var position = item.position + 1;
                                var showAddSubRoute =
                                  this.state.destinations?.length >= 2 &&
                                    position <= this.state.destinations?.length
                                    ? true
                                    : false;

                                return (
                                  <>
                                    <div className="m-auto destination">
                                      <div>
                                        <span>{item.timeString}</span>
                                      </div>
                                      <span
                                        onClick={() =>
                                          this.onRemoveDestination(
                                            item.position
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fa fa-close float-right text-danger"></i>
                                      </span>
                                      <span>
                                        <i className="destination-icon fa fa-home"></i>
                                      </span>
                                    </div>

                                    {showAddSubRoute ? (
                                      <div className="mt-4">
                                        <span
                                          onClick={() =>
                                            this.addDestination(position)
                                          }
                                          className="btn btn-success"
                                          style={{ "border-radius": "20px" }}
                                        >
                                          <i
                                            className="fa fa-plus-circle"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="line"></div>
                          <div className="row mt-0">
                            <div className="col-12 row m-auto">
                              {this.state.destinations?.map((item) => {
                                return (
                                  <>
                                    <div className="m-auto destination">
                                      <span>{item?.name}</span>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </Modal>
              <div className="list-route">
                <Form ref={this.formRef}>
                  <Table
                    rowSelection={rowSelection}
                    components={{
                      body: {
                        cell: this.EditableCell,
                      },
                    }}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={this.state.paging}
                    scroll={{ x: "100vw" }}
                  />
                </Form>
              </div>
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }
}

import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  message,
  Card,
  Checkbox,
} from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import TopBar from "../../../components/topbar";
import companiesDataService from "../../../data-services/companies/companies-data.service";

export class AddCompanyScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      fileList: [],
    };
    this.formRef = React.createRef();
    this.back = this.back.bind(this);
  }

  render() {
    const { t } = this.props;

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("listProviders.addNewProviders")} />
          <Content>
            <Card>
              <Form
                ref={this.formRef}
                layout="vertical"
                initialValues={{ isAvailable: true }}
                requiredMark={false}
                onFinish={this.create.bind(this)}
              >
                <Row>
                  <Col span={8} offset={2}>
                    <h1 style={{ textAlign: "center" }}>
                      <b>{t("providerCreate.providerInfo")}</b>
                    </h1>
                    <br />
                    <Form.Item
                      label={t("providerCreate.providerName")}
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            "notification.providerCreate.providerName"
                          )}`,
                        },
                      ]}
                    >
                      <Input placeholder={t("placeholder.inputProviderName")} />
                    </Form.Item>
                    <Form.Item
                      label={t("providerCreate.providerLogo")}
                      name="companyLogoFile"
                    >
                      <Upload
                        fileList={this.state.fileList}
                        maxCount={1}
                        listType="picture-card"
                        className="beecow-upload-list-inline"
                        beforeUpload={this.handleBeforeUpload}
                        onChange={this.handleChange.bind(this)}
                        onPreview={this.handlePreview}
                      >
                        {(!this.state.fileList ||
                          this.state.fileList.length === 0) &&
                          "+ Upload"}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={8} offset={2}>
                    <h1 style={{ textAlign: "center" }}>
                      <b>{t("providerCreate.firstAdminAccount")}</b>
                    </h1>
                    <br />
                    <Form.Item
                      label={t("listProviders.nameAdmin")}
                      name="userFullName"
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            "notification.providerCreate.adminName"
                          )}`,
                        },
                      ]}
                    >
                      <Input placeholder={t("placeholder.inputAdminName")} />
                    </Form.Item>
                    <Form.Item
                      label={t("listProviders.emailAdmin")}
                      name="userEmail"
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            "notification.providerCreate.adminEmail"
                          )}`,
                        },
                      ]}
                    >
                      <Input placeholder={t("placeholder.inputAdminEmail")} />
                    </Form.Item>
                    <Form.Item
                      label={t("listProviders.phoneAdmin")}
                      name="userPhone"
                      rules={[
                        {
                          required: true,
                          message: `${t("notification.providerCreate.phone")}`,
                        },
                      ]}
                    >
                      <Input placeholder={t("placeholder.inputAdminPhone")} />
                    </Form.Item>
                    <Form.Item name="isAvailable" valuePropName="checked">
                      <Checkbox defaultChecked> Available</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} offset={12} style={{ textAlign: "right" }}>
                    <Form.Item className="actions">
                      <Button
                        className="btn-action btn-back"
                        onClick={this.back}
                      >
                        {t("button.back")}
                      </Button>
                      <Button
                        className="btn-action btn-insert"
                        htmlType="submit"
                      >
                        {t("button.create")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }

  handleBeforeUpload(file) {
    let isImage = /^image\/.*$/i.test(file.type);
    if (!isImage) {
      message.error(`${file.name} is invalid. You can only upload image file!`);
    }
    let isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error(`${file.name} is invalid. Image must smaller than 5MB!`);
    }
    return isImage && isLt5M ? false : Upload.LIST_IGNORE;
  }

  handleChange(event) {
    this.setState({
      selectedFile: event.file,
      fileList: [...event.fileList],
    });
  }

  async handlePreview(file) {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  }

  back() {
    this.props.history?.goBack();
  }

  create(data) {
    const { t } = this.props;

    let formData = new FormData();
    for (let key in data) {
      if (!key) continue;
      if (key === "companyLogoFile") {
        formData.append(key, this.state.selectedFile);
      } else if (data[key]) {
        formData.append(key, data[key]);
      }
    }
    companiesDataService
      .insert(formData)
      .then((res) => {
        if (res.isSuccess) {
          this.props.history?.goBack();
        } else {
          message.error(t(res.message));
        }
      })
      .catch((ex) => {
        console.log(ex);
        message.error("Failed to create provider");
      });
  }
}

import React, { Component } from "react";
import Layout, { Content } from "antd/lib/layout/layout";
import { Card, Col, Row } from "antd";
import TopBar from "../../components/topbar";
import "./view-product-detail.scss";
import moment from "moment";

import { ProductDetailTableComponent } from "./product-detail-table/product-detail-table.component";

export class ProductDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkStrictly: false,
    };

    this.productDetailTableRef = React.createRef();
  }

  componentDidMount() {
    const { productId } = this.props?.match?.params;
    this.props.getProductDetail(productId);
  }

  render() {
    const {
      code,
      inventory,
      name,
      orders,
      packageType,
      total,
      inWarehouse,
      areas,
    } = this.props.productDetails;
    var dataTable = this.getOrdersDataTable(orders);
    if (this.productDetailTableRef && this.productDetailTableRef.current) {
      this.productDetailTableRef.current.updateDataTable(dataTable);
    }

    const { t } = this.props;

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("product.title")} />
          <Content className="content">
            <Card bordered={false} className="product-detail">
              <Row>
                <Col span="12">
                  <Row>
                    <Col span={8} className="infor-contract">
                      <ul>
                        <li>
                          <p>
                            <b>{t("product.text.productId")}: </b>
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("product.text.productName")}: </b>
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("product.text.location")}: </b>
                          </p>
                        </li>
                      </ul>
                    </Col>
                    <Col span={14} className="infor-contract content-data">
                      <ul>
                        <li>
                          <p>{code}</p>
                        </li>
                        <li>
                          <p>{name}</p>
                        </li>
                        <li>
                          {areas?.map(({ code, spaceAreas }) => (
                            <p>
                              {code}:
                              <span>
                                {spaceAreas.map((x) => x.code).join(",")}
                              </span>
                            </p>
                          ))}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col span="12">
                  <Row>
                    <Col span={8} className="infor-contract">
                      <ul>
                        <li>
                          <p>
                            <b>{t("product.text.initial")}: </b>
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("product.text.current")}: </b>
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("product.text.inWarehouse")}: </b>
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>{t("product.text.category")}: </b>
                          </p>
                        </li>
                      </ul>
                    </Col>
                    <Col span={14} className="infor-contract content-data">
                      <ul>
                        <li>
                          <p>{total}</p>
                        </li>
                        <li>
                          <p>{inventory}</p>
                        </li>
                        <li>
                          <p>{inWarehouse}</p>
                        </li>
                        <li>
                          <p>{t(packageType?.languageKey)}</p>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="mt-4">
                <ProductDetailTableComponent
                  t={t}
                  history={this.props.history}
                  ref={this.productDetailTableRef}
                  initialData={dataTable}
                />
              </div>
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }

  getOrdersDataTable = (orders) => {
    return orders?.map((order, index) => {
      var { id, code, type, createdTime, quantity, status, orderItems } = order;
      return {
        key: id,
        index: index + 1,
        id,
        code,
        type,
        date: moment.utc(createdTime).local().format("DD/MM/YYYY"),
        quantity,
        status: status?.statusName,
        children: orderItems
          ?.map((i) => this.importInfoTransfer(i))
          ?.filter((i) => i.quantity > 0),
      };
    });
  };

  importInfoTransfer = (importInfo) => {
    if (!importInfo.imported) {
      return {};
    }

    return {
      id: importInfo?.importList?.id,
      code: importInfo?.importList?.code,
      date: moment
        .utc(importInfo?.importList?.createdTime)
        .local()
        .format("DD/MM/YYYY"),
      quantity: importInfo?.importedAmount ?? 0,
      isChild: true,
    };
  };
}

import actionTypes from "./roles.types";
import userTypesDataService from "../../data-services/user-types/user-types-data.service";

export function setListRoles(roles) {
    return { type: actionTypes.SET_LIST_ROLE, roles };
}

export function fetchListRoles() {
    return async (dispatch) => {
        let data = await userTypesDataService.getUserTypes();
        dispatch(setListRoles(data.rows));
    }
}

export function setSelectedRoleIds(roleIds) {
    return { type: actionTypes.SET_SELECTED_ROLES, roleIds };
}

export function deleteRoles(roleIds) {
    return async (dispatch) => {
        let data = await userTypesDataService.deleteUserTypes(roleIds);
        if (data.success) {
            dispatch({ type: actionTypes.DELETE_ROLES, roleIds });
            dispatch(setSelectedRoleIds([]));
        }
    }
}
import { DataService } from "../base/base-data.service";

class VehicleDataService extends DataService {
    apiUrl = '/vehicle';

    async getListVehicle(keySearch) {
        return await this.get(`${this.apiUrl}?keySearch=${keySearch}`);
    }

    async getVehicles() {
        return await this.get(`${this.apiUrl}/vehicles`);
    }

    async getVehicleById(id) {
        return this.get(`${this.apiUrl}/${id}`);
    }

    async getVehicleTypes() {
        return await this.get(`${this.apiUrl}/get-vehicle-types`);
    }

    async addVehicle(data) {
        return await this.post(`${this.apiUrl}/insert`, data);
    }

    async updateVehicle(data) {
        return await this.post(`${this.apiUrl}/update`, data);
    }

    async deleteVehicles(data) {
        return await this.post(`${this.apiUrl}/delete`, data);
    }

    async getWorkingDays() {
        return await this.get(`${this.apiUrl}/get-working-days`);
    }

    async importVehicle(data) {
        return await this.post(`${this.apiUrl}/import`, data);
    }

    async getTransportTypes() {
        return await this.get(`${this.apiUrl}/get-transport-types`);
    }

}

let vehicleDataService = new VehicleDataService();

export default vehicleDataService;
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { SpaceDetailScreen } from "./space-detail.screen";

const mapStateToProps = ({ warehouse }) => {
  return {
    id: warehouse.id,
    idArea: warehouse.idArea,
    idSpace: warehouse.idSpace,
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, null),
  withRouter
)(SpaceDetailScreen);

import actionTypes from "./shipping-fee.types";

const shippingFeeInitState = {
    innerCityFees: [],
    cities: []
};

const shippingFeeReducer = (state = shippingFeeInitState, action) => {
    switch (action.type) {
        case actionTypes.SET_SHIPPINGFEE_ID:
            return { ...state, id: action.payload };
        case actionTypes.GET_INNER_CITY_FEES:
            const { innerCityFees, cities } = action.payload
            return { ...state, innerCityFees: innerCityFees, cities: cities };
        default:
            return state;
    }
}

export default shippingFeeReducer;
import { Layout } from "antd";
import React, { Component } from "react";
import { I18nextProvider } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { LoadingComponent } from "./components/loading/loading.component";
import BeecowMenu from "./components/menu";
import { admin, superAdmin, warehouseAdmin } from "./constants/user-constants";
import { ErrorBoundary } from "./errorBoundary";
import { finishSwitchingWorkspace } from "./modules/session/session.actions";
import AdminRoutes from "./router/admin.routes";
import PrivateRoute from "./router/auth.router";
import SuperAdminRoutes from "./router/superadmin.routes";
import WarehouseAdminRoutes from "./router/warehouse-admin.routes";
import i18n from "./utils/i18n";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRoutes = () => {
    if (this.props.userType === superAdmin && !this.props.switchWorkspaceId) {
      return SuperAdminRoutes;
    } else if (this.props.userType === admin || this.props.userType === superAdmin || !this.props.userType) {
      return AdminRoutes;
    } else if (this.props.userType === warehouseAdmin) {
      return WarehouseAdminRoutes;
    }
    return null;
  };

  getRouteForFirstAccess = () => {
    let url = window.location.hash;
    if (!url.includes("/login")) {
      if (this.props.userType === warehouseAdmin) {
        return `/admin/warehouse`;
      } else {
        if (this.props.userType === superAdmin && this.props.isSwitching && this.props.switchWorkspaceId) {
          return "/station";
        }
        if (this.props.userType === superAdmin && this.props.isSwitching && !this.props.switchWorkspaceId) {
          return "/companies";
        }
      }
    }
    return null;
  };

  render() {
    let routes = this.getRoutes();
    let redirect = this.getRouteForFirstAccess();

    if (redirect) {
      this.props.finishSwitchingWorkspace();
    }
    return (
      <ErrorBoundary>
        <Router>
          <I18nextProvider i18n={i18n}>
            <div className="container-list">
              <LoadingComponent loading={this.props.loading} />
              <Layout className="ant-layout ant-layout-has-sider" style={{ minHeight: "100vh" }}>
                <BeecowMenu listMenu={routes} />
                <Switch>
                  {routes.map((route, index) =>
                    route.auth ? (
                      <PrivateRoute key={index} path={route.path} exact={route.exact} component={route.main} />
                    ) : (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.main}
                        ii={this.props.i18n}
                      />
                    )
                  )}
                </Switch>
                {redirect && <Redirect to={redirect} />}
              </Layout>
            </div>
          </I18nextProvider>
        </Router>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.app.isDataServiceProcessing || false,
    userType: state.session?.auth?.user?.role,
    switchWorkspaceId: state.session?.workspace?.switchWorkspaceId,
    isSwitching: state.session?.workspace?.isSwitching,
    currentStationId: state.session?.auth?.user?.currentStationId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    finishSwitchingWorkspace: () => dispatch(finishSwitchingWorkspace()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

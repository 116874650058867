import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { WarehouseManagementScreen } from "./warehouse-management.screen";

const mapStateToProps = ({ warehouse, session, app }) => {
  return {
    id: warehouse.id,
    idArea: warehouse.idArea,
    stationId: session?.auth?.user?.currentStationId,
    role: session?.auth?.user?.role,
    currentUserId: session?.auth?.user?.userId,
    setJustLoggedIn: app?.justLoggedIn,
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, null),
  withRouter
)(WarehouseManagementScreen);
